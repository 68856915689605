import PropTypes from 'prop-types';
import React, { Component, PureComponent } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import { List, ListItem } from 'material-ui/List';
import formDataToObject from 'form-data-to-object';
import ActionInfoOutline from 'material-ui/svg-icons/action/info-outline';
import s from './Preview.scss';
import { date, dummyFormatter, formatNumberOrEmpty, formatStringAsNumber, number } from '../../../core/format';
import fieldsConfig from '../fields';
import RaisedButton from '../../Common/RaisedButton';
import ContractLink from '../../Common/ContractLink';
import { staticPopup } from '../../../actions/runtime';
import Diff from '../../Common/Diff';
import { PackingList } from '../MiniPackingList';
import Dialog from '../../Common/Dialog';
import SpinnerButton from '../../Common/SpinnerButton';
import EmailIcon from 'material-ui/svg-icons/communication/email';
import CargoApi from '../../../core/api/Cargo';
import Attachments from './Attachments';
import IconButton from 'material-ui/IconButton';
import ArrowUp from 'material-ui/svg-icons/hardware/keyboard-arrow-up';
import ArrowDown from 'material-ui/svg-icons/hardware/keyboard-arrow-down';
import Tags, { Info } from './../../Common/Tags/Tags';
import { transformTagBeforeSend } from './../../Common/Tags/utils';
import TagsAPI from './../../../core/api/Tags';
import CargoRequestType from './CargoRequestType';
import Formsy from 'formsy-react';
import { getPreviewErrorMsg, parseErrorsArray } from '../utils';
import AdditionalCargoDetailsEdit from "./AdditionalCargoDetailsEdit";
import PolPodPreview from "./PolPodPreview";
import {getConfigFields} from "../../Documents/fields";
import {CargoInfo, LDRatesView} from "../../Documents/shared/preview";
import {findContainerTypeById} from "../../dictionaries";
import {constructCargoTitle} from "../../Documents/constructCargoTitle";


const styles = {
  container: { width: '100%' },
  info: {
    cursor: 'pointer',
    top: '4px',
    right: '0px',
    width: '20px',
    height: '20px',
  },
};

class Preview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      originalMsg: false,
    };
  }

  static propTypes = {
    request: PropTypes.object.isRequired,
    onSend: PropTypes.func,
    onClose: PropTypes.func,
    showTerms: PropTypes.bool,
    showButtons: PropTypes.bool,
    cancelLabel: PropTypes.string,
    sendLabel: PropTypes.string,
    previousValues: PropTypes.object,
    forceUpdate: PropTypes.number,
    loading: PropTypes.bool,
    editable: PropTypes.bool,
    showPorts: PropTypes.bool,
  };
  static defaultProps = {
    showTerms: true,
    showButtons: true,
    cancelLabel: 'CANCEL',
    sendLabel: 'CREATE',
    editable: false,
    showPorts: true,
    requestTypeSelectorEnabled: false,
  };
  static contextTypes = {
    contracts: PropTypes.array,
    store: PropTypes.object,
    indexedVoyageTerms: PropTypes.array,
    showMessage: PropTypes.func
  };
  handleShowOriginalMsg = () => {
    this.setState(state => ({ originalMsg: !state.originalMsg }));
  };

  componentWillMount() {
    const contract =
      this.props.request.contract &&
      this.context.contracts.find(
        c =>
          c._id === this.props.request.contract ||
          c._id === this.props.request.contract._id,
      );
    this.setState({
      contract: contract || {},
      readinessDate: this.props.request.readinessDate,
      cancellingDate: this.props.request.cancellingDate,
    });
  }

  // shouldComponentUpdate(nextProps, nextState, nextContext) {
  //   return (
  //     nextProps.request !== this.props.request ||
  //     nextProps.loading !== this.props.loading ||
  //     this.props.previousValues !== nextProps.previousValues ||
  //     !!(nextProps.forceUpdate &&
  //       this.props.forceUpdate !== nextProps.forceUpdate)
  //   );
  // }

  componentWillReceiveProps(nextProps, nextContext) {
    if (
      nextProps.request.contract &&
      this.state.contract._id !== (nextProps.request.contract._id || nextProps.request.contract )
    ) {
      this.setState({ contract: this.context.contracts.find(
          c =>
            c._id === nextProps.request.contract ||
            c._id === nextProps.request.contract._id,
        )});
    }
  }

  openTermsDetails = (termsLink) => {
    this.context.store.dispatch(staticPopup(termsLink, true));
  };

  handleReadinessChange = (e, value) => {
    this.setState({ readinessDate: value, updatePreview: Math.random() });
    if (
      this.state.cancellingDate &&
      value.getTime() > this.state.cancellingDate.getTime()
    ) {
      this.refs.cancellingDate.resetValue();
      this.setState({ cancellingDate: undefined });
    }
  };
  handleCancellingChange = (e, value) => {
    this.setState({ cancellingDate: value });
  };

  handleSaveTag = async (entityId, tags) => {
    try {
      const res = await TagsAPI.saveTag("cargoRequest", {
        toAdd: tags.map(t => transformTagBeforeSend(t))
      });
      if (this.props.handleTags) {
        this.props.handleTags(res.data)
      }
      this.context.showMessage({
        message: `Tag has been successfully added`
      });
    } catch (error) {
      console.error(error);
      this.context.showMessage({
        level: "error",
        message: `Error adding tag: ${error?.message || error}`
      });
    }
  }

  handleDeleteTag = tag => {
    const { tags = [] } = this.props;
    if (!tags.length) return;
    const newTags = tags.filter(t => t._id !== tag._id);

    if (this.props.handleTags) {
      return this.props.handleTags(newTags);
    }
  }

  handleSend = async () => {
    const { previewForm } = this.refs;
    if (!previewForm) return;

    previewForm.submit();

    const model = previewForm.getModel();

    if (!previewForm.state.isValid && previewForm.inputs.length) return;

    return this.props.onSend(model);
  }

  handleClose = () => {
    const { previewForm } = this.refs;
    const model = previewForm.getModel();
    this.props.onClose(model);
  }

   findRowTypeInErrors = errors => rowName => errors?.[rowName] && Object.keys(errors[rowName]).length > 0;

  //TODO use Diff component instead {?<div className={s.old}>...}
  render() {
    const { request, previousValues = {}, errors, isEditPort = true } = this.props;
    const now = new Date();

    const errorsTable = parseErrorsArray(errors);

    const isInvalidRow = this.findRowTypeInErrors(errorsTable);

    return (
      <div className={cx(s.root, this.props.monitor ? s.root_monitor : '')}>
        <Formsy.Form ref="previewForm" className={cx('row', s.wrapper_info_open)}>
          {this.props.requestTypeSelectorEnabled ? <CargoRequestType email={request.realUserEmail} /> : null}
          <div className="w-100" />
            { request.account ? [
              <div key={0} className="col preview_label">ACCOUNT NAME</div>,
              <div
                key={1}
                className={cx(
                  'col-12 col-md-auto preview_value grow',
                )}
              >
                {request.account.name || request.account}
              </div>
            ] : null }
            { request.clientRefNo ? [
              <div key={0} className="col preview_label">Cargo Reference(s)</div>,
              <div
                key={1}
                className={cx(
                  'col-12 col-md-auto preview_value grow',
                )}
              >
                { request.clientRefNo }
              </div>
            ] : null }
            { request.incoterms?.kind ? [
              <div key={0} className="col preview_label">INCOTERMS</div>,
              <div
                key={1}
                className={cx(
                  'col-12 col-md-auto preview_value grow',
                )}
              >
                { `${request.incoterms.kind}` }
                { request.incoterms.place ? `, Port or place: ${request.incoterms.place}` : null }
              </div>
            ] : null }

          <div className="w-100" >
            <PolPodPreview
              loading={request.loading}
              unloading={request.unloading}
              errors={errorsTable}
            />
          </div>
          <hr style={{ marginBottom: '6px' }} />
          <div className={cx('col preview_label', s.cargo_label, isInvalidRow("CARGO") ? s.error_field : "")}>CARGO</div>
          <div
            className={cx(
              'col-12 col-md-auto preview_value grow',
              s.cargo_type,
            )}
          >
            <div className="w-100">
              <CargoList errors={errorsTable["CARGO"]} request={request} />
              <Attachments attachments={request.files} />
            </div>
          </div>
          <div className="w-100" />
          <hr style={{ marginTop: '6px' }} />
          <div className="col-12"> <LDRatesView loading={request.ldRates.loading} discharging={request.ldRates.discharging}/></div>
          <hr />
          {request.description
            ? [
              <div key="0" className="col preview_label">DESCRIPTION</div>,
              <div key="1" className="col pre_line">
                {request.description}
              </div>,
            ]
            : null}
          {this.props.showTerms
            ? <div className="col-12">
              <div className="row">
                {request.totalWeightDeviation
                  ? [
                    <div key="0" className="col preview_label">
                      TOTAL WEIGHT +-,%
                    </div>,
                    <div
                      key="1"
                      className={cx(
                        'col-12 col-md-auto preview_value grow',
                        s.rate,
                      )}
                    >
                      {previousValues.totalWeightDeviation &&
                      previousValues.totalWeightDeviation.value !==
                      request.totalWeightDeviation.value
                        ? <span className={s.old}>
                          {number(previousValues.totalWeightDeviation.value)}
                          %
                        </span>
                        : null}
                      <span className={s.new}>
                        {number(request.totalWeightDeviation.value)}%
                      </span>
                      ,
                      {previousValues.totalWeightDeviation &&
                      previousValues.totalWeightDeviation.term !==
                      request.totalWeightDeviation.term
                        ? <span className={s.old}>
                          {previousValues.totalWeightDeviation.term}
                        </span>
                        : null}
                      <span className={s.new}>
                        {request.totalWeightDeviation.term}
                      </span>
                    </div>,
                    <div key="2" className="w-100" />,
                  ]
                  : null}

                {request.totalVolumeDeviation
                  ? [
                    <div key="0" className="col preview_label">
                      TOTAL VOLUME +-,%
                    </div>,
                    <div
                      key="1"
                      className={cx(
                        'col-12 col-md-auto preview_value grow',
                        s.rate,
                      )}
                    >
                      {previousValues.totalVolumeDeviation &&
                      previousValues.totalVolumeDeviation.value !==
                      request.totalVolumeDeviation.value
                        ? <span className={s.old}>
                          {number(previousValues.totalVolumeDeviation.value)}
                          %
                        </span>
                        : null}
                      <span className={s.new}>
                        {number(request.totalVolumeDeviation.value)}%
                      </span>
                      ,
                      {previousValues.totalVolumeDeviation &&
                      previousValues.totalVolumeDeviation.term !==
                      request.totalVolumeDeviation.term
                        ? <span className={s.old}>
                          {previousValues.totalVolumeDeviation.term}
                        </span>
                        : null}
                      <span className={s.new}>
                        {request.totalVolumeDeviation.term}
                      </span>
                    </div>,
                    <div key="2" className="w-100" />,
                  ]
                  : null}
                {request.freight
                  ? [
                    <div key="0" className="col preview_label">
                      FREIGHT
                    </div>,
                    <div
                      key="1"
                      className={cx(
                        'col-12 col-md-auto preview_value grow',
                        s.rate,
                      )}
                    >
                      {previousValues.freight &&
                      previousValues.freight.value !==
                      request.freight.value
                        ? <span className={s.old}>
                          ${number(previousValues.freight.value)}
                        </span>
                        : null}
                      <span className={s.new}>
                        ${number(request.freight.value)}
                      </span>
                      ,
                      {previousValues.freight &&
                      previousValues.freight.term !== request.freight.term
                        ? <span className={s.old}>
                          {previousValues.freight.term}
                        </span>
                        : null}
                      <span className={s.new}> {request.freight.term}</span>
                      ,
                      {previousValues.freight &&
                      previousValues.freight.method !==
                      request.freight.method
                        ? <span className={s.old}>
                          {previousValues.freight.method}
                        </span>
                        : null}
                      <span className={s.new}>
                        {' '}{request.freight.method}
                      </span>
                    </div>,
                    <div key="3" className="w-100" />,
                  ]
                  : null}
                {request.demurrage
                  ? [
                    <div key="0" className="col preview_label">
                      DEMURRAGE
                    </div>,
                    <div
                      key="1"
                      className={cx(
                        'col-12 col-md-auto preview_value grow',
                      )}
                    >
                      {previousValues.demurrage &&
                      previousValues.demurrage !== request.demurrage
                        ? <span className={s.old}>
                            ${number(previousValues.demurrage)}
                        </span>
                        : null}
                      <span className={s.new}>
                        ${number(request.demurrage)}
                      </span>
                      <span> per day</span>
                    </div>,
                    <div key="3" className="w-100" />,
                  ]
                  : null}
                {request.detention
                  ? [
                    <div key="0" className="col preview_label">
                      DETENTION
                    </div>,
                    <div
                      key="1"
                      className={cx(
                        'col-12 col-md-auto preview_value grow',
                      )}
                    >
                      {previousValues.detention &&
                      previousValues.detention !== request.detention
                        ? <span className={s.old}>
                            ${number(previousValues.detention)}
                        </span>
                        : null}
                      <span className={s.new}>
                        ${number(request.detention)}
                      </span>
                      <span> per day</span>
                    </div>,
                    <div key="3" className="w-100" />,
                  ]
                  : null}

                <div className="col preview_label">TERMS</div>
                <div className={cx('col-12 col-md-auto preview_value grow', s.terms_wrapper)}>
                  <Terms
                    openTermsDetails={this.openTermsDetails}
                    previousValues={previousValues.terms}
                    terms={request.terms}
                    indexedTerms={this.context.indexedVoyageTerms}
                    billOfLading={request.billOfLading}
                    previousBillOfLading={previousValues.billOfLading}
                  />
                </div>
                <div className="w-100" />
                <div className="col preview_label">CONTRACT</div>
                <div className={cx('col-12 col-md-auto preview_value grow')}>
                  {previousValues.contract &&
                  previousValues.contract._id !== this.state.contract._id
                    ? <span className={s.old}>
                      <ContractLink contract={previousValues.contract} />
                    </span>
                    : null}
                  <span className={s.new}>
                    <ContractLink contract={this.state.contract} />
                  </span>

                </div>
                <div className="w-100" />

                <div className="col preview_label">COMM</div>
                <div
                  key="1"
                  className={cx(
                    'col-12 col-md-auto preview_value grow',
                  )}
                >
                  <span>
                  {previousValues.comm || request.comm ?
                    <span>
                      <Diff oldValue={formatNumberOrEmpty(previousValues.comm)} newValue={formatNumberOrEmpty(request.comm)} />%{' '}
                    </span>
                    : '--- '}
                    <Diff
                      oldValue={previousValues.pus !== undefined ? ((previousValues.pus && 'PUS') || '') : undefined}
                      newValue={(request.pus && 'PUS') || ''}
                    />{' '}
                    <Diff
                      oldValue={previousValues.deductible !== undefined ? ((previousValues.deductible && 'Deductable') || '') : undefined}
                      newValue={(request.deductible && 'Deductable') || ''}
                    />
                  </span>
                </div>
                <div key="2" className="w-100" />

              </div>
            </div>
            : null}
          <div className="w-100" />
          {request.canViewOriginalMail ?
            <OriginalMessage _id={request._id} />
            : null}
          { this.props.user ? (
            <div className={s.preview_tags}>
              <Tags collapseProps={{ additionals: <Info link={ <a href="/settings/tags" target="_blank">Open tags settings</a> } /> }} savePromise={this.handleSaveTag} handleDelete={this.handleDeleteTag} tags={this.props.tags || []} entity="cargoRequest"/>
            </div>
          ) : null}
          {this.props.showButtons
            ? <div className={cx('col-12', s.footer)}>
                {
                  request.simpleForm?.length ? (
                    <RaisedButton
                        label="BACK TO SIMPLE FORM"
                        disableTouchRipple
                        disableFocusRipple
                        secondary
                        onClick={this.props.handleBackToSimpleForm}
                        style={{
                          borderRadius: '15px',
                        }}
                        buttonStyle={{
                          borderRadius: '15px',
                        }}
                    />
                  ) : null
                }
              <RaisedButton
                label={this.props.cancelLabel}
                disableTouchRipple
                disableFocusRipple
                secondary
                onClick={this.handleClose}
                style={{
                  borderRadius: '15px',
                }}
                buttonStyle={{
                  borderRadius: '15px',
                }}
              />
              <SpinnerButton
                label={this.props.sendLabel}
                disableTouchRipple
                disableFocusRipple
                primary
                disabled={errors?.length}
                onClick={this.handleSend}
                style={{
                  borderRadius: '15px',
                }}
                buttonStyle={{
                  borderRadius: '15px',
                }}
              />
            </div>
            : null}
          {this.props.footer}
        </Formsy.Form>

      </div>
    );
  }
}

function renderFields(fields, values, cargoName, errors) {
  const ret = [];
  const packingName = values['[name]'];
  if (!fields) {
    return ret;
  }
  fields.forEach((f) => {
    let label;
    let value;
    if(f.toPreview) {
      const lv = f.toPreview(values, f.name, f);
      label = lv.label;
      value = lv.value;
      if (errors) { //TODO deduplicate
        const isFieldError = Object.keys(errors).find(key => f.name.includes(key));

        if (isFieldError) {
          ret.push(<span className={s.error_field}>{`${label}: ${errors[isFieldError]}`}</span>);
        }
      }
      if (value) {
        if (label) {
          ret.push(<div key={f.name}>{label}:&nbsp;{typeof value === 'number' ? number(value) : value}</div>);
        } else {
          ret.push(<div key={f.name}>{typeof value === 'number' ? number(value) : value}</div>);
        }
      }
      return ret;
    } else { //TODO
       label = f.label && f.label
        .replace('{{packingName}}', packingName)
        .replace('{{cargoName}}', cargoName)
        .replace(/,? ?\({{\w+}}\)/, '')
        .replace(/,? ?{{\w+}}/, '');
       value = values[f.name];
    }

    if (
      value === null ||
      value === undefined ||
      value === false ||
      value === '' ||
      value === 0 ||
      f.type === 'dummy'
    ) {

      if (errors) {
        const isFieldError = Object.keys(errors).find(key => f.name.includes(key));

        if (isFieldError) {
          ret.push(<span className={s.error_field}>{`${label}: ${errors[isFieldError]}`}</span>);
        }
      }

      return;
    }
    if (f.type === 'checkbox') {
      ret.push(<div key={f.name}>{label}</div>);
      if (
        f.fields?.length
      ) {
        ret.push(...renderFields(f.fields, values));
      }
      return;
    }
    const hasUnit = f.name.indexOf('value') !== -1;
    let unit;
    let deviation;
    let term;

    if (hasUnit) {
      const namePartToReplace = 'value';
      unit = values[f.name.replace(namePartToReplace, 'unit')] || '';
      deviation = values[f.name.replace(namePartToReplace, 'deviation')];
      if (deviation) {
        term = values[f.name.replace('value', 'term')];
      }
    }
    if(f.name === '[dangerous][class]' && Array.isArray(value)) {
      value = value.join(', ');
    }
    if (typeof value === 'number') {
      value = number(value);
    }
    if (deviation && typeof deviation === 'number') {
      deviation = number(deviation);
    }
    ret.push(
      <div key={f.name}>
        {label}
        :
        {' '}
        {value}
        {' '}
        {unit}
        {' '}
        {deviation ? `, +/- ${deviation || ''} %, ${term || ''}` : null}
      </div>,
    );
  });

  return ret;
}

class CargoPreview extends PureComponent {
  static defaultProps = {fields: fieldsConfig}

  render() {
    let {fields, cargo, index, errors} = this.props;
    if (cargo.typeOfTransportation === 'container') {
      cargo.packing.pack.containerDetails = cargo.containerDetails;
    }
    const {config} = getConfigFields(cargo, cargo.cargoType, cargo.typeOfTransportation, cargo.packing.pack);
    const isPacked = cargo.packing.category === 'packed';
    const cargoName = cargo.cargoName.name || cargo.cargoName;
    if (cargo.dangerous &&
      (cargo.dangerous.unNumber || (cargo.dangerous.class && cargo.dangerous.class[0]))) {
      cargo.dangerous.value = true;
    }
    const values = {
      ...cargo.packing.pack,
      ...{
        military: cargo.military,
        propelled: cargo.propelled,
        dangerous: cargo.dangerous,
      },
    };
    const formValues = formDataToObject.fromObj({'': values}, (key, val) => Array.isArray(val));
    const {category} = cargo.packing;
    let categoryFieldsKey;
    let categoryLabel;
    switch (category) {
      case 'packed':
        categoryFieldsKey = 'packed';
        categoryLabel = 'Packed';
        break;
      case 'bulk':
        categoryFieldsKey = 'bulk';
        categoryLabel = 'Bulk';
        break;
      case 'loose':
        categoryFieldsKey = 'unpacked';
        categoryLabel = 'Loose';
        break;
      case 'unpacked':
        categoryFieldsKey = 'unpacked';
        categoryLabel = 'Unpacked';
        break;
      case 'container':
        categoryFieldsKey = 'unpacked';
        categoryLabel = 'Container';
        break;
      default:
        categoryFieldsKey = 'unpacked';
        categoryLabel = '';
    }

    return (
      <div className={s.fields_container}>
        {renderFields(config.stowage, formValues, cargoName, errors)}
        {config.packingCategories &&
        config.packingCategories.length > 1 &&
        categoryLabel
          ? <div className={s.field_label}>{categoryLabel}</div>
          : null}
        {renderFields(
          config[categoryFieldsKey],
          formValues,
          cargoName,
        )}
        {cargo.clauses && cargo.clauses.length
          ? cargo.clauses.map((f) => {
            if (f.value) {
              if (f.value === true) {
                return <div key={f.name}>{f.name}</div>;
              }
              return <div key={f.name}>{f.name}: {f.value}</div>;
            }
            return null;
          })
          : null}

        {cargo.terms && cargo.terms.length
          ? <span>
          <div>Terms:</div>
            {cargo.terms.map((t, i) => <div key={i}>{t.number || (i + 1)}. {t.value}</div>)}
        </span>
          : null}
          {cargo.additionalCargoDetails && <div className={s.field_label}>Additional details: {cargo.additionalCargoDetails}</div>}
      </div>
    );
  }
}

export const CargoPreviewWithStyles = withStyles(s)(CargoPreview);

function getCQDLabel(cqd) {
  switch (cqd){
    case true:
      return 'CQD';
    case null:
      return 'As Fast as Vessel Can';
  }
}

function Rate({ rate, previousValues, errors }) {

  const quantityError = errors && getPreviewErrorMsg(errors.quantity);
  const dimensionError = errors && getPreviewErrorMsg(errors.dimension);
  const prevCQDLabel = getCQDLabel(previousValues?.cqd);
  const nextCQDLabel = getCQDLabel(rate?.cqd);

  if (quantityError || dimensionError) {
    return (
      <span>
        {
          quantityError ? <span className={s.error_field}>{ quantityError }</span> : <span>{nextCQDLabel ? nextCQDLabel : rate.quantity || ""}</span>
        }
        {
          dimensionError ? <span className={s.error_field}>, { dimensionError }</span> : <span>{rate.dimension || ""}</span>
        }
      </span>
    )
  }

  if ((!rate && !nextCQDLabel) || (!nextCQDLabel && !rate.quantity && !rate.dimension)) {
    return <span>---</span>;
  }
  return (
    <span>
      {previousValues && prevCQDLabel !== nextCQDLabel
        ? <span className={s.old}>{prevCQDLabel}</span>
        : null}
      <span className={s.new}>{nextCQDLabel}</span>
      {previousValues && previousValues.quantity !== rate.quantity
        ? <span className={s.old}>{previousValues.quantity ? number(previousValues.quantity) : ''}</span>
        : null}
      <span className={s.new}>{rate.quantity ? number(rate.quantity) : ''}</span>
      {previousValues && previousValues.dimension !== rate.dimension
        ? <span className={s.old}>{previousValues.dimension || ''}</span>
        : null}
      <span className={s.new}>{rate.dimension || ''}</span>
      {previousValues && previousValues.speed !== rate.speed
        ? <span className={s.old}>{previousValues.speed}</span>
        : null}
      <span className={s.new}>{rate.speed || ''} </span>
      {previousValues && previousValues.abbreviation !== rate.abbreviation
        ? <span className={s.old}>
          {(previousValues.abbreviation || '').toLocaleUpperCase()}
        </span>
        : null}
      <span className={s.new}>{(rate.abbreviation || '').toLocaleUpperCase()}</span>
      {(rate.term || (previousValues && previousValues.term)) ? ',' : ''}
      {previousValues && previousValues.term !== rate.term
        ? <span className={s.old}>
          {(previousValues.term || '').toLocaleUpperCase()}
        </span>
        : null}
      <span className={s.new}>{(rate.term || '').toLocaleUpperCase()}</span>
    </span>
  );
}

// eslint-disable-next-line no-shadow
class _Terms extends Component {

  static defaultProps = {

  }

  state = {
    usersCollapsed: false,
    defaultCollapsed: false
  }

  render() {
    let { terms, previousValues, headerStyle = {}, openTermsDetails, indexedTerms, billOfLading = [], previousBillOfLading } = this.props;
    const { usersCollapsed, defaultCollapsed } = this.state;
    if (!terms) return null;

    if (!terms.default) {
      terms.default = [];
    }
    if (!terms.user) {
      terms.user = [];
    }
    terms.default.forEach((t) => {
      if (!t || !indexedTerms[t.index]) {
        return;
      }
      t.number = indexedTerms[t.index].number;
    });

    const reg = /{{(select|input|info|previousValues)}}/g;

    function getTermsText(term, index) {
      const currentTerm = indexedTerms[index];
      if (!currentTerm) {
        return 'New term, please refresh page!';
      }
      const parsedTemplate = [];
      let previousMatchIndex = 0;
      const number = currentTerm.number || currentTerm.index;
      let i = 0;
      let template = currentTerm.template;
      if (currentTerm.conditions && term.selectedValues) {
        for (let j = 0; j < currentTerm.conditions.length; j++) {
          const condition = currentTerm.conditions[j];
          const currentValue = term.selectedValues[condition.index];
          const previousValue =
            previousValues &&
            previousValues.default[index] &&
            previousValues.default[index].selectedValues[condition.index];
          if (
            currentValue == condition.value ||
            previousValue == condition.value
          ) {
            template = condition.template;
            if (previousValue !== undefined && currentValue !== previousValue) {
              template =
                condition.template.substring(0, condition.position) +
                condition.previousTemplatePart +
                condition.template.substring(condition.position);
              previousValues.default[index].valuesPrefix =
                condition.valuesPrefix || ' ';
              if (currentValue !== condition.value) {
                previousValues.default[index].useCurrentValues = true;
              } else if (previousValue !== condition.value) {
                previousValues.default[index].useCurrentValues = false;
              }
            }

            break;
          }
        }
      }
      template.replace(reg, (match, p1, offset, string) => {
        const before = string.substring(previousMatchIndex, offset);
        previousMatchIndex = offset + match.length;
        parsedTemplate.push(before);
        if (
          p1 === 'previousValues' &&
          previousValues &&
          previousValues.default[index] &&
          previousValues.default[index].selectedValues
        ) {
          let joinedValues;
          let className;
          if (previousValues.default[index].useCurrentValues) {
            joinedValues = term.selectedValues.map(formatStringAsNumber).join(' ');
            className = s.redText;
          } else {
            joinedValues = previousValues.default[index].map(formatStringAsNumber).selectedValues.join(' ');
            className = s.strikeStyle;
          }
          parsedTemplate.push(
            <span className={className}>
              {previousValues.default[index].valuesPrefix + joinedValues}
            </span>,
          );
          return;
        }
        if (p1 === 'info') {
          parsedTemplate.push(
            <ActionInfoOutline
              style={styles.info}
              key={i}
              className={s.info_icon}
              onClick={openTermsDetails.bind(
                null,
                currentTerm.options[0].termsLink,
              )}
            />,
          );
        } else {
          let formatter = dummyFormatter;
          if (currentTerm.options?.[i]?.validations && currentTerm.options[i].validations.indexOf('isNumeric') !== -1) {
            formatter = formatStringAsNumber;
          }
          // eslint-disable-next-line no-lonely-if
          if (previousValues) {
            if (
              previousValues.default[index] &&
              previousValues.default[index].selectedValues &&
              previousValues.default[index].selectedValues[i] !==
              term.selectedValues[i]
            ) {
              parsedTemplate.push(
                <span className={s.strikeStyle}>
                  {formatter(previousValues.default[index].selectedValues[i])}
                </span>,
              );
              parsedTemplate.push(
                <span className={s.redText}> {formatter(term.selectedValues[i])}</span>,
              );
            } else {
              parsedTemplate.push(formatter(term.selectedValues[i]));
            }
          } else {
            parsedTemplate.push(formatter(term.selectedValues[i]));
          }
        }

        i += 1;
      });
      parsedTemplate.push(template.substring(previousMatchIndex));

      function returnFullTerms() {
        return parsedTemplate.map((item, j) => <span key={j}>{item}</span>);
      }

      if (!previousValues) {
        if (term.checked === undefined) return null;
        if (term.checked) return <span>{returnFullTerms()}</span>;
      }

      if (previousValues) {
        if (term.new) {
          return <span className={s.redText}>{returnFullTerms()}</span>;
        }
        if (term.unchecked) {
          return (
            <span className={s.strikeStyle}>{returnFullTerms()}</span>
          );
        }
        if (term.checked === false) {
          return (
            <span className={s.uncheckedEarlierTermText}>
              {returnFullTerms()}
            </span>
          );
        }
        if (term.checked) {
          return <span>{returnFullTerms()}</span>;
        }
      }
    }

    let mergedUserTerms = [];
    if (previousValues) {
      previousValues.user.forEach((put) => {
        const removed = terms.user.findIndex(ut => ut.value === put.value) === -1;
        if (removed) {
          put.removed = true;
          mergedUserTerms.push(put);
        }
      });
      terms.user.forEach((ut) => {
        const added =
          previousValues.user.findIndex(put => put.value === ut.value) === -1;
        ut.added = added;
        mergedUserTerms.push(ut);
      });
    } else {
      mergedUserTerms = terms.user;
    }
    let mergedBillOfLading = [];
    if (previousBillOfLading) {
      previousBillOfLading.forEach((put) => {
        const removed = billOfLading.findIndex(ut => ut.value === put.value) === -1;
        if (removed) {
          put.removed = true;
          mergedBillOfLading.push(put);
        }
      });
      billOfLading.forEach((ut) => {
        const added =
          previousBillOfLading.findIndex(put => put.value === ut.value) === -1;
        ut.added = added;
        mergedBillOfLading.push(ut);
      });
    } else {
      mergedBillOfLading = billOfLading;
    }


    return (
      <div className={s.terms}>
        {terms.default && terms.default.length
          ?
          <div className={s.terms_general} >
            <p style={{display: 'flex', alignItems: 'center', ...headerStyle}} className={cx(s.terms_header, s.standard_terms)}><IconButton onClick={() => this.setState(state => ({defaultCollapsed: !state.defaultCollapsed}))} style={{marginRight: 8, padding: '0px', height: 'auto', width: 'auto'}}>
              {!defaultCollapsed ? <ArrowUp style={{width: 14, height: 14}}/> : <ArrowDown style={{width: 14, height: 14}}/>}
            </IconButton>Standard terms</p>
            {
              !defaultCollapsed
              ? (
                <ul>
                  {
                    terms.default.filter(item => !!item).sort((a, b) => a.number - b.number).map(item => (
                      <li key={item.index} className={s.term}>
                        {getTermsText(item, item.index)}
                      </li>
                    ))
                  }
                </ul>
              )
              : null
            }
          </div>
          : null}

        {mergedUserTerms.length
          ?
          <div className={s.terms_general}>
            <div style={{display: 'flex', alignItems: 'center'}} className={s.terms_header}><IconButton onClick={() => this.setState(state => ({usersCollapsed: !state.usersCollapsed}))} style={{marginRight: 8, padding: '0px', height: 'auto', width: 'auto'}}>
              {!usersCollapsed ? <ArrowUp style={{width: 14, height: 14}}/> : <ArrowDown style={{width: 14, height: 14}}/>}
            </IconButton>Additional terms</div>
            {
              !usersCollapsed
              ? (
                <ul>
                  {mergedUserTerms.map((t) => {
                    let className = s.term;
                    if (t.removed) {
                      className += ' ' + s.strikeStyle;
                    }
                    if (t.added) {
                      className += ' ' + s.redText;
                    }
                    return <li key={t.value} className={className}>{t.value}</li>;
                  })}
                </ul>
              )
              : null
            }
          </div>
          : null}
        {mergedBillOfLading.length
          ?
          <div className={s.terms_general}>
            <div className={s.terms_header}>Bill of lading remarks</div>
            <ul>
              {
                mergedBillOfLading.map((t) => {
                  let className = s.term;
                  if (t.removed) {
                    className += ' ' + s.strikeStyle;
                  }
                  if (t.added) {
                    className += ' ' + s.redText;
                  }
                  return <li key={t.value} className={className}>{t.value}</li>;
                })
              }
            </ul>
          </div>
          : null}
      </div>
    );
  }
}

export class PackingListDialog extends PureComponent {
  state = { opened: false };
  toggle = () => {
    this.setState({ opened: !this.state.opened });
  };

  render() {
    return (<div>
      <a onClick={this.toggle}>Packing list</a>
      {this.state.opened ?
        <Dialog
          handleClose={this.toggle}
          open
          title="Packing list"
          className={s.packing_dialog}
        >
          <PackingList readOnly data={this.props.data} />
        </Dialog>
        : null}
    </div>);
  }
}

export function CargoList({ request, errors, additionalDetailsEditable, prevRequest = request, additionalDetailsProps = {} }) {

  const renderErrorsOrInfo = (cargo, i) => {
    const cargoNameError = getPreviewErrorMsg(errors?.cargoName, i);
    const totalWeightError = getPreviewErrorMsg(errors?.unitsWeight, i);
    const totalVolumeError = getPreviewErrorMsg(errors?.unitsVolume, i);
    let title = constructCargoTitle(cargo);
    if (cargo.containerDetails?.kind && cargo.packing.pack.bundlesQuantity) {
      const containerType = findContainerTypeById(cargo.containerDetails.kind);
      title = `${cargo.packing.pack.bundlesQuantity}x${containerType?.name || 'Container'}`;
    }
    return (
      <div className={s.cargo_name}>
        <div>
          {
            cargoNameError
              ? (
                <span className={s.error_field}>
                              {
                                cargoNameError
                              }
                            </span>
              )
              : title
          }
          {
            totalVolumeError || totalWeightError
              ? (
                <span className={s.error_field}>,&nbsp;
                  {
                    totalVolumeError || totalWeightError
                  }
                            </span>
              )
              : null
          }
        </div>
        <div>
          {cargo.hsCode ? `HS Code: ${cargo.hsCode.code || cargo.hsCode}` : null}
        </div>

      </div>
    )
  }

  return (
    <div className={s.cargo_list_container}>
      { request.cargo.length ? (
        request.cargo.map((cargo, i) => {
          const prevCargo = prevRequest.cargo[i];
          const isLast = i === request.cargo.length - 1;
          const hideBorder = isLast && !cargo.additionalCargoDetails && !prevCargo.additionalCargoDetails && !additionalDetailsEditable;
         return (<div>
            <List key={i} className={cx(s.cargo_list, hideBorder && s.noborder)}>
              <ListItem
                className={s.list_item}
                initiallyOpen={false}
                nestedItems={[
                  cargo?.cargoName ? <CargoPreview
                    index={i}
                    errors={errors ? Object.keys(errors).filter(key => Array.isArray(errors[key]) ? errors[key].find(err => err.index == i) : errors[key].index == i).reduce((acc, key) => {
                      const error = errors[key];
                      if (error) {
                        const errorMsg = Array.isArray(error) ? error.find(err => err.index == i).config.error : error.config.error;

                        acc[key] = errorMsg;
                      }
                      return acc;
                    }, {}) : null}
                    key="0"
                    cargo={cargo}
                  /> : renderErrorsOrInfo(cargo, i),
                  (cargo.packingList?.length ? <PackingListDialog data={cargo.packingList} /> : null),
                ]}
                primaryTogglesNestedList
                hoverColor="transparent"
                disableKeyboardFocus
              >
                {
                  renderErrorsOrInfo(cargo, i)
                }
              </ListItem>
            </List>
           {additionalDetailsEditable ? (
             <AdditionalCargoDetailsEdit
               request={request}
               index={i}
               value={cargo.additionalCargoDetails}
               name={`cargo[${i}][additionalCargoDetails]`}
               {...additionalDetailsProps}
             />
           ) : (
             <Diff newValue={cargo.additionalCargoDetails} colorScheme={'multiline'} oldValue={prevCargo.additionalCargoDetails} br />
           )}
          </div>);
        })
      )
        : (
          errors?.cargoName
            ? (
              <span className={s.error_field} style={{padding: '10px 0px', display: 'flex', height: '100%', alignItems: 'center'}}>
                  {
                    getPreviewErrorMsg(errors.cargoName, 0)
                  }
                </span>
            )
            : null
        )}

    </div>
  );
}

class OriginalMessage extends PureComponent {
  state = {};

  loadMessage = async () => {
    if(this.state.loading || this.state.originalMsg) {
      return;
    }
    this.state.loading = true;
    this.setState({loading: true});
    const res = await CargoApi.getOriginalMail(this.props._id);
    this.state.loading = false;
    this.setState({loading: false, originalMsg: res.data });
    return res;
  }

  handleToggleMessage = async () => {
    await this.loadMessage();
    this.setState({ opened: !this.state.opened });
  };

  render() {
    return (<div className={s.original_message}>
      <div className={s.title} onClick={this.handleToggleMessage}>
        <EmailIcon
          viewBox="0 0 20 20"
          style={{
            width: '20px',
            height: '16px',
          }}
          color="#B2B2B2"
        />
        <p>{this.state.opened ? 'Hide' : 'Show'} original message</p>
      </div>
      {this.state.opened ?
        <p className={s.body_message}>
          {this.state.originalMsg.subject}
          <br/>
          <pre>{this.state.originalMsg.text}</pre>
        </p>
        : null}
    </div>)
  }
}

export default withStyles(s)(Preview);
export const Terms = withStyles(s)(_Terms);
export const CargoListView = withStyles(s)(CargoList)
export const RatePreview = withStyles(s)(Rate);
