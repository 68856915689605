import React from 'react'
import s from './CustomTooltip.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
function CustomTooltip({ children, dark = true, horizontalAlign = "center", verticalAlign = "bottom", tooltip, className = "", tooltipStyle={}, ...rest }) {
  return (
    <div className={cx(s.tooltip_wrapper, horizontalAlign === "right" ? s.right_aligned : "", horizontalAlign === "left" ? s.left_aligned : "", verticalAlign === 'top' && s.top_aligned, verticalAlign === 'center' && s.verticalCenterAligned)} {...rest}>
        {
          children
        }
      <div style={tooltipStyle} className={cx(s.tooltip_wrapper_tip, dark ? s.dark : "", className)}>
        { tooltip }
      </div>
    </div>
  )
}

export default withStyles(s)(CustomTooltip)
