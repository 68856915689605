import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Formsy from 'formsy-react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './PositionDialog.scss';
import cx from 'classnames';
import RaisedButton from '../../Common/RaisedButton';
import PortAutocomplete from '../../Common/PortAutocomplete';
import DatePicker from '../../Common/FormsyDate';
import FormsyText from '../../Common/FormsyText';
import Dialog from '../../Common/Dialog';
import { toUTC } from '../../../core/utils';
import formDataToObject from 'form-data-to-object';
import Link from '../../Link/Link';
import { getVesselsList} from '../../../actions/vessel';
import ChannelStatus from './../../Common/ChannelStatus';
import CustomAutocomplete from '../../Common/CustomAutocomplete/CustomAutocomplete.js';
import WithFindContextual from '../../Common/CustomAutocomplete/WithFindContextual.js';
import Maintenance from './Maintenance';
import { NarrowPortAutocomplete } from '../../NewInputs/NarrowPortAutocomplete';
import NarrowFormsyDate from '../../NewInputs/NarrowFormsyDate';
import NarrowFormsyText from '../../NewInputs/NarrowFormsyText';

const dialogContentStyle = { width: 485 };

class PositionDialog extends Component {
  static propTypes = {
    vessel: PropTypes.string,
    position: PropTypes.object,
    handleClose: PropTypes.func,
    handleSendNewPosition: PropTypes.func,
    vesselList: PropTypes.object,
    getVesselsList: PropTypes.func,
    createLink: PropTypes.bool
  };

  static defaultProps = {
    vesselList: { data: [] },
    createLink: '/fleetmonitor/myfleet/create'
  };

  constructor(props) {
    super(props);
    this.props.getVesselsList();

    this.state.vessels = this.props.vesselList.data.filter(v => !v.status || (v.status.name !== 'claimRights' && v.status.name !== 'notConfirmed'));

    this.autocomplete = null;
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.vesselList.data !== this.props.vesselList.data) {
      this.setState({ vessels: nextProps.vesselList.data.filter(v => !v.status || (v.status.name !== 'claimRights' && v.status.name !== 'notConfirmed')) });
    }
  }

  componentDidMount() {
    if (this.props.position) {
      const formData = formDataToObject.fromObj(this.props.position);
      formData['open[where]'] = this.props.position.open.name;
      formData['open[from]'] = this.props.position.open.from;
      formData['open[until]'] = this.props.position.open.until;
      this.refs.form.reset(formData);
    }
  }
  // see mapping for statuses in ChannelStatus component. Default maps to "Open". 7.06.2021 : remove default value
  state = {
    //status: ChannelStatus.statuses.open
    status: ""
  };

  handleSend = (formValues) => {
    if (this.props.position) {
      if (
        formValues.preferredCargo !== this.props.position.preferredCargo ||
        formValues.open.from !== this.props.position.open.from ||
        formValues.open.until !== this.props.position.open.until ||
        formValues.open.where !== this.props.position.open.name
      ) {
        formValues.where = formValues.open.where._id || this.props.position.open._id;
        formValues.name = formValues.open.where.name || formValues.open.where;
        formValues.from = toUTC(formValues.open.from);
        formValues.until = toUTC(formValues.open.until);
        delete formValues.open;

        this.props.handleSendEditPosition(formValues, this.props.position._id);
      } else {
        this.props.handleClose();
      }
    } else {
      !formValues.vessel && (formValues.vessel = this.props.vessel);
      formValues.open.from = toUTC(formValues.open.from);
      formValues.open.until = toUTC(formValues.open.until);
      const autocompleteItem = this.autocomplete?.getItem();
      if (autocompleteItem) {
        formValues.vessel = autocompleteItem;
      }

      this.props.handleSendNewPosition(formValues);
    }
  };

  handleOpenDateChange = (e, value) => {
    this.setState({ openDate: value });
  };
  handleCloseDateChange = (e, value) => {
    this.setState({ closeDate: value });
  };

  setStatus = val => {
    this.setState({
      status: val
    })
  }

  render() {
    const now = new Date();
    now.setDate(now.getDate());

    return (
      <Dialog
        contentStyle={dialogContentStyle}
        open
        bodyStyle={{overflowY: 'auto'}}
        handleClose={this.props.handleClose}
        titleStyle={
          {padding:"0 0 0 0"}
        }
        modal
      >
        <div className={s.add_position}>
          <div className={s.add_position_title} style={{ position: 'relative', zIndex: 10}}>
            {this.props.position ? 'EDIT POSITION' : 'ADD POSITION'}
          </div>

            <Formsy.Form noValidate onValidSubmit={this.handleSend} style={{position: 'relative', zIndex: 0}} ref="form">
              <div className={s.add_position_container}>
                <div className="row">
                  {!this.props.vessel && !this.props.position ?
                    <div className={cx("col-12", s.vessel_autocomplete_wrapper )}>
                      <div style={{zIndex: 999, position: 'relative'}}>
                        <WithFindContextual
                          url="/vessels/findContextual"
                          render={(data, load) => <CustomAutocomplete
                            adjustRef={instance => this.autocomplete = instance}
                            list={data} load={load} />
                          }
                        />
                      </div>

                      <Link to={this.props.createLink} className={s.add_ship_link} tabIndex="3" onClick={this.props.handleClose}>Add Vessel</Link>
                    </div>
                    : null}
                </div>
                  <div className="row" style={{ paddingTop: '26px' }}>
                  <div className="col-8" >
                    <NarrowPortAutocomplete
                      tabIndex="3"
                      floatingLabelText="Enter port or region"
                      name="open[where]"
                      requiredError="required"
                      validationErrors={{ isDefaultRequiredValue: 'required' }}
                      forceSelection
                      fullWidth
                      params={{ withAreas: true }}
                      required
                    />
                  </div>
                  <div className="col-4" style={{display: 'flex'}}>
                    <Maintenance />
                  </div>
                  <div className="col-12 col-md-4">
                    <NarrowFormsyDate
                      tabIndex="3"
                      fullWidth
                      name="open[from]"
                      container="inline"
                      floatingLabelText="Dates from"
                      requiredError="required"
                      onChange={this.handleOpenDateChange}
                      autoOk
                      minDate={now}
                      required
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <NarrowFormsyDate
                      tabIndex="3"
                      fullWidth
                      name="open[until]"
                      container="inline"
                      floatingLabelText="to"
                      requiredError="required"
                      onChange={this.handleCloseDateChange}
                      autoOk
                      startDate={this.state.openDate}
                      minDate={this.state.openDate || now}
                      required
                    />
                  </div>
                  <div className="col-12 col-md-4" style={{display: 'flex'}}>
                    <ChannelStatus floatingLabelText="Select status" select handleChange={this.setStatus} value={this.state.status}></ChannelStatus>
                  </div>
                  <div className="col-12">
                    <NarrowFormsyText
                      tabIndex="3"
                      className={s.preferred_cargo}
                      floatingLabelText="Preferences (optional)"
                      name={'preferredCargo'}
                      fullWidth
                    />
                  </div>
                </div>
              </div>
              <div className={s.add_position_buttons}>
                <RaisedButton
                  tabIndex="3"
                  className={s.rounded_btn}
                  label={'CANCEL'}
                  secondary
                  onTouchTap={this.props.handleClose}
                />
                <RaisedButton
                  tabIndex="3"
                  className={s.rounded_btn}
                  label={this.props.position ? 'EDIT' : 'ADD'}
                  type="submit"
                  primary
                />
              </div>
            </Formsy.Form>
        </div>
      </Dialog>
    );
  }
}

export default connect(
  state => (state.vessel),
  { getVesselsList })(withStyles(s)(PositionDialog));
