import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _Dialog from 'material-ui/Dialog';
import NavigationClose from 'material-ui/svg-icons/navigation/close';

const dialogStyles = {
  style: {},
  bodyStyle: {},
  contentStyle: {},
  overlayStyle: {},
  titleStyle: {
    borderBottom: 'none',
    overflow: 'hidden',
    padding: '32px 40px 16px 40px',
    fontSize: '18px',
    fontWeight:'bold',
    textAlign: 'center',
  },
};
const closeIconStyle = {
  position: 'absolute',
  cursor: 'pointer',
  top: '12px',
  right: '12px',
};
class Dialog extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
    const iconStyle = { ...closeIconStyle, ...(props.closeIconStyle || {}) };
    this.title = (
      <div >
        {props.title || ''}
        <NavigationClose style={iconStyle} onClick={props.handleClose} />
      </div>
    );
  }

  async componentWillReceiveProps(nextProps) {
    if(nextProps.eddie) {
      if(this.props.open !== nextProps.open){
        if(nextProps.open) {
          await this.openInEddie()
        } else {
          this.eddieCloser && this.eddieCloser();
          this.eddieCloser = null;
        }
      }
    }
  }

  async componentWillMount() {
    if(this.props.open && this.props.eddie) {
      await this.openInEddie();
    }
  }


  async openInEddie(props = this.props) {
    this.eddieCloser = await props.eddie.open('right','',undefined,({handleClose}) => {
      return <div>
        <div className={"portal_title portal_title_grey"}>{this.props.header}</div>
        {this.props.children}
      </div>
    });
  }

  componentWillUnmount(): void {
    if(this.eddieCloser) {
      this.eddieCloser();
      this.eddieCloser = null;
    }
  }


  render() {
    const { header, open, title, handleClose,eddie, ...rest } = this.props;
    if(eddie) {
      return null;
    }
    return (
      <_Dialog
        titleClassName={"dialog_header_title" || this.props.titleClassName}
        title={header || this.title}
        modal={this.props.modal}
        open={this.props.open || this.state.open}
        onRequestClose={this.props.handleClose}
        autoScrollBodyContent={this.props.autoScrollBodyContent}
        {...dialogStyles}
        {...rest}
        actionsContainerStyle={{borderTop: 0, paddingRight:'40px', paddingBottom:'32px' }}

      >
        {this.props.children}
      </_Dialog>
    );
  }
}

Dialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  autoScrollBodyContent: PropTypes.bool,
};
// add default props for title ('')
Dialog.defaultProps = { autoScrollBodyContent: true };

export default Dialog;
