import PropTypes from 'prop-types';
import React, { Component } from 'react';
import s from './PortLink.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { PortInfo } from '../Public/PublicPort';
import { availableFacilities } from '../Port/settings';
import DialogHalfScreen from './DialogHalfScreen';
import { connect } from 'react-redux';
import { setPortDialogId } from '../../actions/port';
import { v4 as uuid } from 'uuid';

class PortLink extends Component {

  static propTypes = {
    /** Showed link edit port. */
    isEditPort: PropTypes.boolean,
  };
  static contextTypes = {
    addToRightPart: PropTypes.func,
    removeFromRightPart: PropTypes.func,
    right: PropTypes.array,
    showMessage: PropTypes.func,
  }

  static defaultProps = {
    isEditPort: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      portUid: null,
    };
  }
  _handleOpenPort = () => {
    const portUid = uuid();
    this.setState({ portUid });
      this.props.setPortDialogId({ id: this.props.port?._id, portUid });
  }
  _handleCloseDialog = () => {
    this.setState({ portUid: null });
    if (this.props.shipId) {
      this.context.removeFromRightPart(this.props.shipId);
    }
    if (this.props.closeRightDialogs) {
      this.props.closeRightDialogs();
    }
    this.props.setPortDialogId({ id: null, portUid: null });
  };
  handleCapitalize = (str) => {
    if (!str) return str;
    if (str.charAt(0).toUpperCase() === str.charAt(0)) return str;
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };
  render() {
    return (
      <span className={this.props.className ? this.props.className : null}>
        { this.props.port._id ?
            <a onClick={this._handleOpenPort}>{ this.handleCapitalize(this.props.port.name) }</a>
            :
            <span>{ this.handleCapitalize(this.props.port.name) }</span>
        }
        { this.props.dialogPortId === this.props.port?._id && this.props.uid === this.state.portUid
          ?
          <DialogHalfScreen open>
            <PortInfo portId={this.props.dialogPortId} editEnabled={false} user={this.props.user} searchEnabled={false} handleClose={this._handleCloseDialog} showMessage={this.context.showMessage} />
          </DialogHalfScreen>
        : null
        }
      </span>
    );
  }
}
const mapStateToProps = (state) => ({
  dialogPortId: state.port.dialogPortId,
  uid: state.port.uid,
}
);
const mapDispatchToProps = (dispatch) => (
{
  setPortDialogId: (ids) => dispatch(setPortDialogId(ids)),
}
);
export default connect(mapStateToProps, mapDispatchToProps
)(withStyles(s)(PortLink));

function ServiceProvider({ provider }) {
  return (
    <div className={s.input_service}>
      <p>
        <span>{provider.activity}</span>
      </p>
      <p>
        <span>{provider.name}</span>
      </p>
      <p>
        {provider.emails.concat(provider.phones.map(p => p.code + p.phone)).join(', ')}
        <div>{provider.address}</div>
        <div>{provider.PIC}</div>
      </p>
    </div>
  );
}
