import React, { Component } from 'react'
import NarrowCountrySelect from './../../NewInputs/NarrowCountrySelect';
import cx from 'classnames';
import tc from './TC.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import CustomCheckbox from './../../Common/CustomCheckbox';

import ContentAdd from 'material-ui/svg-icons/content/add';
import Chip from 'material-ui/Chip';
export class CountriesExclusions extends Component {

  constructor(props) {
    super(props);
    this.state = {
      excluded: false,
      flag: ""
    }
  }

  componentDidMount() {
    if (this.props.attachRef) {
      this.props.attachRef(this);
    }
  }

  handleChange = (key, ev, val) => {
    this.setState({
      [key]: val
    })
  }

  addCountry = () => {
    const {flag, excluded} = this.state;
    if (!flag) return;
    this.props.handleChange('flags', null, [
      ...(this.props.flags || []),
      {
        flag,
        excluded
      }
    ])
    this.handleChange('flag', null, "");
  }

  delete = id => {
    const {flags} = this.props;

    const newCountries = flags.filter(c => c.flag._id !== id);

    this.props.handleChange('flags', null, newCountries);
  }

  countrySelector = flag => flag

  render() {

    const { excluded, flag } = this.state;
    const { flags, prefix } = this.props;

    return (
      <div className={tc.col}>
          <div className={cx(tc.row, tc.flag_row)}>
            <div>
              <NarrowCountrySelect
                  hintText="Flag"
                  floatingLabelText="Flag"
                  name={`${prefix}[flag]`}
                  fullWidth
                  value={flag}
                  onChange={this.handleChange.bind(this, 'flag')}
                  maxHeight={271}
                  countrySelector={this.countrySelector}
                  valueSelector={this.countrySelector}
              />
              </div>
            <div>
              <CustomCheckbox className={cx(tc.checkbox, tc.col_cb)} checked={excluded} name={`${prefix}[excluded]`} label="Excluded" onCheck={this.handleChange.bind(this, 'excluded')} />
              <div className={cx(tc.add_btn, tc.col_add_btn)} onClick={this.addCountry}>
                <ContentAdd
                  color="#285596"
                  style={{ padding: '2px' }}
                />
              </div>
            </div>
          </div>
          <div className={tc.row_start_wrap} style={{marginTop: 8}}>
            {
              flags?.length
              ? flags.map(country => (<Chip key={country.flag._id} onRequestDelete={this.delete.bind(this, country.flag._id)} backgroundColor={country.excluded ? '#E6E6E6' : '#D6EDBD'} style={{margin: '8px 8px 0px 0px'}} className={tc.chip}>{`${country.excluded ? '- ' : ''}${country.flag.name}`}</Chip>))
              : null
            }
          </div>
      </div>
    )
  }
}

export default withStyles(tc)(CountriesExclusions);
