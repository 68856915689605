import * as constants from '../constants/contracts';
import ContractApi from '../core/api/Contract';

export function updateContractDetails(contractId, mergeValues) {
  return {
    type: constants.UPDATE_CONTRACT_DETAILS,
    payload: {
      contractId,
      mergeValues,
    },
  };
}

export function getFixedContracts(token) {
  return {
    type: constants.CONTRACT_LIST,
    promise: ContractApi.getFixedContracts(token),
  };
}

export function updateOnlyContractPDF(contractId, contractLinkToPDF) {
  return {
    type: constants.UPDATE_CONTRACT_PDF,
    payload: {
      contractId,
      contractLinkToPDF
    }
  }
}

export function awaitForPDFUpdate() {
  return (dispatch, getState) => {
    setTimeout(() => {
      // if event wasn't received, stop awaiting, hide loader and show older PDF
      const state = getState();
      if (!state.contracts.isAwaitingPDFUpdate) return;
      dispatch({
        type: constants.AWAIT_PDF_UPDATE,
        payload: false,
      });
    }, 5000);
    // await for PDF (show loader) until WebSocket event "offlineContracts:contractIsUpdated" appears

    return dispatch({
      type: constants.AWAIT_PDF_UPDATE,
      payload: true,
    });
  };
}

export function getVoyageContractById(_id, type, token) {
  return {
    type: constants.CONTRACT_GET_CONTRACT,
    payload: _id,
    promise: ContractApi.getVoyageContractById(_id, type, token),
  };
}

export function clearSelectedContract() {
  return {
    type: constants.CONTRACT_GET_CONTRACT,
    res: { data: null },
  };
}
export function filterContracts(filters) {
  return {
    type: constants.CONTRACT_FILTER,
    payload: filters,
  };
}

export function fix({ contractId, type }, token) {
  return {
    type: constants.CONTRACT_FIX,
    payload: { contractId, type },
    promise: ContractApi.fix({ contractId, type }, token),
    successMessage: 'Contract fixed',
  };
}

export function reject({ contractId, type }, token) {
  return {
    type: constants.CONTRACT_REJECT,
    payload: { contractId, type },
    promise: ContractApi.reject({ contractId, type }, token),
    successMessage: 'Contract rejected',
  };
}
export function cancel({ contractId, type }, token) {
  return {
    type: constants.CONTRACT_CANCEL,
    payload: { contractId, type },
    promise: ContractApi.cancel({ contractId, type }, token),
    successMessage: 'Contract canceled',
  };
}
export function extendTime({ contractId, time, type }, token) {
  return {
    type: constants.CONTRACT_EXTEND_TIME,
    payload: { contractId, time, type },
    promise: ContractApi.extendTime({ contractId, time, type }, token),
    successMessage: 'Time extended',
  };
}
export function deleteCargo({ contractId, cargoRequestId }, token) {
  return {
    type: constants.CONTRACT_DELETE_CARGO,
    payload: { contractId, cargoRequestId },
    promise: ContractApi.deleteCargo({ contractId, cargoRequestId }, token),
    successMessage: 'Not implemented yet', //TODO implement
  };
}
export function reopenCargo({ contractId, cargoRequestId }, token) {
  return {
    type: constants.CONTRACT_REOPEN_CARGO,
    payload: { contractId, cargoRequestId },
    promise: ContractApi.reopenCargo({ contractId, cargoRequestId }, token),
    successMessage: 'Request reopened',
  };
}
export function deletePos({ contractId, posId, type }, token) {
  return {
    type: constants.CONTRACT_DELETE_POS,
    payload: { contractId, posId, type },
    promise: ContractApi.deletePos({ contractId, posId, type }, token),
    successMessage: 'Not implemented yet', //TODO implement
  };
}
export function reopenPos({ contractId, posId, type }, token) {
  return {
    type: constants.CONTRACT_REOPEN_POS,
    payload: { contractId, posId },
    promise: ContractApi.reopenPos({ contractId, posId, type }, token),
    successMessage: 'Position reopened',
  };
}
