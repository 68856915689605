import { removeFromArray, replaceInArray } from '../../core/utils';

// noinspection JSUnusedGlobalSymbols
/** @name PROFILE_UPSERT_ACCOUNT_REDUCER */
export default function PROFILE_UPSERT_ACCOUNT(state, action) {
  const account = action.payload;
  if (!account._id) {
    account._id = action.res.data._id;
  }
  const newState = { user: { ...state.user } };
  if (!newState.user.company.accounts) {
    newState.user.company.accounts = [];
  }

  if (account.default) {
    newState.user.company.accounts =
      newState.user.company.accounts.map(a => ({ ...a, default: false }));
  }
  const index = newState.user.company.accounts.findIndex(a => a._id === account._id);

  if (index === -1) {
    newState.user.company.accounts = [...newState.user.company.accounts, account];
  } else {
    newState.user.company.accounts = replaceInArray(newState.user.company.accounts, index, account);
  }
  return { ...state, ...newState };
}
