import React, { Component } from 'react';
import ChatMailIcon from '../Icons/ChatMailIcon';
import EmptyList from '../Common/EmptyList';

export default function MailsNotConnected(){
  return (
  <EmptyList Icon={ChatMailIcon} title='No e-mails yet'
  description={[`To use this Service, please connect your mail.
  Shipnext is a Microsoft Partner solution, and provides an email service that can easily pair with Outlook, Gmail, Sedna and other email services.`,
  `To set up E-mail tagging, please go to Settings/Tags. This will help you and your team use Shipnext algorithm and AI to automate tagging.`
]}/>
)
}