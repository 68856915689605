import { removeFromArray, replaceInArray } from '../../core/utils';

/** @name PROFILE_DELETE_ACCOUNT_REDUCER */
export default function PROFILE_DELETE_ACCOUNT(state, action) {
  const account = action.payload;
  const newState = { user: { ...state.user } };
  const index = newState.user.company.accounts.findIndex(a => a._id === account._id);

  if (index === -1) {
    return state;
  } else {
    newState.user.company.accounts = removeFromArray(newState.user.company.accounts, index);
  }
  return { ...state, ...newState };
}
