import * as constants from '../constants/bl';
import blApi from '../core/api/BL';

export function getBl(_id, token) {
  return {
    type: constants.BL_GET,
    payload: _id,
    promise: blApi.get(_id, token),
  };
}

function refreshBl(_id) {
  return function (res) {
    return function (dispatch, getState) { dispatch(getBl(_id)); };
  };
}
export function createBl(data) {
  return {
    type: constants.BL_CREATE,
    payload: data,
    promise: blApi.create(data),
  };
}
export function invite(data) {
  return {
    type: constants.BL_INVITE,
    payload: data,
    promise: blApi.inviteParticipant(data),
    afterQueue: [refreshBl(data.blId)],
    afterFail: [refreshBl(data.blId)],
  };
}
export function confirmParticipation(bl, role) {
  return {
    type: constants.BL_CONFIRM_PARTICIPATION,
    payload: bl,
    promise: blApi.confirmParticipation(bl, role),
    afterQueue: [refreshBl(bl._id)],
    afterFail: [refreshBl(bl._id)],
  };
}
export function confirmParticipants(bl, role) {
  return {
    type: constants.BL_CONFIRM_PARTICIPANTS,
    payload: bl,
    promise: blApi.confirmParticipants(bl),
    afterQueue: [refreshBl(bl._id)],
    afterFail: [refreshBl(bl._id)],
  };
}
export function rejectParticipation(bl, role) {
  return {
    type: constants.BL_REJECT_PARTICIPATION,
    payload: bl,
    promise: blApi.rejectParticipation(bl, role),
    afterQueue: [refreshBl(bl._id)],
    afterFail: [refreshBl(bl._id)],
  };
}
export function remind(data) {
  return {
    type: constants.BL_REMIND,
    payload: data,
    promise: blApi.remindParticipant(data),
    afterQueue: [refreshBl(data.blId)],
    afterFail: [refreshBl(data.blId)],
  };
}
export function sign(data) {
  return {
    type: constants.BL_SIGN,
    payload: data,
    promise: blApi.sign(data),
    afterQueue: [refreshBl(data.blId)],
    afterFail: [refreshBl(data.blId)],
  };
}
export function amend(data) {
  return {
    type: constants.BL_AMEND,
    payload: data,
    promise: blApi.claim(data),
    afterQueue: [refreshBl(data.blId)],
    afterFail: [refreshBl(data.blId)],
  };
}

export function getList(data) {
  return {
    type: constants.BL_GET_LIST,
    payload: data,
    promise: blApi.getBlList(data),
  };
}

export function changeField(data) {
  return {
    type: constants.BL_CHANGE_FIELD,
    payload: data,
    promise: blApi.changeField(data),
    afterQueue: [refreshBl(data.blId)],
    afterFail: [refreshBl(data.blId)],
  };
}
export function issue(data) {
  return {
    type: constants.BL_ISSUE,
    payload: data,
    promise: blApi.issue(data),
    afterQueue: [refreshBl(data.blId)],
    afterFail: [refreshBl(data.blId)],
  };
}

export function entrust(data) {
  return {
    type: constants.BL_ENTRUST,
    payload: data,
    promise: blApi.entrust(data),
    afterQueue: [refreshBl(data.blId)],
    afterFail: [refreshBl(data.blId)],
  };
}

export function remindApproval(data) {
  return {
    type: constants.BL_REMIND_APPROVAL,
    payload: data,
    promise: blApi.remindApproval(data),
    afterQueue: [refreshBl(data.blId)],
    afterFail: [refreshBl(data.blId)],
  };
}
