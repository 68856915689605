/** @name GET_INDEXES_LIST_SUCCESS */
export default function GET_INDEXES_LIST_SUCCESS(state, action) {
  const newState = {};

  newState.balticIndex = {
    ...state.balticIndex,
    loading: false,
    indexesList: action.payload.data,
    errorMessage: "",
  };

  return { ...state, ...newState };
}
