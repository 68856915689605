import * as handlers from './login/index';

export default function login(
  state = { user: null,
    loading: false,
    afterQueue: [],
    counters: {
      cargoBids: 0,
      cargoTcBids: 0,
      vesselBids: 0,
      vesselTcBids: 0,
      contractBids: 0,
    },
    limitations: {},
    statistics: {
      data: [],
      page: 1,
      total: 0,
      numberOfRows: 15,
      loading: true,
      errors: []
    },
    autoTags: { cargoFilter: [], vesselFilter: [] },
    tagCategoriesByName: {},
    membersTags: {},
    lastFilters: {},
    globalComposeDialog: false,
  },
  action,
) {

  if (!handlers[action.type]) {
    return state;
  }
  try {

    return handlers[action.type](state, action) || state;
  } catch (e) {
    console.error(e);
    try {
      if (typeof window !== 'undefined' && typeof window.Raven !== 'undefined') {
        window.Raven.captureException(e);
      }
    } catch (e2) {
      console.error(e2);
    }
    return state;
  }
}
