// @flow
import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { HOC } from 'formsy-react';
import s from './FormsyAttachments.scss';
import EditorAttachFile from 'material-ui/svg-icons/editor/attach-file';
import cx from 'classnames';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import { uploadFile } from '../../../actions/cargo';
import UploadedFileExtensionErr from './../UploadedFileExtensionErr';
import { WithUserUploads, withUserUploads } from '../../../core/HOC';
import {getAttachmentAProps, round} from "../../../core/utils";
import {LinearProgress} from "material-ui";
import styled from "styled-components";
type Props = {
  getValue: any,
  setValue: any,
  accept: string,
  maxSizeMb: number,
  maxTotalSizeMb: number,
  privateAttachment: boolean,
  oldFilesLocked: boolean,
  buttonPosition: 'left' | 'right';
};
type State = {
  erroredFiles: File[],
};

class FormsyAttachments extends Component<Props, State> {

  static defaultProps = {
    accept: ".xls, .xlsx, .txt, .csv, .pdf, .jpg, .jpeg, .png, .doc, .docx, .rtf, .tiff, .dwg, .dxf, .vsd, .xlsm, .zip, .rar, .7z",
    maxSizeMb: 25,
    buttonPosition: 'left',
    maxTotalSizeMb: 25,
    oldFilesLocked: false,
    onChange: () => {},
  };

  input = null;
  constructor(props) {
    super(props);
    this.state = { erroredFiles: [], maxTotalSizeMb: props.maxTotalSizeMb };
    this.props.setValidations({ attachmentsMaxSize: this.validateMaxSize });
  }

  handleAttachFiles = async (formData, size) => {
    this.setState({ uploading: true });
    try {
      const res = await uploadFile(formData, { privateAttachment: this.props.privateAttachment });
      const files = [...(this.getValue() || []), { ...res.data, size, deletable: true }];
      this.props.setValue(files);
      this.props.onChange(null, files);
      if (this.input) {
        this.input.type = '';
        this.input.type = 'file';
      }
    } finally {
      this.setState({ uploading: false });
    }
  };


  componentDidMount() {
    this.props.setValidations({ attachmentsMaxSize: this.validateMaxSize });
    if (this.props.files) {
      this.setValue(this.props.files);
    }
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.files !== this.props.files) {
      this.setValue(nextProps.files);
    }
    this.props.setValidations({ attachmentsMaxSize: this.validateMaxSize });
  }


  handleRemoveFile = (i) => {
    const files = [...this.getValue()];
    files.splice(i,1);
    this.props.setValue(files);
    this.props.onChange(null, files);
  };
  handleInputClick = () => {
    this.setState({erroredFiles:[]});
  }

  refInput = (input)=> {
    this.input = input;
  };

  getValue = () => {
   return this.props.getValue() || [];
  };
  setValue = (val) => {
    this.props.setValue(val);
  }
  validateMaxSize = (formValues, value) => {
    if (!value) {
      return true;
    }
    const totalSizeMb = value.reduce((acc, file) => acc + (file.size || 0) , 0) / (1024 * 1024);
    this.state.remainingSize = Math.max(round(this.props.maxTotalSizeMb - totalSizeMb, 2), 0);
    return totalSizeMb < this.props.maxTotalSizeMb;
  }

  render() {
    const files = this.getValue();
    const { className,maxSizeMb, ...rest } = this.props;
    const remainingSize = this.state.remainingSize;
    return (
      <WithUserUploads accept={this.props.accept} maxSizeMb={maxSizeMb} files={files} onSuccess={this.handleAttachFiles} privateAttachment={this.props.privateAttachment}>
        {
          ({
            erroredFiles,
            validateUserUploads,
          }) => (
              <div className={cx(s.attachments, className)} {...rest}>
                <div className={cx(s.attach_file_wrapper, s[this.props.buttonPosition])}>
                <label htmlFor="attachments" title={`Attach up to ${maxSizeMb} mb`} className={cx(s.attach_file, (remainingSize <= 0 && s.disabled) )}>
                  <EditorAttachFile className={s.attach_icon} />
                  Attach file
                  <input
                    accept={this.props.accept}
                    id="attachments"
                    type="file"
                    onChange={validateUserUploads}
                    onClick={this.handleInputClick}
                    multiple
                    ref={this.refInput}
                  />
                </label>
                </div>
                <div>
                  {erroredFiles?.length || !this.props.isValid() ?
                    <UploadedFileExtensionErr erroredFiles={erroredFiles} accept={this.props.accept} maxSizeMb={this.props.maxSizeMb} remainingSize={remainingSize} maxTotalSizeMb={this.props.maxTotalSizeMb}   />
                    : null}
                  <Attachments files={files} handleRemoveFile={this.handleRemoveFile} oldFilesLocked={this.props.oldFilesLocked} />
                  {this.state.uploading ? <LinearProgress /> : null}
                </div>
              </div>
          )
        }
      </WithUserUploads>
    )
  }
}

export default withStyles(s)(HOC(FormsyAttachments))

export const Attachments = styled(({ files, className, handleRemoveFile, oldFilesLocked, ...props }) => {
  return <div className={className}>
    {files.map((file,i)=> {
      return <div className={'part_attached_file'}>
        <EditorAttachFile className={cx('attach_icon', 'attach_icon_second')} />
        {file.url ? <a {...getAttachmentAProps(file)} target="_blank" title={file.name}><p>{file.name}</p></a> : <p>{file.name}</p>}
        {file.deletable || !oldFilesLocked ?  <CloseIcon onClick={handleRemoveFile.bind(this, i)} className={'btn_close'} /> : null }
      </div>
    })}
  </div>
})`
  .part_attached_file {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 4px;
    padding: 8px 24px 8px 12px;
    background-color: var(--bg-light-grey);
    p {
      margin: 0;
      padding: 0;
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
      color: var(--text-links);
    }
    .btn_close {
      position: absolute;
      cursor: pointer;
      right: 8px;
      height: 20px!important;
      width: 20px!important;
    }
    .attach_icon {
      fill: var(--text-links) !important;
      margin-right: 8px;
      transform: rotate(45deg);
      width: 20px !important;
      height: 20px !important;
    }
    .attach_icon_second {
      fill: var(--text-medium) !important;
      margin-right: 8px;
    }
  }
`
