import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Pane } from '../Common/SplitPane';
import ArrowPopover from '../Common/ArrowPopover';
import { RowWrapper, SearchField, Text } from '../Contracts/shared/styled';
import Sort from '../Common/Sort';
import ThemeToggle from '../Common/ThemeToggle';
import styled, { consolidateStreamedStyles, css, ThemeProvider } from 'styled-components';
import s from '../Monitor/TableNew.scss';
import Col from '../Monitor/RowBlocks/Col';
import VesselSelector from '../Monitor/VesselSelector';
import { connect } from 'react-redux';
import { getEmailList, checkEmail, checkAll, toggleTheme, archiveEmails, markEmailAs, changeEmailTags } from '../../actions/emails';
import CustomCheckbox from '../Common/CustomCheckbox';
import {
  dateStringOrTime,
  dateTime,
  dateTimeLocal,
  dateTimeShiftedToLocal,
  elapsedHoursFromMillis
} from '../../core/format';
import VirtualList from '../Common/VirtualList';
import { Icon } from '../Common/StyledComponents';
import {getMessageTypeIcon, PrivateIcon, SharedIcon, StarredIcon} from './Icons';
import EmailsTypeFilter from './EmailsTypeFilter';
import NavigationPopover from '../Common/NavigationPopover';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import icon_archive_in from '../Icons/icon_archive_in.svg';
import icon_archive_out from '../Icons/icon_archive_out.svg';
import MailsNotConnected from './MailsNotConnected';
import WithTooltip from '../Common/WithTooltip';
import { getProfile, getAccountFilters } from '../../actions/login';
import { debounce, debounceWithoutFirstCall } from '../../core/utils';
import Socket from '../Socket';
import history from '../../core/history';
import EmailDetails from './EmailDetails';
import DialogHalfScreen from '../Common/DialogHalfScreen';
import CustomTooltip from '../Common/CustomTooltip';
import ChipsAutocomplete from '../Common/Tags/ChipsAutocomplete';
import TagsView from '../Common/Tags/TagsView';
import ComposeEmailDialog from './ComposeEmailDialog';
import RaisedButton from '../Common/RaisedButton';
import InfoIcon from "material-ui/svg-icons/action/info";
import EmailsSharedFilter from './EmailsSharedFilter';
import RoundedDateRangeFilter from "../Common/RoundedDateRangeFilter";
import {isExchangeFiltersEmpty} from "./utils";
import TagsViewExchange from '../Common/Tags/TagsViewExchange';
import { v4 as uuid } from 'uuid';
import SaveFilterWrapper from '../Monitor/Filter/SaveFilterWrapper';
import ContactDetailsPopover from "../ContactBook/ContactDetailsPopover";
import FolderTypeSelect from '../Common/FolderTypeSelect';
import Loader from '../Common/Loader';
import ChatMailIcon from '../Icons/ChatMailIcon';
import EmptyList from '../Common/EmptyList';

type Props = {
  emailList: {
    data:any[],
    filters: { type: {}, tags: {} },
    folder: 'received' | 'archive' | 'sent',
    loading: boolean,
    next: number,
    cursor: string,
    sort: { field: string, value: -1 | 1 },
    dark: boolean,
    filtersPerFolder: {[string]: { type: {}, tags: {} } },
  },
  getEmailList: () => Promise<void>,
  checkEmail: (id: string, checked: boolean) => void,
  checkAll: () => void,
  toggleTheme: (isDark: boolean) => void,
  folder: string,
  emailId: string,
  archiveEmails: (ids: string[], archive: boolean) => void,
  action: string,
  markEmailAs: (ids: string[], { seen: boolean }) => Promise<*>,
  composeProps: { to: string[] },
  changeEmailTags: () => void,
}
type State = {
  checked:any[],
}
const sortFields = {
  added_date: {
    field: "added_date",
    value: -1,
    label: "Added Date",
  },
  dwt: {
    field: "dwt",
    value: 1,
    label: "DWT",
  },
  capacity: {
    field: "capacity",
    value: 1,
    label: "Capacity",
  },
};
const defaultScopes = [
  {
      "type": "cargo",
      "processed": true,
  },
  {
      "type": "vessel",
      "processed": true,
  },
  {
      "type": "sales",
      "processed": true,
  },
  {
      "type": "tc",
      "processed": true,
  },
  {
      "type": "cargo",
      "processed": false,
  },
  {
      "type": "vessel",
      "processed": false,
  },
  {
      "type": "sales",
      "processed": false,
  },
  {
      "type": "tc",
      "processed": false,
  },
  {
      "type": "spam",
      "processed": false,
  },
];

export const StyledRowWrapper = styled(RowWrapper)`
  .${s.ref_checkbox}.custom-checkbox {
    margin-right: 0px;
     width: 16px !important;
     height: 16px !important;
     input {
      width: 16px !important;
      height: 16px !important;
    }
    svg {
      margin-top: 1px;
      margin-left: 1px;
      width: 14px !important;
      height: 14px !important;
    }
    margin-top: -2px !important;
    margin-bottom: 1px !important;

  }
  .${s.text} {
    justify-content: space-between;
    padding: 10px 8px 7px 0px;
    color: var(--default-dark-text);
    ${props => props.header && css`
        font-weight: 400;
        color: var(--text-medium);
      `
}
  }
  > div:first-child {
    width: 16px;
    min-width: 16px;
    margin-right: 9px;
    overflow: unset;
    padding: 10px 0px 9px 0px;
  }
  > div:nth-child(2) {
    flex: 1;
    min-width: 90px;
  }
  > div:nth-child(3){
    flex:3;
    >div:first-child {
      display: flex;
      >div {
        width: 50%;
      }
      >div:first-child {
        padding-right: 8px;
        max-width: 50%;
        width: auto;
      }
      justify-content: space-between;
    }
  }

  > div:nth-child(4) {
    width: 78px;
    min-width: 78px;
  }
  .tooltip-default {
    white-space: pre-line;
    max-height: 300px;
    max-width: 478px;
    overflow: auto;
  }
  .reply_counter {
    display: inline-flex;
    align-items: center;
    .material-icons, .material-icons-outlined {
      font-size: 12px;
      color: var(--text-links);
    }
  }
  .attach-icon {
    font-size: 16px;
    transform: rotate(45deg);
    color: var(--text-dark333);
  }

  &.unread{
    background-color: var(--bg-gark-grey);
    ${props => props.isDark && css`
      background-color: var(--bg-table-dark-hover);
    `}
    .reply_counter {
      font-weight: bold;
    }
    > div:nth-child(3){
      >div {
        font-weight: bold;
        >div:first-child {
         font-weight: bold;
          >div {
            font-weight: bold;
          }
        }
      }
    }
  }
  &.preselected {
    background-color: #F6FFFF;
    ${props => props.isDark && css`
      background-color: #3C4B55;
    `
    }
  }
  &.active {
    background-color: #F1F9FF;
    ${props => props.isDark && css`
      background-color: var(--bg-table-dark-hover);
    `
    }

  }
  .tooltip_position {
    right: -13px !important;
  }
`;
const StyledTable = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  flex-direction: column;
  .col-to, .col-from {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .col-to {
    display: none;
  }
  &.sent {
    .col-to {
      display: block;
    }
    .col-from {
      display: none;
    }
  }
  .multi_email{
    display: flex;
    >div:nth-child(1) {
      overflow: hidden;
      text-overflow: ellipsis;
    }
    >div:nth-child(2) {
      min-width: min-content;
    }
    .tooltip-default {
      font-size: 11px;
      line-height: 18px;
      a {
        color: var(--text-links-light);
        text-decoration: underline;
        font-weight: 400;
      }
    }
  }
  .favorite-icon {
    min-width: 12px;
  }
`

const TableHeader = styled(({ className, isDark, checkAll, rows, ...props }) => {
  return (
  <div className={className}>
    <StyledRowWrapper noHover isDark={isDark} h="48px" header>
      <Col>
        <VesselSelector style={{ maxWidth: '26px', marginTop: '-4px', overflow: 'unset' }} vertical refList={[]} isDark={isDark} handleCheck={checkAll} vessels={rows} />
      </Col>
      <Col>
        <Text className={"col-from"}>
          FROM
        </Text>
        <Text className={"col-to"}>
          TO
        </Text>
        <Text>
          REF
        </Text>
      </Col>
      <Col>
        <div>
          <Text>
            SUBJECT
          </Text>
          <Text>
            TAGS
          </Text>
        </div>
        <div>
          <Text>
            MESSAGE
          </Text>
        </div>
      </Col>
      <Col style={{ overflow: "visible" }} right>
        <Text textAlign="right">
          ADDED
        </Text>
        <Text style={{ overflow: "visible", display: "flex" }} textAlign="right">
          <span>ACCESS</span>
          <CustomTooltip
            tooltip={
              <div style={{ padding: "4px 0" }}>
                <div style={{ lineHeight: "20px", display: "flex", alignItems: "center", height: "24px" }}>
                  <SharedIcon color="#D2D2D2"/>
                  <span style={{ marginLeft: "8px" }}>Shared Access</span>
                </div>
                <div style={{ lineHeight: "20px", display: "flex", alignItems: "center", paddingLeft: "2px", height: "24px" }}>
                  <PrivateIcon width={11} height={11} color="#D2D2D2"/>
                  <span style={{ marginLeft: "10px" }}>Private Access</span>
                </div>
              </div>}
            horizontalAlign="left"
            className="tooltip_position"
          >
          <InfoIcon
            style={{
              width: "15px",
              height: "15px",
              color: "var(--profile-grey-label)",
              marginLeft: "6px",
            }}
          />
          </CustomTooltip>
        </Text>
      </Col>
    </StyledRowWrapper>
  </div>);
})`
  background: var(--bg-light-grey);
`


const altHeaderProps = {
  hoverableProps: {
    style: {
      marginRight: 8,
      pointerEvents: 'initial',
    },
  },
  style: {
    padding: '0px 0px 0px 9px',
    minWidth: '100%',
    minHeight: '48px',
  },
};

function isInViewport(element) {
  const rect = element.getBoundingClientRect();
  return (
      rect.top >= 144 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

class EmailsTable extends Component<Props, State> {
  static contextTypes = {
    showMessage: PropTypes.func,
  };
  state = {
    checked: [],
    preSelectedId: '',
    monitorEmailsFolder: 'MAIL',
  }
  handleLoadMore = () => {
    if (this.nextPageLoading) {
      return;
    }
    this._loadMore();
  }

  _loadMore = debounce(async () => {
    if (this.nextPageLoading) {
      return;
    }
    if (this.props.emailList.cursor) {
      this.nextPageLoading = true;
      if (this.props.isMonitor) {
        await this.props.getEmailList({ folder: this.props.folder, filters: this.props.emailList.filters, cursor: this.props.emailList.cursor, cargoId: this.props.cargoId, vesselId: this.props.vesselId, monitorEmailsFolder: this.state.monitorEmailsFolder });
      } else {
        await this.props.getEmailList({ folder: this.props.folder, filters: this.props.emailList.filters, cursor: this.props.emailList.cursor });
      }
      
      setTimeout(() => { this.nextPageLoading = false; },500);
    }
  },100)

  componentWillMount() {
    if(typeof window !== 'undefined') {
      this.props.getProfile();
    }
  }


  async componentDidMount() {
    this.keyHandler = debounceWithoutFirstCall(this.keyListen, 0);
    document.body.addEventListener('keydown', this.keyHandler);
    const mailTable = document.getElementById('mail_list_table');
    mailTable.focus();
    this.setPreselectedId();
    let filters = this.props.isMonitor ? {} : { ...this.props.emailList.filtersPerFolder[this.props.folder] };
    if (this.props.action === 'filter') {
      const filterProps = this.props.filterProps;
      if (filterProps.imo) {
        filters = {
          tags: {
            condition: [
              {
                anyOf: [
                  {
                    value: filterProps.imo,
                    negate: false,
                    categoryName: "Vessel",
                    category: this.props.tagCategoriesByName?.vessel,
                  },
                ],
              },
            ],
          },
        };
      }
      history.push(`/$1/$2/$3/-/-/---`, undefined, true);
    }
    if (this.props.action === 'compose') {
      const composeProps = this.props.composeProps;
      if (composeProps?.to) {
        filters = {
          tags: {
            condition: [
              {
                anyOf: [
                  {
                    search: composeProps.to.join(),
                    negate: false,
                  },
                ],
              },
            ],
          },
        };
      }
    }
    if (this.props.isContactBook) {
      const emailsForFilter = this.props.emailsForFilter;
      if (emailsForFilter.length) {
        filters = {
          tags: {
            condition: [
              {
                anyOf: emailsForFilter.map(email => ({
                  inAddressesOnly: true,
                  search: email,
                })),
              },
            ],
          },
          scopes: defaultScopes,
        };
      }
    }
    if (this.props.lastFilters && this.props.lastFilters[`${this.props.folder}Filter`] && !this.props.isMonitor) {
      await this.props.getEmailList({ folder: this.props.folder, filters: this.props.lastFilters[`${this.props.folder}Filter`] });
    } else {
      await this.props.getEmailList({ folder: this.props.folder, filters, cargoId: this.props.cargoId, vesselId: this.props.vesselId, monitorEmailsFolder: this.state.monitorEmailsFolder });
    }

    Socket.joinRoom('mailer:list');
    window.addEventListener('click', this.linkClickHandler, { capture: true, passive: false});
  }


  componentWillUnmount() {
    document.body.removeEventListener('keydown', this.keyHandler, false);
    Socket.leaveRoom('mailer:list');
    window.removeEventListener('click', this.linkClickHandler, { capture: true, passive: false });
  }

  async componentWillReceiveProps(nextProps) {
    if (this.props.action !== nextProps.action && nextProps.action === 'filter') {
      const filterProps = nextProps.filterProps;
      if (filterProps?.imo) {
        const filters = {
          tags: {
            condition: [
              {
                anyOf: [
                  {
                    value: filterProps.imo,
                    negate: false,
                    categoryName: "Vessel",
                    category: this.props.tagCategoriesByName?.vessel,
                  },
                ],
              },
            ],
          },
        };
        history.push(`/$1/$2/$3/-/-/---`, undefined, true);
        return this.props.getEmailList({ folder: nextProps.folder, filters, vesselId: this.props.vesselId, cargoId: this.props.cargoId });
      }
    }

    if (nextProps.composeProps?.to?.[0] && this.props.composeProps?.to?.[0] !== nextProps.composeProps.to[0]) {
      if (nextProps.folder === 'received') {
        let filters = nextProps.emailList.filtersPerFolder[nextProps.folder];
         if (isExchangeFiltersEmpty(filters)) {
           filters = {
             tags: {
               condition: [
                 {
                   anyOf: [
                     {
                       search: nextProps.composeProps.to.join(),
                       negate: false,
                     },
                   ],
                 },
               ],
             },
           };
           return this.props.getEmailList({ folder: nextProps.folder, filters, vesselId: this.props.vesselId, cargoId: this.props.cargoId });
         }
      }
    }

    if (nextProps.folder !== this.props.folder) {
        if (this.props.lastFilters && this.props.lastFilters[`${nextProps.folder}Filter`] && !this.props.isMonitor) {
          return await this.props.getEmailList({ folder: nextProps.folder, filters: this.props.lastFilters[`${nextProps.folder}Filter`] });
        }
      this.props.getEmailList({ folder: nextProps.folder, filters: nextProps.emailList.filtersPerFolder[nextProps.folder], vesselId: this.props.vesselId, cargoId: this.props.cargoId });
    }
  }

  componentDidUpdate(prevProps, prevState, prevContext) {
    if (prevProps.emailList.cursor && prevProps.emailList.cursor === this.props.emailList.cursor && prevProps.emailList.data.length > this.props.emailList.data.length) {
      if ((this.scrollWrapper.scrollHeight - this.scrollWrapper.clientHeight - this.scrollWrapper.scrollTop) < 100){
        this.handleLoadMore();
      }
    }
  }

  handleMonitorEmailsFolder = (val) => {
    this.props.getEmailList({ folder: this.props.folder, vesselId: this.props.vesselId, cargoId: this.props.cargoId, monitorEmailsFolder: val, filters: this.props.emailList.filters });
    this.setState({ monitorEmailsFolder: val });
  }

  handleFilter = debounceWithoutFirstCall((filters) => {
    const flatFilters = filters.tags?.condition?.reduce((acc, item) => {
      return [...acc, ...item.anyOf];
    }, []);
    const flatFiltersWithUid = flatFilters?.map(f => {
      f.uid = uuid();
      return f;
    });
    let isError = false;
    flatFilters?.forEach(condition => {
      if (flatFiltersWithUid.length > 1 && condition.negate) {
        if (flatFiltersWithUid.find(item => condition.search ? (item.uid !== condition.uid && item.search === condition.search) : (item.uid !== condition.uid && item.value === condition.value && item.categoryId === condition.categoryId))) {
          this.context.showMessage({
            level: "error",
            autoDismiss: 5,
            message: "Invalid input in search field",
          });
          isError = true;
        }
      }
    });
    if (!isError) {
      if (this.props.isMonitor) {
        return this.props.getEmailList({ filters, cursor: undefined, vesselId: this.props.vesselId, cargoId: this.props.cargoId, monitorEmailsFolder: this.state.monitorEmailsFolder });
      }
      this.props.getEmailList({ folder: this.props.folder, filters, cursor: undefined });
    }
   }, 300);


  checkRow = (_id, e, checked) => {
    this.props.checkEmail(_id, checked);
  }

  checkAll = (e, checked) => {
    this.props.checkAll(checked);
  }
  archiveEmails = () => {
    this.props.archiveEmails(this.props.emailList.data.filter(m => m.selected).map(m => m.id), this.props.folder !== 'archive');
  }
  getAltHeaderProps = (checkedCount) => {
    if (checkedCount) {
      return altHeaderProps;
    }
    return {};
  }
  assignScrollWrapperRef = (el) => {
    this.scrollWrapper = el;
  }

  selectEmail = (id) => {
    if (this.props.isMonitor) {
      return this.props.openEmailDetails(id);
    }
    this.setState({ preSelectedId: id });
    history.push(`/emails/${this.props.folder}/${id}/-/-/---`);
  }
  closeEmail = () => {
    history.push(`/emails/${this.props.folder}/-/-/-/---`);
  }
  openCompose = (e) => {
    if (this.props.isMonitor) {
      return this.props.openCompose();
    }
    history.push('/$1/$2/$3/compose/-/---');
  }
  closeCompose = () => {
    history.push('/$1/$2/$3/-/-/---');
  }

  markSelectedAsUnread = async () => {
    const selected = this.props.emailList.data.filter(m => m.selected).map(m => m.id);
    await this.props.markEmailAs(selected, { read: false });
    if (selected.includes(this.props.emailId)) {
      this.closeEmail();
    }
  }
  markSelectedAsRead = () => {
    const selected = this.props.emailList.data.filter(m => m.selected).map(m => m.id);
    this.props.markEmailAs(selected, { read: true });
  }
  setPreselectedId = () => {
    if (this.props.emailId) {
      this.setState({ preSelectedId: this.props.emailId });
    }
  }
  keyListen = (event) => {
    let newPreselectedId;
    if (event.target.id === "mail_list_table" && this.props.action !== "add-cargo") {
      const { preSelectedId } = this.state;
      //down
      if (event.keyCode === 40) {
        event.preventDefault();
        if (preSelectedId !== '') {
          const preSelected = document.getElementById(preSelectedId);
          if (preSelected !== null) {
            if (preSelected.dataset.nextid !== '') {
              const next = document.getElementById(preSelected.dataset.nextid);
              if (!isInViewport(next)) {
                next.scrollIntoView({ block: "center", behavior: "auto" });
              }
              newPreselectedId = preSelected.dataset.nextid;
            } else {
              const mailList = document.getElementsByClassName('list_item');
              const last = mailList[mailList.length - 1];
              newPreselectedId = last.id;
            }
          } else {
            const first = document.getElementsByClassName('list_item')[0];
            newPreselectedId = first.id;
          }
        } else {
          const first = document.getElementsByClassName('list_item')[0];
          newPreselectedId = first.id;
        }
        //up
      }
      if (event.keyCode === 38) {
        event.preventDefault();
        if (preSelectedId !== '') {
          const preSelected = document.getElementById(preSelectedId);
          if (preSelected !== null) {
            if (preSelected.dataset.previd !== '') {
              const prev = document.getElementById(preSelected.dataset.previd);
              if (!isInViewport(prev)) {
                prev.scrollIntoView({ block: "center", behavior: "auto" });
              }
              newPreselectedId = preSelected.dataset.previd;
            } else {
              const first = document.getElementsByClassName('list_item')[0];
              newPreselectedId = first.id;
            }
          } else {
            const first = document.getElementsByClassName('list_item')[0];
            newPreselectedId = first.id;
          }
        } else {
          const first = document.getElementsByClassName('list_item')[0];
          newPreselectedId = first.id;
        }
      }
      if (event.keyCode === 13) {
        if (preSelectedId !== null) {
          document.getElementById(preSelectedId).click();
        }
      }
      if (newPreselectedId) {
        this.setState({ preSelectedId: newPreselectedId });
        this.openPreselected(newPreselectedId);
      }
    }
  }

  openPreselected = debounceWithoutFirstCall((id) => {
    document.getElementById(id).click();
  }, 500);

  linkClickHandler = (ev) => {
   if (!this.props.mailGatesState?.senderGates?.length) {
     return;
   }
    let target = ev.target;
    while (target.parentNode && target.nodeName !== "A") {
      target = target.parentNode;
    }
    if (target.nodeName === "A") {
      if (target.href && target.href.indexOf('mailto:') === 0) {
        history.push(`/$1/$2/$3/compose${encodeURIComponent('?to=' + target.href.slice(7) + '&to=')}/-/---`);
        ev.preventDefault();
        document.body.click();
      }
    }
  }

  render() {
    const isDark = this.props.emailList.dark;
    const checkedCount = this.props.emailList.data.filter(m => m.selected).length;
    const folder = this.props.emailList.folder;
    return (
      <Pane
        id="mail_list_table"
        tabIndex={0}
        headerProps={{
          useBorder: true,
          children: <EmailsHeader
            user={this.props.user}
            {...this.props.emailList}
            toggleTheme={this.props.toggleTheme}
            handleFilter={this.handleFilter}
            handleOpenCompose={this.openCompose}
            mailGatesState={this.props.mailGatesState}
            isMonitor={this.props.isMonitor}
            isContactBook={this.props.isContactBook}
            handleCloseTable={this.props.handleCloseTable}
            handleMonitorEmailsFolder={this.handleMonitorEmailsFolder}
            monitorEmailsFolder={this.state.monitorEmailsFolder}
          />,
          conditionalChildren: <EmailsAltHeader {...this.props.emailList} noHover folder={this.props.folder} onArchiveClick={this.archiveEmails} onReadClick={this.markSelectedAsRead} onUnreadClick={this.markSelectedAsUnread} />,
          condition: (checkedCount > 0),
          handleClose: (e) => this.checkAll(e, false),
          selectedCount: checkedCount,
          label: 'mail',
          style: { width: "auto" },
          ...this.getAltHeaderProps(checkedCount),
        }}
        bodyProps={{ style: { height: '100%' } }}
        className={cx(s.table, isDark ? s.dark : '')}
        style={{ outline: "none" }}
      >
        <ThemeProvider theme={{ name: (isDark && 'dark_theme') || 'white_theme' }}>
        <StyledTable className={cx(isDark && s.dark, folder)}>
          <TableHeader rows={this.props.emailList.data} checkAll={this.props.checkAll} isDark={isDark} />
          {this.props.isMonitor && this.props.emailList.loading && <span className={s.empty_list}><Loader isDark={isDark} /></span>}
          {this.props.isMonitor && !this.props.emailList.data.length && <span className={s.empty_list}>
            <EmptyList Icon={ChatMailIcon} title="No e-mails yet" />
          </span>}
          
          {this.props.user.mailGateEnabled || this.props.user.hasFixPlus ? (
            <VirtualList handleLoadMore={this.handleLoadMore} filters={this.props.emailList.filters} rows={this.props.emailList.data} scrollWrapperRef={this.assignScrollWrapperRef} >
              {
                ({ rows, ...restProps }) => (
                  <div>
                    {rows.map((row, i, rowArr) => (
                        <EmailsRow
                          nextRowId={rowArr[i + 1] ? rowArr[i + 1].id : ''}
                          prevRowId={rowArr[i - 1] ? rowArr[i - 1].id : ''}
                          key={row.id}
                          row={row}
                          checkRow={this.checkRow.bind(this, row.id)}
                          isDark={isDark}
                          onSelect={this.selectEmail.bind(this, row.id)}
                          className={cx(this.props.emailId === row.id && 'active', row.hasUnread && 'unread', 'list_item', this.state.preSelectedId === row.id && 'preselected',s.row)}
                          changeEmailTags={this.props.changeEmailTags}
                        />
                      )
                    )}
                  </div>
                )
              }
            </VirtualList>
          ) : <MailsNotConnected /> }
        </StyledTable>
      </ThemeProvider>
        {this.props.emailId ? <DialogHalfScreen open><EmailDetails emailId={this.props.emailId} handleClose={this.closeEmail} action={this.props.action} /></DialogHalfScreen> : null}
        {this.props.action === 'compose' ?
          (<ComposeEmailDialog onClose={this.closeCompose} composeProps={this.props.composeProps} />)
          : null }
      </Pane>
    );
  }
}
export default connect(state => ({ ...state.emails, user: state.login.user, tagCategoriesByName: state.login.tagCategoriesByName, lastFilters: state.login.lastFilters, mailGatesState: state.login.mailGatesState }), { getEmailList, checkEmail, checkAll, toggleTheme, archiveEmails, getProfile, changeEmailTags, markEmailAs, getAccountFilters })(withStyles(s)(EmailsTable));

const navLinks = [{
  name: 'RECEIVED',
  label: 'MAILBOX',
  url: '/emails/received/-/-/-/---/all-fleet/-/-/-',
},
  {
    name: 'SENT',
    label: 'SENT',
    url: '/emails/sent/-/-/-/---/all-fleet/-/-/-',
  },
  {
    name: 'ARCHIVE',
    url: '/emails/archive/-/-/-/---/all-fleet/-/-/-',
  },
  ];

  const folderTypeNav = [{
    name: 'RECEIVED',
    label: 'MAIL',
  },
    {
      name: 'SENT',
      label: 'SENT',
    },
    {
      name: 'ARCHIVE',
      label: 'ARCHIVE',
    },
    ];
const EmailsHeader = styled(function ({ filtersPerFolder, folder = navLinks[0].name, toggleTheme, dark, handleSort, handleFilter, className, handleOpenCompose, user, mailGatesState, isMonitor, handleCloseTable, handleMonitorEmailsFolder, monitorEmailsFolder, isContactBook, ...rest }, ){
  const filters = filtersPerFolder[folder];
  const tagged = filtersPerFolder[folder]?.tagged;
  const newButtonDisabled = !mailGatesState?.senderGates?.length;
  return <div className={cx(className, "align-items-center", "w-100", "space-between")}>
    <div className="flex margin_children8">
      {isMonitor ? <FolderTypeSelect handleSelect={handleMonitorEmailsFolder} links={folderTypeNav} val={monitorEmailsFolder} />
      : <NavigationPopover links={navLinks} val={folder.toUpperCase()} />
      }
        {isMonitor ? <ChipsAutocomplete
            folder="exchange"
            key={isContactBook ? `contacts${monitorEmailsFolder}` : monitorEmailsFolder}
            tagged={tagged}
            filters={isContactBook ? rest.filters : filters}
            tags={isContactBook ? rest.filters?.tags : filters?.tags}
            handleSearch={handleFilter}
            entity="cargoRequest"
            className={'exchange-tags-autocomplete'}
            name="tags"
          />
          : <SaveFilterWrapper style={{display: "flex"}} lastFilterFieldName={`${folder}Filter`}>
          <ChipsAutocomplete
            folder="exchange"
            key={folder}
            tagged={tagged}
            filters={filters}
            tags={filters?.tags}
            handleSearch={handleFilter}
            entity="cargoRequest"
            className={'exchange-tags-autocomplete'}
            name="tags"
          />
        <EmailsTypeFilter style={{margin: "0 6px"}} tagged={tagged} filters={filters} handleFilter={handleFilter} name="scopes" />
        <RoundedDateRangeFilter filters={filters} handleFilter={handleFilter} name={'receivedAt'} />
      </SaveFilterWrapper>
      }
      {isContactBook && <EmailsTypeFilter style={{margin: "0 6px"}} tagged={tagged} filters={rest.filters} handleFilter={handleFilter} name="scopes" />}
    </div>
    <div style={{ marginLeft: '8px' }} className="align-items-center" >
       <RaisedButton styles={'xs'} style={{ minWidth: "46px" }} disabled={newButtonDisabled} buttonStyle={{ minWidth: "46px" }} primary label={"NEW"} onClick={handleOpenCompose} />
      <ThemeToggle dark={dark} changeTheme={toggleTheme} />
      {(isMonitor && handleCloseTable) && <span onClick={handleCloseTable} className="material-icons-outlined close-icon pointer">close</span>}
    </div>
  </div>;
})`
  > div:nth-child(3) {
    max-width: 217px;
  }
  .exchange-tags-autocomplete {
    width: 220px;
  }
  @media (min-width: 1440px) {
    .exchange-tags-autocomplete {
      width: 280px;
    }
  }
`

const EmailsAltHeader = styled(function ({ className, onArchiveClick, onUnreadClick, onReadClick, ...props }) {
  const hasUnread = props.data.some(message => message.selected && message.hasUnread);
  const hasRead = props.data.some(message => message.selected && !message.hasUnread);
  return <div className={className}>
    <CustomTooltip tooltip={props.folder === 'archive' ? 'From Archive' : 'To Archive'}><img onClick={onArchiveClick} src={props.folder === 'archive' ? icon_archive_out : icon_archive_in} /></CustomTooltip>
    {hasRead && <CustomTooltip tooltip={'Mark as unread'}><span onClick={onUnreadClick} className="material-icons">mark_email_unread</span></CustomTooltip> }
    {hasUnread && <CustomTooltip tooltip={'Mark as read'}><span onClick={onReadClick} className="material-icons">drafts</span></CustomTooltip> }
  </div>;
})`
  display: flex;
  >div{
    cursor: pointer;
  }
  > div + div {
    margin-left: 8px;
  }
  .material-icons {
    color: #787878;
  }
`

function ReplyCounter({ mailsInThread }) {
  if (!mailsInThread || mailsInThread < 2) {
    return null;
  }
  return (<span className="reply_counter">
    <span className="material-icons-outlined">reply</span>
    <span>{mailsInThread}</span>
  </span>);
}

function MultiEmail({ mails = [] }) {

  return (<div className={"multi_email"}>
    <div><ContactDetailsPopover email={mails[0]}>{mails[0]}</ContactDetailsPopover></div>
    {mails.length > 1 ?
    <WithTooltip tip={() => mails.length > 1 ? <div className={cx('tooltip-default', 'dark')}>{mails.map((mail) => <Text color={"var(--text-links-light)"} isBold ellipsis ><a href={`mailto:${mail}`}>{mail}</a></Text>)}</div>: null}>
       <span>&nbsp;+{mails.length - 1}</span>
    </WithTooltip>
      : null}
  </div>);
}

function EmailsRow({row, isDark, onSelect, className, nextRowId, prevRowId, changeEmailTags, ...props }) {
  return <StyledRowWrapper isDark={isDark} onClick={onSelect} className={className} id={row.id} data-nextid={nextRowId} data-previd={prevRowId}>
    <Col>
      <CustomCheckbox style={{ marginTop: '-4px', marginBottom: '2px' }} checked={row.selected} onClick={ ev => ev.stopPropagation() } onCheck={props.checkRow} className={s.ref_checkbox} />
      <Icon>{getMessageTypeIcon(row)}</Icon>
    </Col>
    <Col>
      <div className={"flex"}>
        {row.favorite ? <StarredIcon className={'favorite-icon'} /> : null}
        <Text className={"col-from"} color={"var(--text-links)"} isBold>
          <MultiEmail mails={row.from} />
        </Text>
        <Text className={"col-to"} color={"var(--text-links)"} isBold>
          <MultiEmail mails={row.to} />
        </Text>
      </div>
      <Text className="flex align-items-center">
        {row.refNo} <ReplyCounter mailsInThread={row.mailsInThread} />
      </Text>
    </Col>
    <Col>
      <div>
        <WithTooltip style={{display: 'block'}} tip={() => row.subject ? <div className={cx('tooltip-default', 'dark')}>{row.subject}</div>: null}>
          <Text isBold ellipsis>
            {row.subject}
          </Text>
        </WithTooltip>
        <TagsViewExchange id={row.id} tags={row.tags} changeEmailTags={changeEmailTags} />
      </div>
      <div>
        <div className="flex align-items-end">
          {row.hasAttachments ? <span className="material-icons-outlined attach-icon">attach_file</span> : null}
          <Text ellipsis>
            {row.text}
          </Text>
        </div>
      </div>
    </Col>
    <Col right>
      <Text color="#787878" textAlign="right">
        <WithTooltip horizontalPosition={'left'} style={{display: 'block'}} tip={() => <div className={cx('tooltip-default', 'dark')}>{dateTimeShiftedToLocal(row.receivedAt) }</div>}>
          {dateStringOrTime(row.receivedAt)}
        </WithTooltip>
      </Text>
      <Text textAlign="right">
        {row.isPrivate ?
          <PrivateIcon />
          : <SharedIcon /> }
      </Text>
    </Col>
  </StyledRowWrapper>
}
