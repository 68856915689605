import PropTypes from 'prop-types';
import React, { Component } from 'react';
import history from '../../core/history';

function isLeftClickEvent(event) {
  return event.button === 0;
}

function isModifiedEvent(event) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

class Link extends Component {
  static propTypes = {
    to: PropTypes.string.isRequired,
    children: PropTypes.node,
    onClick: PropTypes.func,
    handleClick: PropTypes.func,
  };

  handleClick = event => {
    if (event.currentTarget.target === '_blank') {
      return;
    }
    if (this.props.onClick) {
      this.props.onClick(event);
    }
    if (this.props.handleClick) {
      this.props.handleClick(event);
    }

    if (isModifiedEvent(event) || !isLeftClickEvent(event)) {
      return;
    }

    if (event.defaultPrevented === true) {
      return;
    }

    event.stopPropagation();
    event.preventDefault();
    this.props.to && history.push(this.props.to);
  };

  render() {
    const { to, children, onClick, handleClick, ...props } = this.props;
    return <a href={to} {...props} onClick={this.handleClick}>{children}</a>;
  }
}

export default Link;
