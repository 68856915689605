import * as handlers from './monitor/index';
import { defaultCargoSortField, defaultSortField } from './../components/Monitor/shared';
/** @typedef {Object} MonitorState
 * @property {List} cargoList
 * @property {List} vesselList
 */
export default function monitor(
  state = {
    cargoList: {
      data: [],
      page: 1,
      next: 2,
      filters: {channels:{mine:true, broker:true, market: true}},
      sort: defaultCargoSortField,
      loading: false,
    },
    vesselList: {
      data: [],
      page: 1,
      next: 2,
      filters: {channels:{mine:true, broker:true, market: true, onRoute: true}},
      sort: defaultSortField,
      loading: false,
      hiddenCount: null,
      dark: true,
    },
    tcList: {
      data: [],
      page: 1,
      next: 2,
      filters: {search: '', channels:{mine:true, broker:true, market: true}},
      sort: { field: '' },
      loading: false,
      dark: false,
      form: {}
    },
    selectedVesselId: '',
    isHidden: false
  },
  action,
) {
  if (!handlers[action.type]) {
    return state;
  }
  try {
    return handlers[action.type](state, action) || state;
  } catch (e) {
    console.error(e);
    try {
      if (typeof window !== 'undefined' && typeof window.Raven !== 'undefined') {
        window.Raven.captureException(e);
      }
    } catch (e2) {
      console.error(e2);
    }
    return state;
  }
}
