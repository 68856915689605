/**@see  {@link CONTRACT_LIST_REDUCER}*/
export const CONTRACT_LIST = 'CONTRACT_LIST';
/**@see  {@link CONTRACT_LIST_REQUEST_REDUCER}*/
export const CONTRACT_LIST_REQUEST = 'CONTRACT_LIST_REQUEST';
/**@see  {@link CONTRACT_LIST_FAILED_REDUCER}*/
export const CONTRACT_LIST_FAILED = 'CONTRACT_LIST_FAILED';

/**@see  {@link CONTRACT_GET_CONTRACT_REDUCER}*/
export const CONTRACT_GET_CONTRACT = 'CONTRACT_GET_CONTRACT';
/**@see  {@link CONTRACT_GET_CONTRACT_REQUEST_REDUCER}*/
export const CONTRACT_GET_CONTRACT_REQUEST = 'CONTRACT_GET_CONTRACT_REQUEST';
/**@see  {@link CONTRACT_GET_CONTRACT_FAILED_REDUCER}*/
export const CONTRACT_GET_CONTRACT_FAILED = 'CONTRACT_GET_CONTRACT_FAILED';

/**@see  {@link CONTRACT_FILTER_REDUCER}*/
export const CONTRACT_FILTER = 'CONTRACT_FILTER';

/**@see  {@link CONTRACT_FIX_REDUCER}*/
export const CONTRACT_FIX = 'CONTRACT_FIX';
/**@see  {@link CONTRACT_FIX_REQUEST_REDUCER}*/
export const CONTRACT_FIX_REQUEST = 'CONTRACT_FIX_REQUEST';
/**@see  {@link CONTRACT_FIX_FAILED_REDUCER}*/
export const CONTRACT_FIX_FAILED = 'CONTRACT_FIX_FAILED';

/**@see  {@link CONTRACT_REJECT_REDUCER}*/
export const CONTRACT_REJECT = 'CONTRACT_REJECT';
/**@see  {@link CONTRACT_REJECT_REQUEST_REDUCER}*/
export const CONTRACT_REJECT_REQUEST = 'CONTRACT_REJECT_REQUEST';
/**@see  {@link CONTRACT_REJECT_FAILED_REDUCER}*/
export const CONTRACT_REJECT_FAILED = 'CONTRACT_REJECT_FAILED';

/**@see  {@link CONTRACT_CANCEL_REDUCER}*/
export const CONTRACT_CANCEL = 'CONTRACT_CANCEL';
/**@see  {@link CONTRACT_CANCEL_REQUEST_REDUCER}*/
export const CONTRACT_CANCEL_REQUEST = 'CONTRACT_CANCEL_REQUEST';
/**@see  {@link CONTRACT_CANCEL_FAILED_REDUCER}*/
export const CONTRACT_CANCEL_FAILED = 'CONTRACT_CANCEL_FAILED';

/**@see  {@link CONTRACT_EXTEND_TIME_REDUCER}*/
export const CONTRACT_EXTEND_TIME = 'CONTRACT_EXTEND_TIME';
/**@see  {@link CONTRACT_EXTEND_TIME_REQUEST_REDUCER}*/
export const CONTRACT_EXTEND_TIME_REQUEST = 'CONTRACT_EXTEND_TIME_REQUEST';
/**@see  {@link CONTRACT_EXTEND_TIME_FAILED_REDUCER}*/
export const CONTRACT_EXTEND_TIME_FAILED = 'CONTRACT_EXTEND_TIME_FAILED';

/**@see  {@link CONTRACT_DELETE_CARGO_REDUCER}*/
export const CONTRACT_DELETE_CARGO = 'CONTRACT_DELETE_CARGO';
/**@see  {@link CONTRACT_DELETE_CARGO_REQUEST_REDUCER}*/
export const CONTRACT_DELETE_CARGO_REQUEST = 'CONTRACT_DELETE_CARGO_REQUEST';
/**@see  {@link CONTRACT_DELETE_CARGO_FAILED_REDUCER}*/
export const CONTRACT_DELETE_CARGO_FAILED = 'CONTRACT_DELETE_CARGO_FAILED';

/**@see  {@link CONTRACT_REOPEN_CARGO_REDUCER}*/
export const CONTRACT_REOPEN_CARGO = 'CONTRACT_REOPEN_CARGO';
/**@see  {@link CONTRACT_REOPEN_CARGO_REQUEST_REDUCER}*/
export const CONTRACT_REOPEN_CARGO_REQUEST = 'CONTRACT_REOPEN_CARGO_REQUEST';
/**@see  {@link CONTRACT_REOPEN_CARGO_FAILED_REDUCER}*/
export const CONTRACT_REOPEN_CARGO_FAILED = 'CONTRACT_REOPEN_CARGO_FAILED';

/**@see  {@link CONTRACT_DELETE_POS_REDUCER}*/
export const CONTRACT_DELETE_POS = 'CONTRACT_DELETE_POS';
/**@see  {@link CONTRACT_DELETE_POS_REQUEST_REDUCER}*/
export const CONTRACT_DELETE_POS_REQUEST = 'CONTRACT_DELETE_POS_REQUEST';
/**@see  {@link CONTRACT_DELETE_POS_FAILED_REDUCER}*/
export const CONTRACT_DELETE_POS_FAILED = 'CONTRACT_DELETE_POS_FAILED';

/**@see  {@link CONTRACT_REOPEN_POS_REDUCER}*/
export const CONTRACT_REOPEN_POS = 'CONTRACT_REOPEN_POS';
/**@see  {@link CONTRACT_REOPEN_POS_REQUEST_REDUCER}*/
export const CONTRACT_REOPEN_POS_REQUEST = 'CONTRACT_REOPEN_POS_REQUEST';
/**@see  {@link CONTRACT_REOPEN_POS_FAILED_REDUCER}*/
export const CONTRACT_REOPEN_POS_FAILED = 'CONTRACT_REOPEN_POS_FAILED';

/**@see  {@link CONTRACT_VOYAGE_SUBJECT_UPDATED_REDUCER}*/
export const CONTRACT_VOYAGE_SUBJECT_UPDATED =
  'CONTRACT_VOYAGE_SUBJECT_UPDATED';
/**@see  {@link CONTRACT_TC_SUBJECT_UPDATED_REDUCER}*/
export const CONTRACT_TC_SUBJECT_UPDATED = 'CONTRACT_TC_SUBJECT_UPDATED';
export const UPDATE_CONTRACT_PDF = 'UPDATE_CONTRACT_PDF';
export const AWAIT_PDF_UPDATE = 'AWAIT_PDF_UPDATE';
export const UPDATE_CONTRACT_DETAILS = 'UPDATE_CONTRACT_DETAILS';
