import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Dialog from './Dialog';
import RaisedButton from '../Common/RaisedButton';
const titleStyle = {
  fontWeight: 'bold',
  color: '#333333',
  textAlign: 'center',
  fontSize: '14px',
};
const contentStyle = {
  fontWeight: 'normal',
  color: '#333333',
  textAlign: 'center',
  fontSize: '14px',
  whiteSpace: 'pre-wrap',
};
const buttonsStyle = { textAlign: 'right', margin: '0' };

export default function ConfirmDialog(props) {
  return (
    <Dialog
      modal
      contentStyle={{ maxWidth: '440px' }}
      open
      handleClose={() => {
        props.handleClose(false);
      }}
      title={props.title}
      actions={
        <div className="row flex justify-content-end" style={buttonsStyle}>
            <RaisedButton
              label="Cancel"
              disableTouchRipple
              disableFocusRipple
              secondary
              onTouchTap={() => {
                props.handleClose(false);
              }}
              style={{ marginRight: '8px', borderRadius:50, width: '100px' }}
              buttonStyle={{ borderRadius:'50px' }}
              overlayStyle={{
                borderRadius: '50px'
              }}
            />
            <RaisedButton
              label="OK"
              disableTouchRipple
              disableFocusRipple
              primary
              onTouchTap={() => {
                props.handleClose(true);
              }}
              style={{ borderRadius:50, width: '100px' }}
              buttonStyle={{ borderRadius:'50px' }}
              overlayStyle={{
                borderRadius: '50px'
              }}
            />
        </div>
      }
    >
      <div>
        <div style={contentStyle}>
          {props.children}
        </div>
      </div>

    </Dialog>
  );
}
