import Port from '../../core/api/Port';
import MenuItem from 'material-ui/MenuItem';
import Autocomplete from './AutoComplete';
import PropTypes from 'prop-types';
import React, { Component, PureComponent } from 'react';
import Dialog from './Dialog';
import Formy from 'formsy-react';
import FormsyText from '../Common/FormsyText';
import CountrySelect from './CountrySelect';
import { loadMap } from '../../core/utils';
import RaisedButton from './RaisedButton';
import { number } from '../../core/format';
import { create } from '../../actions/port';


const Form = Formy.Form;

function renderer(ports) {
  const items = [];
  ports.forEach(port => {
    items.push(PortAutocomplete.portRenderer(port));
    port.sub &&
      port.sub.forEach(port => {
        items.push(
          PortAutocomplete.portRenderer(port, {
            innerDivStyle: { marginLeft: '15px' },
          }),
        );
      });
  });
  if (this.props.canCreate) {
    items.push({
      text: "",
      _value: null,
      value: (
        <MenuItem
          primaryText={<span className="green" >+ Create Port</span>}
          className="port_autocomplete_item"
          innerDivStyle={{ color: '#4D9E0E' }}
          onTouchTap={this.props.handleOpenCreatePort}
        />
      ),
    });
  }
  return items;
}


class CreatePortDialog extends PureComponent {

  static contextTypes= {
    store: PropTypes.object,
  }

  static styles = {
    map: { width: '100%', height: '300px' },
    required: {
      hr: { borderTop: '2px solid  rgb(244, 67, 54)', margin: '0' },
      label: { color: 'rgb(244, 67, 54)', fontSize: '12px' },
    },
    title: { color: '#333333', fontSize: '14px' },
    input: { fontSize: '14px' },
    contentWrapper: { width: '256px' },
    coordinatesWrapper: { display: 'inline-block' },
  };
  state = {};

  componentDidMount() {
    loadMap().then(() => {
      this.map = this.map || new google.maps.Map(document.getElementById('create-port-map'), { center: { lat: 0, lng: 0 }, zoom: 1, draggableCursor: 'crosshair', });
      google.maps.event.addListener(this.map, 'click', this.addMarker);
    });
  }
  handleValidSubmit = (port) => {
    if(this.state.loading) {
      return;
    }
    this.setState({ submitted: true });
    if (!port || !this.state.position) {
      return;
    }
    port.coordinates = [this.state.position.lng(), this.state.position.lat()];
    port = { ...{ portLimitations: {}, liftsCranes: {}, facilities: {}, serviceProviders: [], warehouses: {} }, ...port };
    this.state.loading = true;
    this.setState({ loading: true });
    this.context.store.dispatch(create(port)).then((res) => {
      this.setState({ loading: false });
      if (res.status === 200) {
        this.props.handleClose(undefined, undefined, res.data);
      }
    });
  };
  handleInvalidSubmit = () => {
    this.handleValidSubmit();
  }

  addMarker = (e) => {
    const marker = this.state.marker || new google.maps.Marker({
      position: e.latLng,
      map: this.map,
    });
    marker.setPosition(e.latLng);
    this.setState({ marker: marker, position: e.latLng });
  }

  render(){
    const styles = CreatePortDialog.styles;
    const { defaultName, ...props } = this.props;
    return (
      <Dialog open {...props}>
        <Form ref="form" noValidate onValidSubmit={this.handleValidSubmit} onInvalidSubmit={this.handleInvalidSubmit} >
          <h3 style={styles.title} >ADD NEW PORT</h3>
          <div style={styles.contentWrapper}>
            <FormsyText style={styles.input} fullWidth required requiredError="required" name="name" hintText="Name*" defaultValue={defaultName} />
            <FormsyText style={styles.input} fullWidth name="unLoCode" hintText="UN LOCODE" validations="minLength:5,maxLength:5" validationError="5 characters" />
            <CountrySelect style={styles.input} fullWidth name="country" required requiredError="required" hintText="Country*" />
          </div>
          <span style={styles.coordinatesWrapper} >
            <span className="bold">Latitude / Longitude:</span> {this.state.position ? <span>{number(this.state.position.lat(), undefined, undefined, 3)} / {number(this.state.position.lng(), undefined, undefined, 3)} </span> : <span className="green">Set marker on the map</span>}
            <div style={{ display: this.state.submitted && !this.state.position ? 'block' : 'none' }}>
              <hr style={styles.required.hr} />
              <span style={styles.required.label}>required</span>
            </div>
          </span>
          <br />
          <br />
          <div style={styles.map} id="create-port-map" />
          <br />
          <div className="flex justify-content-end"><RaisedButton disabled={this.state.loading} type="submit" primary label="Add" /></div>
        </Form>
    </Dialog>);
  }
}

const styles = {
  unlocode: {
    position: 'absolute',
    right: 0,
    bottom: '10px',
    color: 'gray',
  },
}

class PortAutocomplete extends Autocomplete {
  static defaultProps = {
    forceSelection: true,
    handleOpenCreatePort: e => window.open("/port/create"),
    popoverProps: { style: { minWidth: '256px' } },
  };
  constructor(props) {
    super(props);
    this.Api = Port;
    this.renderer = renderer;
  }

  handleCreatePort = (e0, e1, port) => {
    this.setState({ createDialogOpened: false });
    this.setValue(port);
}

  static portRenderer(port, props = {}) {
    const countryCode = port.country && port.country.code ? ' (' + port.country.code + ')' : '';
    return {
      text: port.name,
      _value: port,
      value: (
        <MenuItem
          primaryText={port.name + countryCode }
          secondaryText={port.unLoCode || ' '}
          className="port_autocomplete_item"
          title={port.name + ' ' + (port.unLoCode || '')}
          {...props}
        />
      ),
    };
  }

  render() {
    return (
      <div className="sn-port-autocomplete-wrapper" style={{ position: 'relative' }}>
        {super.render()}
        {this.state.createDialogOpened ?
          <CreatePortDialog
            defaultName={this.state.__searchText}
            handleClose={this.handleCreatePort}
          /> :
          null
        }
      </div>
    );
  }
}

PortAutocomplete.propTypes = {};

export default PortAutocomplete;
