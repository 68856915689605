import * as React from "react";
import cx from 'classnames';

import s from './Filter.scss';
import Select from '../../Common/Select';
import MenuItem from 'material-ui/MenuItem';
import CancelIcon from 'material-ui/svg-icons/navigation/cancel';

import { formatTypeByPurposePlural, number } from './../../../core/format';
import {findCargoTypeById} from "../../dictionaries";
import {cloneDeep} from "lodash";
import { v4 as uuidv4 } from 'uuid';
import {UnionIconTooltip} from "../../Common/CountrySelect";
import CustomTooltip from "../../Common/CustomTooltip";

export const styleForm = {
  labelStyle: {
    fontSize: '13px',
    fontWeight: '500',
    color: '#333',
  }
}

export const typeByPurposeList = [undefined,"BULK", "BULKTANK", "CAR", "CONT", "LIVESTOCK", "MPP", "PASS", "PASSCAR", "REEFER", "TANK"];
export const typeByConstructionList = [ undefined,"SID", "TWEEN"];

export const MAX_PORTS_FOR_FILTER = 4;

export class SelectedPorts extends React.Component {
  render(){
    const props = this.props;
    const { ports, removeFromPorts, distanceUnit = 'nm' } = props;
    if(!ports || !ports.length) {
    return null;
    }
    return (
      <div className="row">
        <div className={cx("col-8", s.selected_ports)}>
          {
            ports && ports.length >= MAX_PORTS_FOR_FILTER &&
              <div className={s.info}>
                You can add maximum {MAX_PORTS_FOR_FILTER} regions or ports.
                <br />
              </div>
          }
          {
            ports && ports.map((port,i) => {
              if(this.refs[`paragraph${i}`] && this.refs[`paragraph${i}`]?.scrollWidth>this.refs[`paragraph${i}`]?.clientWidth) return (
              <CustomTooltip key={port._id + port.maxDistance + port.maxTime} verticalAlign='top' style={{maxWidth:'100%',height:'unset'}} tooltip={port.name}>
                <div
                  key={port._id + port.maxDistance + port.maxTime}
                  className={
                    cx(s.selected_port_or_region, s[port.areaParent ? 'region' : 'port'], port.exclude && s.exclude)
                  }
                >
                  <p ref={`paragraph${i}`}>
                    {port.exclude ? '- ' : null}{port.name}
                    {port.maxDistance && !props.hideDistance ?
                      <span style={port.exclude ? {color: '#333333'} : undefined}>{`+${number(Number(port.maxDistance), "", "")} ${distanceUnit}`}</span> :
                      null
                    }
                    {
                      port.maxTime && !props.hideDistance ?
                        <span>{`+${port.maxTime} days`}</span> :
                        null
                    }
                  </p>
                  <CancelIcon className={cx(s.icon_delete)} onClick={() => { removeFromPorts(port) }} />
                </div>
              </CustomTooltip>
              )
              return (
                <div
                  key={port._id + port.maxDistance + port.maxTime}
                  className={
                    cx(s.selected_port_or_region, s[port.areaParent ? 'region' : 'port'], port.exclude && s.exclude)
                  }
                >
                  <p ref={`paragraph${i}`}>
                    {port.exclude ? '- ' : null}{port.name}
                    {port.maxDistance && !props.hideDistance ?
                      <span style={port.exclude ? {color: '#333333'} : undefined}>{`+${number(Number(port.maxDistance), "", "")} ${distanceUnit}`}</span> :
                      null
                    }
                    {
                      port.maxTime && !props.hideDistance ?
                        <span>{`+${port.maxTime} days`}</span> :
                        null
                    }
                  </p>
                  <CancelIcon className={cx(s.icon_delete)} onClick={() => { removeFromPorts(port) }} />
                </div>
              )
            })
          }
        </div>
      </div>
    );
  }
}

export function SelectedTypeByPurposes({ list = [], handleRemove, ...props }) {

 return (<div className="row">
   <div className={cx("col-8", s.selected_ports)}>
     {list.map(el => {
       return (<div
         key={el}
         className={
           cx(s.selected_port_or_region, s.port)
         }
       >
         <p style={{ display: "flex", alignItems: "center", textWrap: "nowrap" }}>
         {el &&
      <span style={{ top: '-1px', position: 'relative', marginRight: '4px' }}><img alt="icon" src={`/images/vessel-icons/moving/${el}.png`}/></span>}<span>{formatTypeByPurposePlural(el)}</span>
         </p>
         <CancelIcon className={cx(s.icon_delete)} onClick={() => { handleRemove(el); }} />
       </div>);
     })}
   </div>
 </div>);
}
export function SelectedChips({ list = [], handleRemove, ...props }) {
  let preparedList = [...list].filter(val => val);
  if (list.length && typeof list[0] === "string") {
    preparedList = list.map(el => ({ name: el, _id: uuidv4() }));
  }
 return (<div className="row">
   <div className={cx("col-8", s.selected_ports)}>
     {preparedList.map(el => {
       return (<div
         key={el._id}
         className={
           cx(s.selected_port_or_region, s.port)
         }
       >
         <p>
         {el.name}
         </p>
         <CancelIcon className={cx(s.icon_delete)} onClick={() => { handleRemove(el); }} />
       </div>);
     })}
   </div>
 </div>);
}

/**
 * This function convert param to number to fix weird behavior
 * with own implementation of 'FormsyText' with isNumeric validation
 * @param {number | string} value
 */
export function makeNumberIfValid(value) {
  let val = parseFloat(value);
  // if value is number save it instead of raw input
  if (Number.isNaN(val)) {
    return value;
  }
  return val;
}

export const commonReset = onChange => key => onChange(key, "");

export function commonAutocompleteHandler(onChange) {
  const that = this;
  // TODO: get rid of this closure and uncontrolled getValue and resetValue
  return (inputName = "", arrName = "") => val => {
    const autocompleteRef = `${arrName}Ref`
    const newChip = val || that?.[autocompleteRef].getValue();

    if (!newChip) {
      return;
    }
    if (newChip._id || typeof newChip === 'object') {
      onChange(`${arrName}Checkbox`, true);
      onChange(inputName, newChip, false);
      if (newChip.areaParent || inputName === 'currentPosition') {
        onChange(arrName, {...newChip});
        that?.[autocompleteRef]?.resetValue();
        onChange(inputName, "");
      }
    }

  }
}

export function DistanceUnitSelect(props){

  return <Select {...props} className={s.select_distance_unit}>
    <MenuItem
      key="maxTime"
      value="maxTime"
      primaryText="days"
      style={{
        fontSize: '13px'
      }}
    />
    <MenuItem
      key="maxDistance"
      value="maxDistance"
      primaryText="nm"
      style={{
        fontSize: '13px'
      }}
    />
  </Select>
}


// function to handle checkbox for min/max inputs (accepts genericChange fn to update FIlterWrapper's state)
export const commonDependantChange = (handleChange, filter) => (name, dependantName, selector = item => item, shouldUpdateCheckbox = false) => (e, val) => {
  let selected = null;
  try {
    selected = selector(val);
    const otherField = dependantName.replace('Checkbox', "");
    handleChange(dependantName, (!!filter[otherField] ||!!selected));
  } catch (error) {
    console.error(error);
  } finally {
    handleChange(name, selected, shouldUpdateCheckbox);
  }
}
export const commonMultiSelectField = onChange => (name, checkbox) => (ev, val) => {
  onChange(name, val, false);
  if (val && val.length === 0) {
    onChange(checkbox, false);
  } else {
    onChange(checkbox, true);
  }
};
// function to enable checkbox immediately after value has been selected
export const commonSelectField = onChange => (name, checkbox) => (ev, val) => {
  onChange(name, val, false);
  onChange(checkbox, true);
}
// enable/disable checkbox based on value and already added chips
export const commonGenericChange = (onChange, filter) => (name, arrName = '', shouldUpdateCheckbox = true, boundedField) => (e, val) => {
  try {
    arrName = arrName || name.replace('Port', '');

    if (boundedField) {
      let boundedFieldVal = filter[boundedField];
      if (!boundedFieldVal && !val) {
        onChange(`${arrName}Checkbox`, false);
        return;
      }
    }
    if (Array.isArray(filter[arrName]) && filter[arrName].length === 0 && !val) {
      onChange(`${arrName}Checkbox`, false);
    }
    else if (arrName === name && !val && !boundedField) {
      onChange(`${arrName}Checkbox`, false);
    }
    if (typeof val === 'object' && val !== null && arrName) {
      onChange(`${arrName}Checkbox`, true);
    }
  } catch (error) {
    console.error(error);
  } finally {
    onChange(name, val, shouldUpdateCheckbox);
  }
}

export function SelectedFlags({flags, remove}){
  if(!flags) {
    return null;
  }
  return (<div className="row">
    <div className={cx("col grow", s.selected_ports)}>
      {flags.map(flag=> (
        <div
          key={flag.country?._id ?? flag._id}
          className={
            cx(s.selected_port_or_region, s['port'], flag.exclude && s.exclude)
          }
        >
          <p>
            {flag.exclude ? "– " : ""}
            {flag.country.name}
          </p>
          {flag.country.isUnion ? <div className={s.country_info}><UnionIconTooltip country={flag.country} horizontalAlign={'center'} verticalAlign={'top'} /> </div> : null}
           <CancelIcon className={cx(s.icon_delete)} onClick={() => { remove(flag) }} />
        </div>
      ))}
    </div>
  </div>)
}
export const Chips = ({ chips, remove, selector = item => item.name }) => !chips
  ? null
  : (
    <div className="row">
    <div className={cx("col grow", s.selected_ports)}>
      {chips.map(chip => (
        <div
          key={chip._id}
          className={
            cx(s.selected_port_or_region, s['port'], chip.exclude && s.exclude)
          }
        >
          <p>
            {chip.exclude ? "– " : ""}
            {selector(chip)}
          </p>
           <CancelIcon className={cx(s.icon_delete)} onClick={() => { remove(chip) }} />
        </div>
      ))}
    </div>
  </div>
  )

export function SelectedCargoes({ cargoes, remove }){
  const selector = cargo => `${cargo?.name ? `${cargo.name}${cargo?.cargoType?.name ? ` (${cargo.cargoType.name})` : ''}` : cargo?.cargoType?.name ? cargo.cargoType.name : '---'}`
  return <Chips chips={cargoes} selector={selector} remove={remove}></Chips>
}

export function SelectedTypes({ types, remove }){
  const selector = item => formatTypeByPurposePlural(item?.type || '---');
  return <Chips chips={types} selector={selector} remove={remove}></Chips>
}

export function SelectedGeneralTypes({ types, remove }){
  const selector = item => `${item && (item.typeByPurpose || item.typeBySpecialty)
    ? `${item.typeByPurpose?.type || '---'} ${item.typeBySpecialty?.type ? `(${item.typeBySpecialty.type})` : ''}`
    : '---'}`
  return <Chips chips={types} selector={selector} remove={remove}></Chips>
}
export const Tooltip = ({text, children, noMargin = false, disabled = false, ...rest}) => (
  <div className={cx(s.hoverable_tooltip, disabled && s.disabled_add_tooltip)} style={noMargin ? {marginRight: '0px', width: 'auto'} : {width: 'auto'}} {...rest}>
    <span className={s.tooltip}>{text}</span>
    {children}
  </div>
)

export function prepareCargoFilterFromServer(filter = {}){
  if (filter.deckCargoFilter) {
    if (filter.deckCargoFilter.cargoType) {
      filter.deckCargoFilter.cargoTypes = filter.deckCargoFilter.cargoTypes || [];
      filter.deckCargoFilter.cargoTypes.push(filter.deckCargoFilter.cargoType);
      filter.deckCargoFilter.cargoType = undefined;
      filter.deckCargoFilter.cargoTypesCheckbox = filter.deckCargoFilter.cargoTypesCheckbox || filter.deckCargoFilter.cargoTypeCheckbox;
    }
    if (filter.deckCargoFilter.cargoTypes) {
      filter.deckCargoFilter.cargoTypes = filter.deckCargoFilter.cargoTypes.map(cti => findCargoTypeById(cti._id || cti));
    }
    if (filter.deckCargoFilter.cargoName) {
      filter.deckCargoFilter.cargoNames = filter.deckCargoFilter.cargoNames || [];
      filter.deckCargoFilter.cargoName = { name: filter.deckCargoFilter.cargoName, _id: uuidv4() };
      filter.deckCargoFilter.cargoNames = [...filter.deckCargoFilter.cargoNames, filter.deckCargoFilter.cargoName];
      filter.deckCargoFilter.cargoName = undefined;
      filter.deckCargoFilter.cargoNamesCheckbox = filter.deckCargoFilter.cargoNamesCheckbox || filter.deckCargoFilter.cargoNameCheckbox;
    }
    if (filter.deckCargoFilter.cargoNames) {
      filter.deckCargoFilter.cargoNames = filter.deckCargoFilter.cargoNames.map(cn => {
        if (typeof cn === "string") {
          return { name: cn, _id: uuidv4() };
        }
        return cn;
      });
    }
  }
  return filter;
}

export async function prepareCargoFiltersFromServer(filters){
  filters.forEach(prepareCargoFilterFromServer);
  return filters;
}

export function prepareCargoFilterToServer(filterData) {
  const _filterData = cloneDeep(filterData);
  if (_filterData.cargoTypes) {
    _filterData.cargoTypes = _filterData.cargoTypes.map(ct => ct._id);
  }
  if (_filterData.cargoNames) {
    _filterData.cargoNames = _filterData.cargoNames.map(cn => cn.name);
  }
  return _filterData;
}
export function prepareVesselFilterFromServer(filter = {}, dataFieldName){
  if (filter[dataFieldName]) {
    if (filter[dataFieldName].typeByPurpose) {
      filter[dataFieldName].typeByPurposes = filter[dataFieldName].typeByPurposes || [];
      filter[dataFieldName].typeByPurposes = [...filter[dataFieldName].typeByPurposes, filter[dataFieldName].typeByPurpose];
      filter[dataFieldName].typeByPurpose = undefined;
      filter[dataFieldName].typeByPurposesCheckbox = filter[dataFieldName].typeByPurposesCheckbox || filter[dataFieldName].typeByPurposeCheckbox;
    }
  }
  return filter;
}

export async function prepareVesselFiltersFromServer(filters, dataFieldName){
  filters.forEach((filter) => prepareVesselFilterFromServer(filter, dataFieldName));
  return filters;
}

export function prepareVesselFilterToServer(filterData) {
  const _filterData = cloneDeep(filterData);
  if (_filterData.typeByPurpose) {
    _filterData.typeByPurpose = undefined;
    _filterData.typeByPurposeCheckbox = undefined;
  }
  return _filterData;
}
