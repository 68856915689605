/* eslint-disable global-require */
import { getInviteInfo, logout } from '../actions/login';
import history from '../core/history';

const home = require('./home').default;
const login = require('./login').default;
const register = require('./register').default;
import defaultPreviewImg from '../public/preview.png';

const defaultDescription = 'SHIPNEXT is an international Online Shipping Marketplace for dry-bulk and wet bulk, general and oversized cargo, steels and containers. It allows an instant search for suitable Cargo or Ships by sending a request in random form to fix@shipnext.com or on-line in just a few clicks.\n' +
  'SHIPNEXT includes Cargo and Ship matching, fully integrated range of industry data-bases, online trading and Contract Management tools.'
// The top-level (parent) route
export default {
  path: '/',

  // Keep in mind, routes are evaluated in order
  children: [
    home,
    require('./demo').default,
    require('./contact').default,
    login,
    register,
    require('./cargo').default,
    require('./cargo').requestList,
    require('./vessel').default,
    require('./fleetmonitor').default,
    require('./monitor').default,
    require('./trade').default,
    require('./settings').default,
    require('./contracts').default,
    require('./admin').default,
    require('./regions').default,
    require('./news').default,
    require('./my').default,
    require('./emails').default,
    require('./contacts').default,
    require('./market').default,

    // place new routes before...
    require('./content').default,
    require('./notFound').default,
  ],

  async action(context) {
    const { next, query, store } = context;
    let route = await processInvite(query, store);
    // Execute each child route until one of them return the result
    route = route || await next();

    // Provide default values for title, description etc.
    route.title = `${route.title || 'SHIPNEXT - Shipping Marketplace and Transportation Platform'}`;
    route.description = route.description || defaultDescription;
    route.ogImg = route.ogImg || `https://shipnext.com${defaultPreviewImg}`;
    return route;
  },
};


async function processInvite(query, store) {
  let route;
  try {
    if (query.strategy) {
      const state = store.getState();
      const invite = (await store.dispatch(getInviteInfo(query.strategy)));
      let user = state.login && state.login.user;
      if (user && user.email && invite.data && invite.data.email && invite.data.email.toLocaleLowerCase() !== user.email.toLocaleLowerCase()) {
        await store.dispatch(logout());
        user = null;
      }
      if (!user) {
        if (invite.data.isRegistered) {
          history.push && history.push('/login');
          route = login.children[0].action();
        } else {
          history.push && history.push('/register');
          route = register.action();
        }
      }
    }
  } catch (e) {
    console.error(e);
  }
  return route;
}
