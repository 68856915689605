import React, { Component } from 'react'
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import v from './VesselEdit.scss'
import s from './../Monitor.scss';
import RaisedButton from "../../Common/RaisedButton";
import cx from "classnames";
import { connect } from "react-redux";
import VesselImg from './VesselImg';
import Formsy from 'formsy-react';
import MenuItem from 'material-ui/MenuItem';
import { CustomCheckbox as _Checkbox } from '../../Common/CustomCheckbox';
import ContentAdd from 'material-ui/svg-icons/content/add';
import ContentClear from 'material-ui/svg-icons/content/clear';
import { TableRow, TableRowColumn} from 'material-ui/Table';
import formDataToObject from 'form-data-to-object';
import CalculatorInput from './../../Calculator/Input';
import NarrowFormsyText from './../../NewInputs/NarrowFormsyText';
import NarrowVesselAutocomplete from './../../NewInputs/NarrowVesselAutocomplete'
import NarrowSelect from './../../NewInputs/NarrowSelect'
import NarrowCountrySelect from './../../NewInputs/NarrowCountrySelect'
import NarrowPortAutocomplete from './../../NewInputs/NarrowPortAutocomplete'
import NarrowCompanyAutocomplete from './../../NewInputs/NarrowCompanyAutocomplete'
import NarrowAutocomplete from './../../NewInputs/NarrowAutocomplete'
import FormsyCheckbox from './../../Common/CheckBox';
import {withRows, WithUserUploads} from './../../../core/HOC'
import {scrollToAnchor} from './../../../core/utils';
import { removeFromArray } from './../../../core/utils';
import { uploadImage } from './../../../actions/login';
import { claimRights, addNewVessel, updateVesselById } from '../../../actions/vessel';
import Vessel, { piClub, builderYard, registerClass, vesselDesign } from '../../../core/api/Vessel';
import VesselPreviewDialog from '../../Vessel/Preview/VesselPreviewDialog';
import Collapse from './../../Common/Collapse';
import history from '../../../core/history';
import lodashIsEqual from 'lodash/isEqual';
import lodashCloneDeep from 'lodash/cloneDeep';
import ExtendedForm from '../../Common/ExtendedForm';
import FittedSelect from '../../Common/FittedSelect';
const hatchTypes = ['Combined', 'Folding', 'Mc Gregor', 'Pontoon', 'Rolling', ''];
const year = [];
const currentYear = +(new Date().getUTCFullYear());
for (let i = 1960; i <= currentYear; i++) {
  year.push(i);
}
import {typeByPurposeList, typeByConstructionList, specialties as _specialties, bunkersList, gearTypes, gearLabels, grabTypes, _iceClass} from './config'
import Attachments, {AddAttachment} from './Attachments';
import Exclusions from './Exclusions';
import {_SpeedConsumptions} from './SpeedConsumptions';
import PublicPrivateSpeedCons from './PublicPrivateSpeedCons';
import TextArea from './TextArea';
import LeavePageConfirmDialog from './LeavePageConfirmDialog';
import { mergeDisponentOwner } from '../../../reducers/monitor/helpers';
import { updateVesselsList } from './../../../actions/monitor';
import PubSub from 'pubsub-js';
import imoClasses from './imoClasses';
import { TypeByPurposeSelect } from '../Filter/TypeByPurposeSelect';
import { formatTypeByPurposePlural } from '../../../core/format';
import UploadedFileExtensionErr from "../../Common/UploadedFileExtensionErr";

const Input = (props) => <CalculatorInput {...props} locale={'en-US'} decimalSeparator="." separator="," />;

const specialties = {};

for (let key in _specialties) {
  specialties[key] = ["", ..._specialties[key]];
}

export const CheckBox = React.createClass({
  mixins: [
    {
      ...Formsy.Mixin
    }
  ],
  getInitialState() {
    return {
      _value: this.props.checked || this.props.value || false
    }
  },
  componentWillReceiveProps(nextProps) {
    if (nextProps.checked !== this.props.checked || nextProps.value !== this.props.value) {
      this.setState({
        _value: nextProps.checked || nextProps.value || false,
      })
    }
  },
  update(ev, val) {
    this.props.onCheck && this.props.onCheck(ev, val);
    this.props.onChange && this.props.onChange(ev, val);
    this.setState({
      _value: val
    })
  },
  render() {
   return <_Checkbox value={this.state._value} {...this.props} checked={this.state._value} onCheck={this.update} />
  }
})

export class VesselEdit extends Component {
    static defaultProps = {
      basePath: '/main-deck/general/',
      myfleet: false,
    }
    constructor(props) {
        super(props);
        this.MAX_SYMBOLS = 50000;
        this.state = {
            attachments: [],
            disponentOwner: "",
            shipImoError: false,
            hasPlugs: false,
            attachmentsKey: 0,
            specialtyList: [],
            typeBySpecialty: "",
            typeByPurpose: "",
            openVesselPreviewDialog: false
        }
        this.cancel = this.cancel.bind(this);
        this.collapses = {};
    }
    transformConsumptions = arr => {
        const newObj = {};
        if (!arr || !arr.length) return null;
        arr.forEach(({typeOfFuel, quantity}) => {
            newObj[typeOfFuel] = {
                typeOfFuel,
                quantity
            }
        })
        return newObj;
    }
    validateConsumption = obj => {
        return Object.values(obj).filter(c => c.typeOfFuel && c.quantity).map(c => ({...c, quantity: c?.quantity && Number(c.quantity.replace(/,/g, '.'))}));
    }
    componentDidMount() {
          this.prepareVessel(this.props);
          if (this.props.attachRef) {
            this.props.attachRef(this);
          }
    }

    componentWillReceiveProps(nextProps) {
      if ((nextProps.vessel?._id && nextProps.vessel._id !== this.props.vessel?._id) || this.props.edit !== nextProps.edit) {
        this.prepareVessel(nextProps)
      }
    }

    parseSpeedConsOnLoad = speedCons => {
      let defaultRows = _SpeedConsumptions.defaultRows || [];
      const rows = _SpeedConsumptions.parseRows(speedCons);
      if (defaultRows && defaultRows.length) {
        defaultRows = defaultRows.map(row => {
          const inRows = rows.findIndex(i => i.mode.trim() === row.mode);
          if (inRows >= 0) {
            const obj = {
              ...rows[inRows],
              id: row.id
            };
            rows.splice(inRows, 1);
            return obj;
          }
          return row;
        });
      }
      const parsed = [...defaultRows, ...rows].map(item => ({...item, id: item.id ? item.id : String(Math.random()).replace('.', '')}));
      return parsed
    }

    prepareVessel = async (props = this.props) => {

      let newState = {};
            // const currentVessel = this.props.vesselList.data.find(item => item._id === this.props.vesselId);
            /* if (!currentVessel) {
              return this.handleCancel();
            } */
            newState = props.vessel ? lodashCloneDeep(props.vessel) : {};
            if (newState.holds && newState.hatches) {
              if (newState.hatches.quantity) {
                newState.hatches.quantity = newState?.hatches?.quantity || undefined; /* newState.holds?.quantity
                 ? newState.hatches.quantity > newState.holds.quantity * 2
                  ? newState.holds.quantity * 2 : newState.hatches.quantity
                 : newState.hatches.quantity || undefined */
              }
              newState.holds.quantity = newState?.holds?.quantity <= 20 ? newState?.holds?.quantity || undefined : 20;
            }
            if (newState.reeferPlugs) {
                newState.hasPlugs = true;
            }
            if (newState.flag && newState.flag._id) {
              newState.flag = newState.flag._id;
            }
            if (newState.builderV2) {
              if (newState.builderV2.country) {
                newState.builderV2.country = newState.builderV2.country._id;
              }


            }
            if (newState.rt) {
              newState.rt.panamaNt = newState.rt.panamaNt || undefined;
              newState.rt.suezGt = newState.rt.suezGt || undefined;
              newState.rt.panamaGt = newState.rt?.panamaGt || undefined;
              newState.rt.suezNt = newState.rt.suezNt || undefined;
              newState.rt.nt = newState.rt.nt || undefined;
              newState.rt.gt = newState.rt.gt || undefined;
            }
            if (newState.gears) {
              newState.gears.maxSWL = newState.gears.maxSWL || undefined;
            }
            /* if (newState.consumptions) {
              newState.consumptions.tpc = newState.consumptions.tpc || undefined;
              newState.consumptions.tpi = newState.consumptions.tpi || undefined;
              newState.consumptions.windScale = newState.consumptions.windScale || 0;
              if (newState.consumptions.laden && !newState.consumptions.laden.splice) {
                newState.consumptions.laden = [];
              }
              if (newState.consumptions.ballast && !newState.consumptions.ballast.splice) {
                newState.consumptions.ballast = [];
              }
              if (newState.consumptions.eco && !newState.consumptions.eco.splice) {
                newState.consumptions.eco = [];
              }
              if (newState.consumptions.portIdle && !newState.consumptions.portIdle.splice) {
                newState.consumptions.portIdle = [];
              }
              if (newState.consumptions.portWorking && !newState.consumptions.portWorking.splice) {
                newState.consumptions.portWorking = [];
              }
              for (let key in newState.consumptions) {
                newState.consumptions[key] = this.transformConsumptions(newState.consumptions[key]) || newState.consumptions[key];
              }

            } */
          /*   if (newState.capacity) {
              newState.capacity.feu = newState.capacity.feu || undefined;
              newState.capacity.teu = newState.capacity.teu || undefined;
              newState.capacity.bale = newState.capacity.bale || undefined;
              newState.capacity.grain = newState.capacity.grain || undefined;
            } */
            if (newState.capacityV2) {
              newState.capacityV2.feu = newState.capacityV2?.feu || undefined;
              newState.capacityV2.teu = newState.capacityV2?.teu || undefined;
              newState.capacityV2.bale = newState.capacityV2?.bale || undefined;
              newState.capacityV2.grain = newState.capacityV2?.grain || undefined;
            }
            if (newState.speeds) {
              newState.speeds.eco = newState.speeds.eco || undefined;
              newState.speeds.ballast = newState.speeds.ballast || undefined;
              newState.speeds.laden = newState.speeds.laden || undefined;
            }
            if (newState.dimensions) {
              newState.dimensions.loa = newState.dimensions.loa || undefined;
              newState.dimensions.beam = newState.dimensions.beam || undefined;
              newState.dimensions.lbp = newState.dimensions.lbp || undefined;
              newState.dimensions.depth = newState.dimensions.depth || undefined;
            }
            if (newState.dw) {
              newState.dw.summer = newState.dw.summer || undefined;
              newState.dw.tropical = newState.dw.tropical || undefined;
              newState.dw.dwcc = newState.dw.dwcc || undefined;
            }
            if (newState.areas) {
              newState.areas.mainDeck = newState.areas.mainDeck || undefined;
              newState.areas.tweenDeck = newState.areas.tweenDeck || undefined;
              newState.areas.weatherDeck = newState.areas.weatherDeck || undefined;
              newState.areas.total = newState.areas.total || undefined;
            }
            if (newState.strength) {
              newState.strength.maxTt = newState.strength.maxTt || undefined;
              newState.strength.maxTween = newState.strength.maxTween || undefined;
              newState.strength.maxWeather = newState.strength.maxWeather || undefined;
            }
            if (newState.stackWeight) {
              newState.stackWeight['20hold'] = newState.stackWeight['20hold'] || undefined;
              newState.stackWeight['20deck'] = newState.stackWeight['20deck'] || undefined;
              newState.stackWeight['40hold'] = newState.stackWeight['40hold'] || undefined;
              newState.stackWeight['40deck'] = newState.stackWeight['40deck'] || undefined;
            }
            if (newState.holds) {
              if (newState.holds.lowerDims && newState.holds.lowerDims.length) {
                newState.holds.lowerDims.forEach((dim) => {
                  dim.l = dim.l || undefined;
                  dim.b = dim.b || undefined;
                  dim.h = dim.h || undefined;
                });
              }
              if (newState.holds.tweenDims && newState.holds.tweenDims.length) {
                newState.holds.tweenDims.forEach((dim) => {
                  dim.l = dim.l || undefined;
                  dim.b = dim.b || undefined;
                  dim.h = dim.h || undefined;
                });
              }
            }
            if (newState.hatches) {
              if (newState.hatches.hatchType === 'Mc Greegor') {
                newState.hatches.hatchType = 'Mc Gregor';
              }
              if (newState.hatches.size && newState.hatches.size.length) {
                newState.hatches.size.forEach((dim) => {
                  dim.l = dim.l || undefined;
                  dim.b = dim.b || undefined;
                });
              }
            }
            /* if (newState.bunkers) {
              newState.bunkers = newState.bunkers.filter(({typeOfFuel = ''}) => bunkersList.find(fuel => fuel.type === typeOfFuel.trim()));
            } */
            if (newState.typeByPurpose) {
                 newState.specialtyList = specialties[newState.typeByPurpose.toLowerCase().trim()]
            }
            if (!newState.cargoExclusionsV2) {
                newState.cargoExclusionsV2 = [];
            }
            if (!newState.tradingRestrictions) {
                newState.tradingRestrictions = [];
            }
            newState.cargoPreferences = newState.cargoPreferences || [];
            newState.areaPreferences = newState.areaPreferences || [];
            if (newState.geared) {
              newState.geared = newState.geared || false;
            }
            if (newState.grabbed) {
              newState.geared = true;
            }

            newState.owner = newState.owner || undefined;

            if (!props.edit && props?.user?.company) {
              newState.owner = props?.user?.company;
            }
            newState.draft = newState.draft || undefined;
            if (newState.speedConsumptionV2) {
              newState.speedConsumptionV2 = this.parseSpeedConsOnLoad(newState.speedConsumptionV2);
            }
            if (newState.speedConsumptionV2Private) {
              newState.speedConsumptionV2Private = this.parseSpeedConsOnLoad(newState.speedConsumptionV2Private);;
            }
            if (newState.techEquipCertificate?.referPlugsAmount === 0) {
              newState.techEquipCertificate.referPlugsAmount = '';
            }
            this.setState(newState, () => {
              this.refs.form.reset(formDataToObject.fromObj(newState));
              setTimeout(() => {
                if (this?.refs?.form) {
                  this.formModel = this.refs.form.getModel()
                }
              }, 100)
            });
    }

    filterEmptyObject = (fields = [], item) => {
      let empty = true;
      fields.forEach(field => {
        if (item[field]?.constructor === Object) {
          const keys = Object.keys(item[field]);
          if (Object.keys(item[field]).length !== 0 && keys.some(key => item[field][key])) {
            empty = false;
          }
        }
        else if (item[field]) {
          empty = false;
        }
      })
      return !empty
    }

    removeEmptyStrings = obj => {
      if (!obj || typeof obj !== 'object') return obj;
      obj = {...obj};
      for (let key in obj) {
        if (obj[key] && typeof obj[key] === 'object') obj[key] = this.removeEmptyStrings(obj[key]);
        if (!obj[key]) {
          delete obj[key];
        }
      }
      return obj;
    }

  removeOnlyEmptyStrings = obj => {
    if (!obj || typeof obj !== 'object') return obj;
    obj = {...obj};
    for (let key in obj) {
      if (obj[key] && typeof obj[key] === 'object') obj[key] = this.removeOnlyEmptyStrings(obj[key]);
      if (obj[key] === '') {
        delete obj[key];
      }
    }
    return obj;
  }

    filterSpeedConsumptions = item => {
      if (!item.mode) return false;
      const itemCopy = {...item};
      delete itemCopy.mode;
      const values = Object.values(itemCopy);
      if (values.length) {
        return values.some(val => val ? typeof val === 'object' ? Object.values(val).length : !!val : false)
      }
      else {
        return false;
      }
    }

    handleValidSubmit = (formData) => {
        if (this.state.disponentOwner && typeof this.state.disponentOwner === 'object') {
          formData.disponentOwner = this.state.disponentOwner;
        }
        else if (typeof formData.disponentOwner === 'string') {
          formData.disponentOwner = {
            name: formData.disponentOwner
          };
        }
        if (formData?.homePort?.length < 1) {
          delete formData.homePort;
        }
        if (formData?.techEquipCertificate) {
          if (formData?.techEquipCertificate?.referPlugsAmount === 0 ) {
            delete formData.techEquipCertificate.referPlugsAmount
          }
        }
        formData.images = this.state.images && this.state.images.map(image => image._id);
        if (formData.speedConsumptionV2) {
          const filtered = formData.speedConsumptionV2?.length ? this.removeEmptyStrings(formData.speedConsumptionV2) : [];
          formData.speedConsumptionV2 = Object.values(filtered).filter(this.filterSpeedConsumptions).map(item => {
            const ifo = item.ifo && item.ifo?.typeIfo ? {...item.ifo} : {cons: item.ifo?.cons};
            const mdo = item.mdo && item.mdo?.typeMdo ? {...item.mdo} : {cons: item.mdo?.cons};
            return {
              ...item,
              ifo,
              mdo
            }
          })
          if (formData?.hasOwnProperty('isPrivate')) {
            formData.isSpeedConsumptionV2Private = formData.isPrivate;
            delete formData.isPrivate;
          }
        }
        if (formData.capacityV2 && formData.capacityV2?.summary) {
          formData.capacityV2.summary = formData.capacityV2.summary.filter(this.filterEmptyObject.bind(this, ['tweenDeck', 'hold']))
            .map(item => ({tweenDeck: this.removeEmptyStrings(item.tweenDeck), hold: this.removeEmptyStrings(item.hold)}));
        }
        if (formData.cells && formData.cells.length) {
          formData.cells =formData.cells.filter(cell => {
            let holdsEmpty = this.filterEmptyObject(['l', 'b', 'h', 'area'], cell.holds);
            let hatches = cell.hatches.filter(this.filterEmptyObject.bind(this, ['l', 'b', 'area']));
            let tweenDecks = cell.tweenDecks.filter(this.filterEmptyObject.bind(this, ['l', 'b', 'h', 'area']));
            return !(!holdsEmpty && !hatches?.length && !tweenDecks?.length);
          })
          formData.cells = formData.cells.map(cell => ({holds: this.removeEmptyStrings(cell.holds), tweenDecks: cell.tweenDecks.map(tw => this.removeEmptyStrings(tw)), hatches: cell.hatches.map(hc => this.removeEmptyStrings(hc))}))
        }
        if (formData.bunkers && formData.bunkers.length) {
          formData.bunkers = formData.bunkers.filter(this.filterEmptyObject.bind(this, ['quantity']))
        }

        if (formData.deadWeight) {
          formData.deadWeight = this.removeEmptyStrings(formData.deadWeight);
        }

        if (formData.geared === true) {
          if (formData.gearV2 && formData.gearV2.summary) {
            formData.gearV2.summary = formData.gearV2.summary.filter(this.filterEmptyObject.bind(this, ['type']))
          }
          if (formData.grabV2 && formData.grabV2.summary) {
            formData.grabV2.summary = formData.grabV2.summary.filter(item => Object.values(item).filter(val => !!val).length > 0);
          }
        }
        else {
          formData.gearV2.summary = [];
          formData.grabV2.summary = [];
          if (formData.geared === undefined) {
            delete formData.geared;
          }
        }
        if (formData.builderV2) {
          formData.builderV2.yard = formData.builderV2.yard || {name: ""};
          formData.builderV2.country = formData.builderV2.country || "";
        }
        formData.attachments = this.state.attachments && this.state.attachments.map(image => image._id);
        formData.attachmentVisibility = this.state.attachments && this.state.attachments.map(({ visibility, _id }) => ({visibility,id:_id}));
        formData.cargoExclusionsV2 = this.exclusions.getCargoExclusions() || [];
        formData.tradingRestrictions = this.exclusions.getTradingRestrictions() || [];
        formData.cargoPreferences = this.preferences.getCargoExclusions() || [];
        formData.areaPreferences = this.preferences.getTradingRestrictions() || [];
      if ((!formData.registerClass || !formData.registerClass.name) && typeof formData.iacs === 'boolean') {
        formData.registerClass = { ...(formData.registerClass || {}), iacsRegister: formData.iacs };
      }
      formData.techEquipCertificate = this.removeOnlyEmptyStrings(formData.techEquipCertificate);

        const urlFallback = this.state._id ? `${this.props.basePath}${!this.props.myfleet ? '/-/' : ''}${this.state._id}` : `${this.props.basePath}`;
        if (this.props.edit) {
          this.props
            .updateVesselById(this.state._id, formData)
            .then((res) => {
              if (res.status === 200) {
                const newDisponentOwner = mergeDisponentOwner(formData.disponentOwner);
                this.props.updateVesselsList({
                  vesselId: res.data.vessel._id,
                  data: newDisponentOwner
                });
                PubSub.publish("vessel::success_edit", res.data?.vessel);
                if (this.props.onChangedVessel) {
                  this.props.onChangedVessel(res.data?.vessel);
                }
                if (this.props.closeEditingVessel) {
                  this.props.closeEditingVessel();
                  return;
                }
                history.safeBack(urlFallback);
              }
            });
        } else {
          this.props
            .addNewVessel(formData)
            .then((res) => {
              if (res.status === 200) {
                if (this.props.onChangedVessel) {
                  this.props.onChangedVessel(res.data?.vessel);
                  return;
                }
                if (this.props.closeEditingVessel) {
                  this.props.closeEditingVessel();
                  return;
                }
                history.safeBack(urlFallback)
              }
            });
        }
      };

       async cancel(ev, cb) {
        const dialog = this.leaveDialog;
        const formModel = (this.refs?.form && this.refs.form?.getModel()) || {};
        console.log('THIS', this)
        console.log('NEW MODEL', formModel, 'OLD MODEL', this.formModel);
        const {cargoExclusionsV2 = [], tradingRestrictions = [], cargoPreferences = [], areaPreferences = []} = this.props.vessel || {};
        const noChanges = lodashIsEqual(formModel, this.formModel);
        let exclChanged = false;
        let preferencesChanged = false;
        if (this.exclusions) {
          const cargoExclModel = this.exclusions.getCargoExclusions && this.exclusions.getCargoExclusions();
          const tradingRestrModel = this.exclusions.getTradingRestrictions && this.exclusions.getTradingRestrictions();
          if (cargoExclModel.some(excl => cargoExclusionsV2.findIndex(cExcl => cExcl?._id === excl?.id) < 0)
           || tradingRestrModel.some(excl => tradingRestrictions.findIndex(tExcl => tExcl?._id === excl?.id) < 0)) {
            exclChanged = true;
          }
          if (cargoExclModel?.length !== cargoExclusionsV2.length || tradingRestrModel?.length !== tradingRestrictions?.length) {
            exclChanged = true;
          }
        }
         if (this.preferences) {
           const cargoExclModel = this.preferences.getCargoExclusions && this.preferences.getCargoExclusions();
           const tradingRestrModel = this.preferences.getTradingRestrictions && this.preferences.getTradingRestrictions();
           if (cargoExclModel.some(excl => cargoPreferences.findIndex(cExcl => cExcl?._id === excl?.id) < 0)
             || tradingRestrModel.some(excl => areaPreferences.findIndex(tExcl => tExcl?._id === excl?.id) < 0)) {
             preferencesChanged = true;
           }
           if (cargoExclModel?.length !== cargoPreferences.length || tradingRestrModel?.length !== areaPreferences?.length) {
             preferencesChanged = true;
           }
         }
        if (noChanges && !exclChanged && !preferencesChanged) {
          if (cb) {
            cb();
            return true;
          }
          this.props.closeEditingVessel();
        }
        else if (dialog) {
          try {
            const result = await dialog.show();
            if (cb) {
              cb();
              return true;
            }
            this.props.closeEditingVessel();
          } catch (error) {
            console.log(error);
            return false;
          }
        }
    }
    componentWillUnmount() {
      if (this.props.attachRef) {
        this.props.attachRef(null);
      }
    }
    handleShipChange = (ship) => {
        console.log(this.refs.shipName, this.refs.shipImo);
        if (ship && ship._id) {
          this.refs.shipName.input.setValue(ship.name.replace(/ \([0-9]{7,7}\)$/, ''));
          this.refs.shipImo.refs.input.setValue(ship.imoNumber);
          this.setState({
            shipImoError: this.state._id !== ship._id,
            claimShip: ship,
          });
        }
    }
    toggleVesselPreviewDialog = () => {
        this.setState({ openVesselPreviewDialog: !this.state.openVesselPreviewDialog });
    };
    claimRights = () => {
        if (!this.state.claimShip) return;
        this.props.claimRights(this.state.claimShip).then((res) => {
          if (res.status === 200) {
            // this.props.closeEditingVessel();
            if (this.props.onChangedVessel) {
              this.props.onChangedVessel(res.data?.vessel ? res.data.vessel : this.state.claimShip);
            }
          }
        });
    };
    uploadImage = (e) => {
        const input = e.target;
        if (!input.files || !input.files[0]) return;
        const formData = new FormData();
        formData.append('file', e.target.files[0]);
        formData.append('type', 'image');
        this.props.uploadImage(formData).then((res) => {
          if (res.status === 200) {
            this.setState({ images: [res.data] });
          }
        });
    }
    onFilesUploaded = (validatedFiles, uploadedFiles) => {
        this.setState(state => ({ attachmentsKey: state.attachmentsKey + 1, attachments: [...state.attachments, ...(uploadedFiles.map( f => ({ ...f, canEdit: true,visibility:'private' })))] }),
         () => this.collapses.attachmentsCollapse && this.collapses.attachmentsCollapse.show());
    };
    handleAttachmentVisibility = (id) => {
      this.setState(prevState => {
        const newAttachments = prevState.attachments.map(attachment => 
          attachment._id === id 
            ? { ...attachment, visibility: attachment.visibility === 'private' ? 'public' : 'private' } 
            : attachment
        );
        return { attachments: newAttachments };
      });
    }
    handleRemove = (e, _id) => {
        const index = this.state.attachments.findIndex(at => at._id === _id);
        this.setState({ attachments: removeFromArray(this.state.attachments, index) });
        e.preventDefault();
        e.cancelBubble = true;
        e.stopPropagation();
    }
    addQuantity = () => {
        this.setState(state => ({
            hasPlugs: !state.hasPlugs,
        }));
    }
    purposeChanged = (ev, val) => {
        const oldPurpose = this.state.typeByPurpose;
        this.setState( state => ({
            typeByPurpose: val ? val : state.typeByPurpose,
            specialtyList: val ? specialties[val.toLowerCase().trim()] : []
        }));
        if (String(oldPurpose).toLowerCase().trim() !== String(val).toLowerCase().trim()) {
          this.setState({
            typeBySpecialty: ""
          })
        }
    }

    specialtyChanged = (ev, val) => this.setState({
      typeBySpecialty: val
    });

    isLinerMeterAllowed = () => {
      const allowedPurpose = ["MPP", "PASSCAR", "REEFER"];

      return !!(allowedPurpose.includes(this.state.typeByPurpose) && this.state.typeBySpecialty && /Ro-[RL]o/.test(String(this.state.typeBySpecialty)))
    }

    checkShipImo = (e) => {
        if (!e.target.value || e.target.value.trim().length !== 7) {
            if (this.state.shipImoError) {
              this.setState({
                shipImoError: false,
                claimShip: undefined,
              });
            }
            return;
          }
          Vessel
            .getVesselByIMO({ query: e.target.value.trim() })
            .then((response) => {
              this.setState({
                shipImoError: response.data.length > 0 && this.state._id !== response.data[0]._id,
                claimShip: response.data[0],
              });
            });
    }

    openCollapsesWithErrors = () => {
      for (let ref in this.collapses) {
        if (this.collapses[ref] && this.collapses[ref].findInvalidInputs) {
          this.collapses[ref].findInvalidInputs();
        }
      }
    }

    invalidSubmit = (model) => {
        const invalid = document.querySelector(`.${v.content} input[data-valid=false]`) || document.querySelector(`.${v.content} .error`) || document.querySelector(`.${v.content} .with_error`);
        this.openCollapsesWithErrors();
        if (invalid) {
            scrollToAnchor(invalid);
        }
    };
    handleSave = () => {
        this.refs.form.submit();
    }
    handleRegisterClassChange = val => {
        this.setState({
          registerClass: val,
        });
      if (val._id) {
        this.refs.form.inputs.find(i => i.props.name === 'iacs').setValue(val.iacsRegister);
      }

    }
    render() {
        const isOwner = this.state.isUserOwner || !this.props.edit;
        const {attachments, hasPlugs, shipImoError} = this.state;
        const {edit} = this.props;
        const {MAX_SYMBOLS} = this;
        const canEditLinerMeter = this.isLinerMeterAllowed();
        return (
            <div className={s.cargo_details}>
                <div className={s.cargo_details_header}>
                    <h4 className={s.title}>{edit ? 'Edit Vessel' : 'Create Vessel'}</h4>
                    <div className={cx(s.cargo_details_buttons, s.flex_end)}>
                        <RaisedButton
                            label="cancel"
                            disableTouchRipple={true}
                            disableFocusRipple={true}
                            style={{
                                marginRight: '8px',
                                borderRadius: '15px',
                                overflow: 'hidden'
                            }}
                            //onClick={this.cancel}
                            buttonStyle={{
                                borderRadius: '15px',
                            }}
                            secondary={true}
                            onTouchTap = {this.cancel}
                        />
                        <RaisedButton
                            label={"SAVE"}
                            disableTouchRipple={true}
                            disableFocusRipple={true}
                            primary={true}
                            disabled={shipImoError}
                            type="submit"
                            onClick={this.handleSave}
                            style={{
                                borderRadius: '15px',
                            }}
                            buttonStyle={{
                                borderRadius: '15px',
                            }}
                        />
                    </div>
                </div>
                <div className={v.content}>
                    <ExtendedForm
                        ref="form"
                        noValidate
                        onValidSubmit={this.handleValidSubmit}
                        onInvalidSubmit={this.invalidSubmit}
                    >
                        <div className={v.image_wrapper}>
                            <VesselImg uploadImage={this.uploadImage} images={this.state.images}></VesselImg>
                            <div className={v.right_inputs}>
                                <div className={v.big_small}>
                                    {this.state._id ?
                                        <NarrowFormsyText
                                            classes={v.big}
                                            floatingLabelText="Name*"
                                            validations="minLength:2,maxLength:100"
                                            validationError="2 - 100 characters"
                                            requiredError="required"
                                            name="name"
                                            required
                                            autoWidth
                                        />
                                        :
                                        <NarrowVesselAutocomplete
                                            classes={v.big}
                                            floatingLabelText="Name*"
                                            validations="minLength:2,maxLength:100"
                                            validationErrors={{
                                            isDefaultRequiredValue: 'required',
                                            }}
                                            validationError="2 - 100 characters"
                                            requiredError="required"
                                            name="name"
                                            required
                                            autoWidth
                                            onNewRequest={this.handleShipChange}
                                            ref="shipName"
                                        />
                                    }
                                    <div className={shipImoError && v.small} style={{flexWrap:shipImoError ? 'wrap' : ''}}>

                                        <NarrowFormsyText
                                            fullWidth
                                            floatingLabelText="IMO*"
                                            validations="isLength:7,isInt"
                                            validationErrors={{
                                                isInt: 'Only digits',
                                                isLength: 'Must be 7 digits',
                                            }}
                                            requiredError="required"
                                            name="imoNumber"
                                            required
                                            disabled={this.props.edit}
                                            onChange={this.checkShipImo}
                                            ref="shipImo"
                                        />

                                    </div>
                                </div>
                                    {
                                        shipImoError ? (
                                            <div className={v.ship_imo_error}>
                                                <p>
                                                     A ship with such number already exists in the database
                                                </p>
                                                <div className={v.claim_rights}>
                                                    <RaisedButton
                                                        label="Ship's Description"
                                                        backgroundColor="#4380C7"
                                                        labelColor="#fff"
                                                        onClick={this.toggleVesselPreviewDialog}
                                                        style={{
                                                            marginRight: '8px',
                                                            borderRadius: '15px',
                                                        }}
                                                        buttonStyle={{
                                                            borderRadius: '15px',
                                                        }}
                                                    />
                                                    <RaisedButton
                                                        label="Claim Rights"
                                                        backgroundColor="#4380C7"
                                                        labelColor="#fff"
                                                        onClick={this.claimRights}
                                                        style={{
                                                            marginRight: '8px',
                                                            borderRadius: '15px',
                                                        }}
                                                        buttonStyle={{
                                                            borderRadius: '15px',
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            ) : null
                                    }

                                <div className={cx(v.big_small)}>
                                    <NarrowCountrySelect
                                        className={cx(v.small, v.select)}
                                        hintText="Flag*"
                                        floatingLabelText="Flag*"
                                        requiredError="required"
                                        name="flag"
                                        required
                                        autoWidth
                                        maxHeight={271}
                                    />
                                    <NarrowSelect
                                        className={cx(v.big, v.select)}
                                        hintText="Year*"
                                        floatingLabelText="Year*"
                                        requiredError="required"
                                        name="blt"
                                        required
                                        maxHeight={271}
                                        selectedMenuItemStyle={{
                                        color: 'rgba(#000000, .87)',
                                        }}
                                    >
                                        {
                                        year.map((item, i) => (
                                            <MenuItem
                                            innerDivStyle={{
                                                fontSize: '14px',
                                                color: 'rgba(#000000, .87)',
                                            }}
                                            key={i}
                                            value={item}
                                            primaryText={item}
                                            />
                                        ))

                                        }
                                    </NarrowSelect>


                                </div>
                                <NarrowAutocomplete
                                    floatingLabelText="Builder yard"

                                    name="builderV2[yard]"
                                    fullWidth
                                    api={builderYard}
                                />
                                <div className={cx(v.equal)}>
                                    <NarrowCountrySelect
                                        className={v.select}
                                        hintText="Builder Country"
                                        floatingLabelText="Builder Country"
                                        name="builderV2[country]"
                                        autoWidth
                                        maxHeight={271}
                                    />
                                    <div>
                                        <NarrowPortAutocomplete
                                            floatingLabelText="Port of Registry"
                                            name="homePort"
                                            forceSelection={false}
                                            validations="isObj"
                                            validationError="Please, select port of registry"
                                            validationErrors={{
                                              isObj: 'Please, select port of registry'
                                            }}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className={v.full_width}>
                            <div className={v.row} style={{position: 'relative', zIndex: 99}}>
                                    <div className={v.fixed_field}>
                                      <NarrowCompanyAutocomplete
                                          floatingLabelText="Ship Owner"
                                          name="owner"
                                          fullWidth
                                          validations="isObj"
                                          validationError="Please, select ship owner"
                                          validationErrors={{
                                            isObj: 'Please, select ship owner'
                                          }}
                                          disabled
                                          value={this.props?.user?.company}
                                      ></NarrowCompanyAutocomplete>
                                    </div>
                                    <NarrowCompanyAutocomplete
                                        floatingLabelText="Ship Manager"
                                        name="manager"
                                        fullWidth
                                        validations="isObj"
                                        validationError="Please, select ship manager"
                                        validationErrors={{
                                          isObj: 'Please, select ship manager'
                                        }}
                                        errorStyle={{ bottom: "9px", fontSize: "11px" }}
                                    ></NarrowCompanyAutocomplete>
                                    <NarrowCompanyAutocomplete
                                        floatingLabelText="Disponent Owner"
                                        name="disponentOwner"
                                        fullWidth
                                        onNewRequest={val => this.setState({disponentOwner: val})}
                                    ></NarrowCompanyAutocomplete>
                            </div>
                            <div className={cx(v.row, v.equal)} style={{position: 'relative', zIndex: 99}}>
                                <div className={v.fixed_field}>
                                  <NarrowSelect
                                      className={v.select}
                                      floatingLabelText="Type By Purpose*"
                                      requiredError="required"
                                      name="typeByPurpose"
                                      required
                                      value={this.state.typeByPurpose}
                                      maxHeight={271}
                                      onChange={this.purposeChanged}
                                      autoWidth
                                      >
                                      {
                                          typeByPurposeList.map((item, i) => (
                                            <MenuItem key={i} value={item} primaryText={formatTypeByPurposePlural(item,1)} />
                                          ))

                                      }
                                  </NarrowSelect>
                                </div>
                                <NarrowSelect
                                    className={v.select}
                                    floatingLabelText="Type By Specialty"
                                    name="typeBySpecialty"
                                    autoWidth
                                    maxHeight={271}
                                    value={this.state.typeBySpecialty}
                                    onChange={this.specialtyChanged}
                                    disabled={!this.state.typeByPurpose}
                                    >
                                    {
                                        this.state.specialtyList.map((item, i) => (
                                          <MenuItem key={i} value={item} primaryText={item} />
                                        ))

                                    }
                                </NarrowSelect>
                                <NarrowSelect
                                    className={v.select}
                                    floatingLabelText="Type By Construction"
                                    name="typeByConstruction"
                                    maxHeight={271}
                                    >
                                    {
                                        typeByConstructionList.map((item, i) => (
                                          <MenuItem key={i} value={item} primaryText={item} />
                                        ))

                                    }
                                    </NarrowSelect>
                            </div>
                            <div className={v.row}>
                              <div className={v.short_field}>
                                <NarrowFormsyText
                                    floatingLabelText="Lane meter, m"
                                    name={`linerMeter`}
                                    key={canEditLinerMeter}
                                    validations="isNumeric,min:0,max:10000"
                                    validationErrors={{
                                      isNumeric: 'only numbers',
                                      min: '0 - 10000',
                                      max: '0 - 10000',
                                    }}
                                    disabled={!canEditLinerMeter}
                                    fullWidth
                                />
                              </div>

                              <div className={v.short_field}>
                                  <NarrowSelect
                                          floatingLabelText={'Engine location'}
                                          name={'engineLocation'}
                                          defaultValue={''}
                                          fullWidth
                                  >
                                      <MenuItem key={1} value={'aft'} primaryText={'aft'} />
                                      <MenuItem key={2} value={'mid'} primaryText={'mid'} />
                                      <MenuItem key={3} value={'fore'} primaryText={'fore'} />
                                      <MenuItem key={0} value={''} primaryText={''} />
                                  </NarrowSelect>
                              </div>
                              <NarrowAutocomplete
                                  floatingLabelText="P&I Club"
                                  name="piClub"
                                  validations="isObj"
                                  validationError="Please, select p & i club"
                                  validationErrors={{
                                    isObj: "Please, select p & i club"
                                  }}
                                  smallFont={true}
                                  maxHeight={271}
                                  api={piClub}
                                  fullWidth
                              />
                            </div>
                            <div className={cx(v.row)}>
                              <div className={cx(v.short_field, 'flex', 'align-items-center')}>
                               <FormsyCheckbox labelStyle={{ fontSize: '12px', color: 'var(--text-light)' }} name={'iacs'} label={'IACS\u00A0register'} disabled={!!this.state?.registerClass?.name} />
                              </div>
                              <NarrowAutocomplete
                                    floatingLabelText="Register Class (Class)"
                                    name="registerClass"
                                    validations="isObj"
                                    validationError="Please, select register class"
                                    validationErrors={{
                                      isObj: 'Please, select register class'
                                    }}
                                    onNewRequest={this.handleRegisterClassChange}
                                    smallFont={true}
                                    maxHeight={271}
                                    api={registerClass}
                                    fullWidth
                              />
                            </div>
                            <div className={cx(v.row)} style={{paddingBottom: '8px'}}>
                                  <div className={v.short_field}>
                                    <FittedSelect
                                        floatingLabelText="Open Hatch"
                                        name={'openHatch'}
                                    />
                                  </div>
                                  <div className={v.short_field}>
                                    <FittedSelect
                                        floatingLabelText="Box Shaped"
                                        name={'boxShaped'}
                                    />
                                  </div>
                                <div style={{flex: '1'}}>
                                  <NarrowAutocomplete
                                      floatingLabelText="Design"
                                      name="design"
                                      fullWidth
                                      api={vesselDesign}
                                      smallFont={true}
                                      maxHeight={271}
                                  />
                                </div>
                            </div>
                            <Collapse innerRef={el => this.collapses.tonnageCollapse = el} defaultOpen={false} titleClass={v.collapse_title} title="TONNAGE, REG TONS">
                              <Tonnage formSubmitted={this.refs?.form?.state?._formSubmitted}></Tonnage>
                            </Collapse>
                            <Collapse innerRef={el => this.collapses.capacityCollapse = el} defaultOpen={false} titleClass={v.collapse_title} title="CAPACITY">
                              <Capacity rows={this.state?.capacityV2?.summary} />
                            </Collapse>
                            <Collapse innerRef={el => this.collapses.dimensionsCollapse = el} defaultOpen={false} titleClass={v.collapse_title} title="DIMENSIONS, M">
                              <Dimensions />
                            </Collapse>
                            <Collapse innerRef={el => this.collapses.hohaCollapse = el} defaultOpen={false} titleClass={v.collapse_title} title="HO/HA">
                              <HoHa holds={this.state?.holds?.quantity} hatches={this.state?.hatches?.quantity} rows={this.state?.cells} />
                            </Collapse>
                            <Collapse innerRef={el => this.collapses.speedConsCollapse = el} defaultOpen={false} titleClass={v.collapse_title} title="SPEED & CONSUMPTION">
                              {/* <SpeedConsumptions rows={this.state?.speedConsumptionV2} /> */}
                              <PublicPrivateSpeedCons
                                withHiddenButtons={false}
                                isVesselOwnerInSameCompany={this.state?.isVesselOwnerInSameCompany}
                                publicCons={this.state?.speedConsumptionV2}
                                privateCons={this.state?.speedConsumptionV2Private}
                                attachRef={el => this.consumptions = el}
                                companyId={this.props.user?.company?._id}
                                isCreatingVessel={!this.props.edit}
                              ></PublicPrivateSpeedCons>
                              <div className={v.row}>
                                  <div className={v.label_select}>
                                      <p>Maximum Force on the Beaufort wind scale</p>
                                      <NarrowSelect
                                              name={'windScale'}
                                              autoWidth
                                              style={{ marginLeft: '15px'}}
                                      >
                                          <MenuItem value={0} primaryText={'0 (0—0,2 m/s)'} />
                                          <MenuItem value={1} primaryText={'1 (0,3—1,5 m/s)'} />
                                          <MenuItem value={2} primaryText={'2 (1,6—3,3 m/s)'} />
                                          <MenuItem value={3} primaryText={'3 (3,4—5,4 m/s)'} />
                                          <MenuItem value={4} primaryText={'4 (5,5—7,9 m/s)'} />
                                          <MenuItem value={5} primaryText={'5 (8,0—10,7 m/s)'} />
                                          <MenuItem value={6} primaryText={'6 (10,8—13,8 m/s)'} />
                                          <MenuItem value={7} primaryText={'7 (13,9—17,1 m/s)'} />
                                          <MenuItem value={8} primaryText={'8 (17,2—20,7 m/s)'} />
                                          <MenuItem value={9} primaryText={'9 (20,8—24,4 m/s)'} />
                                          <MenuItem value={10} primaryText={'10 (24,5—28,4 m/s)'} />
                                          <MenuItem value={11} primaryText={'11 (28,5—32,6 m/s)'} />
                                          <MenuItem value={12} primaryText={'12 (32,7 and more m/s)'} />
                                      </NarrowSelect>
                                  </div>
                              </div>
                            </Collapse>
                            <Collapse innerRef={el => this.collapses.bunkersCollapse = el} defaultOpen={false} titleClass={v.collapse_title} title="BUNKERS">
                              <Bunkers rows={this.state?.bunkers} />
                            </Collapse>
                            <Collapse innerRef={el => this.collapses.permLoadsCollapse = el} defaultOpen={false} titleClass={v.collapse_title} title="PERMISSIBLE LOADS">
                               <PermissibleLoads />
                            </Collapse>
                            <GearsGrabs ref={el => this.collapses.gearsgrabs = el} gears={this.state?.gearV2?.summary} grabs={this.state?.grabV2?.summary} grabbed={this.state?.grabbed} isGeared={this.state?.geared} />
                            <Collapse innerRef={el => this.collapses.exclusionsCollapse = el} defaultOpen={false} titleClass={v.collapse_title} title="EXCLUSIONS">
                              <Exclusions attachAsRef={instance => this.exclusions = instance} userRole={this.props.user.role} isVesselOwner={isOwner} cargoExclusions={this.state.cargoExclusionsV2} tradingRestrictions={this.state.tradingRestrictions}></Exclusions>
                            </Collapse>
                            <Collapse innerRef={el => this.collapses.preferencesCollapse = el} defaultOpen={false} titleClass={v.collapse_title} title="PREFERENCES">
                              <Exclusions
                                attachAsRef={instance => this.preferences = instance}
                                userRole={this.props.user.role}
                                isVesselOwner={isOwner}
                                cargoExclusions={this.state.cargoPreferences}
                                tradingRestrictions={this.state.areaPreferences}
                                cargosFieldName={"cargoPreferences"}
                                cargosFieldLabel={"Cargo"}
                                areasFieldName={"areaPreferences"}
                                areasFieldLabel={"Trading Area"}
                              >
                              </Exclusions>
                            </Collapse>
                            <Collapse innerRef={el => this.collapses.techEquipCollapse = el} defaultOpen={false} titleClass={v.collapse_title} title="TECHNICAL EQUIPMENT / CERTIFICATE">
                              <TechEquipment
                                referPlugFitted={this.state?.techEquipCertificate?.referPlugFitted}
                                quantity={this.state?.techEquipCertificate?.referPlugsAmount}
                                imoApp={this.state?.techEquipCertificate?.imoApp}
                                imoClass={this.state?.techEquipCertificate?.imoClass}
                                imoFitted={this.state?.techEquipCertificate?.imoFitted}
                                ventilationType={this.state?.techEquipCertificate?.ventilation}
                                ventilationChecked={this.state?.techEquipCertificate?.ventilationFitted} />
                            </Collapse>

                            <Collapse innerRef={el => this.collapses.descriptionCollapse = el} defaultOpen={false} titleClass={v.collapse_title} title="DESCRIPTION">
                              <div style={{marginTop: '2px'}} className={cx(v.section, v.description)}>
                                <div className={v.row}>
                                    <TextArea
                                      validations={`minLength:1,maxLength:${MAX_SYMBOLS}`}
                                      validationError={`1 - ${MAX_SYMBOLS} characters`}
                                      value={this.state?.description}
                                      name="description"
                                      style={{ borderRadius: "8px" }}
                                    />
                                </div>
                              </div>
                            </Collapse>
                            <div className={cx(v.section, v.border)}>
                              <WithUserUploads key={this.state.attachmentsKey} shouldUpload onSuccess={this.onFilesUploaded} maxFiles={Number.MAX_VALUE} privateAttachment={true}>
                                {({ erroredFiles, validateUserUploads, uploadedFiles, accept, uploading }) => {
                                  return (<Collapse innerRef={el => this.collapses.attachmentsCollapse = el} defaultOpen={false} titleClass={v.collapse_title} title="ATTACHMENTS" additionals={(
                                    <AddAttachment key={this.state.attachmentsKey} success={validateUserUploads} />
                                  )}>
                                    <div className={v.row}>
                                      <div className={v.attachments}>
                                        <Attachments isUserOwner={this.props.edit ? this.state?.isUserOwner : true} attachments={this.state?.attachments} handleAttachmentVisibility={this.handleAttachmentVisibility} handleRemove={this.handleRemove}></Attachments>
                                      </div>
                                      <div>{erroredFiles.length ? <UploadedFileExtensionErr className={'upload_error'} accept={accept} erroredFiles={erroredFiles} /> : null}</div>
                                    </div>
                                  </Collapse>);
                                  }}
                              </WithUserUploads>

                            </div>
                        </div>
                        <LeavePageConfirmDialog attachRef={instance => this.leaveDialog = instance}>Are you sure you want to leave without saving?</LeavePageConfirmDialog>
                        {this.state.openVesselPreviewDialog && this.state.claimShip ?
                            <VesselPreviewDialog
                                handleClose={this.toggleVesselPreviewDialog}
                                vessel={{ vessel: this.state.claimShip._id }}
                            />
                        : null}
                    </ExtendedForm>
                </div>
            </div>
        )
    }
}


function ImoClassSelect(props: { prefix: any, value: string | any, onChange: any }) {
  return (
    <NarrowSelect
      floatingLabelText={'Imo class'}
      name={`${props.prefix}[imoClass]`}
      fullWidth
      value={props.value}
      onChange={props.onChange}
    >
      {
        imoClasses.map(option => <MenuItem value={option} primaryText={option} />)
      }
    </NarrowSelect>
  );
}

function ImoAppSelect(props: { prefix: any, value: string | any, onChange: any }) {
  return <NarrowSelect
    floatingLabelText={'Imo app'}
    name={`${props.prefix}[imoApp]`}
    fullWidth
    value={props.value}
    onChange={props.onChange}
  >
    <MenuItem value="Appendix a" primaryText="Appendix a"/>
    <MenuItem value="Appendix b" primaryText="Appendix b"/>
    <MenuItem value="Appendix c" primaryText="Appendix c"/>
    <MenuItem value="Appendix a, b" primaryText="Appendix a, b"/>
    <MenuItem value="Appendix a, c" primaryText="Appendix a, c"/>
    <MenuItem value="Appendix b, c" primaryText="Appendix b, c"/>
    <MenuItem value="Appendix a, b, c" primaryText="Appendix a, b, c"/>
    <MenuItem value={""} primaryText=""/>
  </NarrowSelect>;
}

class TechEquipment extends Component {

  static defaultProps = {
    handleChange: () => undefined,
    prefix: 'techEquipCertificate',
    withoutIceClass: false
  }

  constructor(props) {
    super(props);
    this.state = {
      ventilationType: props.ventilationType || '',
      ventilationCheckbox: (props.ventilationType ? true : (props.ventilationChecked ?? null)),
      imoApp: props.imoApp || '',
      imoClass: props.imoClass || '',
      imoFitted: (props.imoApp || props.imoClass ? true : (props.imoFitted ?? null)),
      referPlugFitted: (props.quantity ? true : (props.referPlugFitted ?? null)),
      quantity: props.quantity || '',
      co2Fitted: props.co2Fitted ?? null,
      a60BulkheadFitted: props.a60BulkheadFitted ?? null,
      cementHolesFitted: props.cementHolesFitted ?? null,
      itfFitted: props.itfFitted ?? null,
      logsFitted: props.logsFitted ?? null,
      strengthenHeavy: props.strengthenHeavy ?? null,
      lakesFitted: props.lakesFitted ?? null,
      aussieFitted: props.aussieFitted ?? null,
      scrubberFitted: props.scrubberFitted ?? null,
      iceClass: props.iceClass ?? '',
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.co2Fitted !== this.props.co2Fitted) {
      this.setState({
        co2Fitted: nextProps.co2Fitted
      })
    }
    if (nextProps.a60BulkheadFitted !== this.props.a60BulkheadFitted) {
      this.setState({
        a60BulkheadFitted: nextProps.a60BulkheadFitted
      })
    }
    if (nextProps.cementHolesFitted !== this.props.cementHolesFitted) {
      this.setState({
        cementHolesFitted: nextProps.cementHolesFitted
      })
    }
    if (nextProps.itfFitted !== this.props.itfFitted) {
      this.setState({
        itfFitted: nextProps.itfFitted
      })
    }
    if (nextProps.logsFitted !== this.props.logsFitted) {
      this.setState({
        logsFitted: nextProps.logsFitted
      })
    }
    if (nextProps.strengthenHeavy !== this.props.strengthenHeavy) {
      this.setState({
        strengthenHeavy: nextProps.strengthenHeavy
      })
    }
    if (nextProps.lakesFitted !== this.props.lakesFitted) {
      this.setState({
        lakesFitted: nextProps.lakesFitted
      })
    }
    if (nextProps.aussieFitted !== this.props.aussieFitted) {
      this.setState({
        aussieFitted: nextProps.aussieFitted
      })
    }
    if (nextProps.referPlugFitted !== this.props.referPlugFitted) {
      this.setState({
        referPlugFitted: nextProps.quantity ? true : nextProps.referPlugFitted
      })
    }
    if (nextProps.quantity !== this.props.quantity) {
      this.setState({
        quantity: nextProps.quantity
      })
    }
    if (nextProps.ventilationType !== this.props.ventilationType) {
      this.setState({
        ventilationType: nextProps.ventilationType,
      })
    }
    if (nextProps.ventilationCheckbox !== this.props.ventilationCheckbox) {
      this.setState({
        ventilationCheckbox: nextProps.ventilationType ? true : nextProps.ventilationChecked,
      })
    }
    if (nextProps.imoApp !== this.props.imoApp) {
      this.setState({
        imoApp: nextProps.imoApp,
      })
    }
    if (nextProps.imoClass !== this.props.imoClass) {
      this.setState({
        imoClass: nextProps.imoClass,
      })
    }
    if (nextProps.imoFitted !== this.props.imoFitted) {
      this.setState({
        imoFitted: nextProps.imoApp || nextProps.imoClass ? true : nextProps.imoFitted,
      })
    }
    if (nextProps.iceClass !== this.props.iceClass) {
      this.setState({
        iceClass: nextProps.iceClass
      })
    }
    if (nextProps.scrubberFitted !== this.props.scrubberFitted) {
      this.setState({
        scrubberFitted: nextProps.scrubberFitted
      })
    }

  }

  beforeChange = () => {
    const obj = {
      ...this.state
    }
    if (this.props.withoutIceClass) {
      delete obj.iceClass
    }

    this.props.handleChange(obj);
  }

  handleField = (fieldName = '', dependantField = "", ev, value) => {
    this.setState(state => ({
      [fieldName]: value,
      [dependantField]: value ? state[dependantField] : ""
    }),this.beforeChange);
  }

  handleObservableField = (field = '', dependantField = '', ev, value) => {
    this.setState( state => ({
      [field]: value,
      [dependantField]: value ? true : state[dependantField]
    }),this.beforeChange)
  }

  render() {
    const {prefix, tcOrder = false} = this.props;
    const {co2Fitted,
      a60BulkheadFitted,
      cementHolesFitted,
      itfFitted,
      logsFitted,
      strengthenHeavy,
      lakesFitted,
      aussieFitted, scrubberFitted} = this.state;
    return (
      <div className={cx(v.section, v.tech_equipment)}>
        <div className={v.row}>
          <div>
            <FittedSelect
              value={this.state.ventilationCheckbox}
              onChange={this.handleField.bind(this, "ventilationCheckbox", "ventilationType")}
              name={`${prefix}[ventilationFitted]`}
              floatingLabelText={'Ventilation'}
            />
          </div>
          <div>
            <NarrowSelect
              floatingLabelText={'Ventilation type'}
              name={`${prefix}[ventilation]`}
              value={this.state.ventilationType}
              fullWidth
              onChange={this.handleObservableField.bind(this, 'ventilationType', 'ventilationCheckbox')}
            >
              <MenuItem value="Electrical" primaryText="Electrical"/>
              <MenuItem value="Natural" primaryText="Natural"/>
              <MenuItem value={null} primaryText=""/>
            </NarrowSelect>
          </div>
          <div>
            <FittedSelect
              name={`${prefix}[referPlugFitted]`}
              floatingLabelText={"Reefer plug"}
              onChange={this.handleField.bind(this, "referPlugFitted", "quantity")}
              value={this.state.referPlugFitted}
            />
          </div>
          <div>
            <NarrowFormsyText
              floatingLabelText="Quantity"
              name={`${prefix}[referPlugsAmount]`}
              validations="isNumeric,isInt,min:0,max:1000"
              validationErrors={{
                isInt: 'only integer',
                isNumeric: 'only numbers',
                min: '0 - 1000',
                max: '0 - 1000',
              }}
              value={this.state.quantity}
              fullWidth
              onBlur={this.handleObservableField.bind(this, 'quantity', 'referPlugFitted')}
            />
          </div>
        </div>
        <div className={v.row}>
          <div>
            <FittedSelect
              value={this.state.imoFitted}
              onChange={(ev, val) => {
                this.handleField("imoFitted", "imoApp", ev, val);
                this.handleField("imoFitted", "imoClass", ev, val);
              }}
              name={`${prefix}[imoFitted]`}
              floatingLabelText={'Imo fitted'}
            />
          </div>
          <div>
            <ImoAppSelect
              prefix={prefix}
              value={this.state.imoApp}
              onChange={this.handleObservableField.bind(this, 'imoApp', 'imoFitted')}/>
          </div>
          <div>
            <ImoClassSelect
              prefix={prefix}
              value={this.state.imoClass}
              onChange={this.handleObservableField.bind(this, 'imoClass', 'imoFitted')}
            />
          </div>
          <div>
            <FittedSelect
              name={`${prefix}[scrubberFitted]`}
              floatingLabelText={'Scrubber'}
              value={scrubberFitted}
              onChange={this.handleField.bind(this, 'scrubberFitted', null)}
            />
          </div>
        </div>
        <div className={v.row}>
          <div>
            <FittedSelect
              name={`${prefix}[co2Fitted]`}
              floatingLabelText={'CO2'}
              value={co2Fitted}
              onChange={this.handleField.bind(this, 'co2Fitted', null)}
            />
          </div>
          <div>
            <FittedSelect
              name={`${prefix}[a60BulkheadFitted]`}
              value={a60BulkheadFitted}
              floatingLabelText={'A60 bulkhead'}
              onChange={this.handleField.bind(this, 'a60BulkheadFitted', null)}
            />
          </div>
          <div>
            <FittedSelect
              name={`${prefix}[itfFitted]`}
              floatingLabelText={'Itf'}
              value={itfFitted}
              onChange={this.handleField.bind(this, 'itfFitted', null)}
            />
          </div>
          <div>
            <FittedSelect
              name={`${prefix}[cementHolesFitted]`}
              floatingLabelText={'Cement holes'}
              value={cementHolesFitted}
              onChange={this.handleField.bind(this, 'cementHolesFitted', null)}
            />
          </div>
        </div>
        <div className={v.row}>
          <div>
            <FittedSelect
              name={`${prefix}[lakesFitted]`}
              floatingLabelText={'Lakes'}
              value={lakesFitted}
              onChange={this.handleField.bind(this, 'lakesFitted', null)}
            />
          </div>
          <div>
            <FittedSelect
              name={`${prefix}[logsFitted]`}
              floatingLabelText={'Logs'}
              value={logsFitted}
              onChange={this.handleField.bind(this, 'logsFitted', null)}
            />
          </div>
          <div>
            <FittedSelect
              name={`${prefix}[aussieFitted]`}
              floatingLabelText={'Aussie'}
              value={aussieFitted}
              onChange={this.handleField.bind(this, 'aussieFitted', null)}
            />
          </div>
          <div>
            <FittedSelect
              name={`${prefix}[strengthenHeavy]`}
              value={strengthenHeavy}
              floatingLabelText={'Heavy cargoes'}
              onChange={this.handleField.bind(this, 'strengthenHeavy', null)}
            />
          </div>
        </div>
        {
          this.props.withoutIceClass
            ? null
            : (
              <div className={v.row}>
                <div>
                  <NarrowSelect
                    floatingLabelText={'Ice Class'}
                    name={`${prefix}[iceClass]`}
                    fullWidth
                  >
                    {
                      _iceClass.map(item => <MenuItem value={item} primaryText={item}></MenuItem>)
                    }
                  </NarrowSelect>
                </div>
                <div>

                </div>
                <div>

                </div>
                <div>

                </div>
              </div>
            )
        }
      </div>
    )
  }
}

class GearsGrabs extends Component {
  static defaultProps = {
    grabbed: false,
    isGeared: undefined,
    gears: [],
    grabs: [],
  }
  constructor(props) {
    super(props);
    this.state = {
      isGeared: props.grabbed ? true : props.isGeared,
      grabbed: props.grabbed,
      isFormHidden: false,
    }
  }

  findInvalidInputs = () => {
    this.collapse.findInvalidInputs()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isGeared !== this.props.isGeared || nextProps.grabbed !== this.props.grabbed) {
      this.setState(state => ({
        gears: nextProps.gears,
        grabs: nextProps.grabs,
        isGeared: nextProps.grabbed ? true : nextProps.isGeared,
        grabbed: nextProps.grabbed,
        isFormHidden: nextProps.grabbed || nextProps.isGeared ? false : true
      }))
    }
  }

  handleGeared = (ev, value) => {
    let newState = {
      grabbed: value
    }
    if (value) {
      newState.isGeared = true;
    }
    this.setState(newState, this.handleForm)
  }

  handleForm = () => {
    const {isGeared, grabbed} = this.state;
    if (grabbed || isGeared) {
      this.unhideForm()
    }
    else {
      this.hideForm();
    }
  }

  changeGeared = (ev, value) => {
    this.setState( state => ({
      isGeared: value,
      grabbed: value ? state.grabbed : false
    }), this.handleForm)
  }

  unhideForm = () => {
    this.setState({
      isFormHidden: false
    })
  }

  hideForm = () => {
    this.setState({
      isFormHidden: true
    })
  }

  render() {
    const additionals = (
      <div className={cx(v.row, v.additionals)}>
          <div style={{width: '150px', display: 'flex'}}>
            <NarrowSelect
                underlineStyle={{display: 'none'}}
                name={`geared`}
                // labelStyle={{top: '4px'}}
                // containerStyle={{ left: '25%', transform: 'translate(-50%, -50%)', maxHeight: '27px'}}
                fullWidth
                defaultValue={this.state.isGeared}
                value={this.state.isGeared}
                className={cx(v.select,"select_small_height")}
                onChange={this.changeGeared}
              >
                <MenuItem value={undefined} primaryText="Gears not set" />
                <MenuItem value={true} primaryText="Geared" />
                <MenuItem value={false} primaryText="Gearless" />
            </NarrowSelect>
          </div>
          <div style={{height: '100%', alignItems: 'center'}}>
              <CheckBox
                className={v.grabFitted}
                name={`grabbed`}
                label={'Grab Fitted'}
                checked={this.state.grabbed}
                onCheck={this.handleGeared}
              />
          </div>

      </div>
    )
    return (
      <div className={v.gearsGrabs}>
        <Collapse innerRef={el => this.collapse = el} defaultOpen={false} isHidden={this.state.isFormHidden} titleClass={v.collapse_title} ref="collapseComponent" title="GEAR & GRABS" additionals={additionals}>
          <Gears setGeared={this.changeGeared.bind(this, null, true)} rows={this.state.gears}></Gears>
          <Grabs setGrabbed={this.handleGeared.bind(this, null, true)} setGeared={this.changeGeared.bind(this, null, true)} rows={this.state.grabs}></Grabs>
        </Collapse>
      </div>

    )
  }
}
function _Grabs(props) {

  const prefix = props.prefix || 'grabV2[summary]'

  const changeType = (row, ev, value) => {
    props.setGeared();
    props.setGrabbed && props.setGrabbed();
    props.changeField({id: row?.id ?? row, field: 'type'}, ev, value)
  }

  const changeQuantity = (row, ev, value) => {
    props.setGeared();
    props.setGrabbed && props.setGrabbed();
    props.changeField({id: row?.id ?? row, field: 'quantity'}, ev, value)
  }

  const changeVolume = (row, ev, value) => {
    props.setGeared();
    props.changeField({id: row?.id ?? row, field: 'volume'}, ev, value)
  }

  return (
    <div className={v.grabs}>
        {
          props.withoutTitle
          ? null
          : (
            <h5 className={v.section_title}>
                GRABS
            </h5>
          )
        }
        {props.rows?.map((row, i) => (<div key={row?.id ?? row} className={v.grab}>
          <div>
            <NarrowSelect
              floatingLabelText="Type"
              name={`${prefix}[${i}][type]`}
              maxHeight={271}
              className={v.select}
              fullWidth
              value={row?.type || ''}
              onChange={changeType.bind(this, row)}
              disabled={props.geared === false}
            >
              {
                grabTypes.map(grabType => (
                  <MenuItem key={grabType} value={grabType} primaryText={grabType} />
                ))

              }
            </NarrowSelect>
          </div>
          <div>
            <NarrowFormsyText
              floatingLabelText="Quantity"
              validations={'requiredIf:' + prefix + '[' + i + ']' + '[volume],' + 'isNumeric,isInt,min:0,max:20'}
              validationErrors={{
                isInt: 'only integer',
                isNumeric: 'only integer',
                min: '0 - 20',
                max: '0 - 20',
                requiredIf: 'required'
              }}
              value={row?.quantity || ''}
              name={`${prefix}[${i}][quantity]`}
              onChange={changeQuantity.bind(this, row)}
              fullWidth
            />
          </div>
          <div>
          <NarrowFormsyText
              floatingLabelText="Volume, cbm"
              validations="isNumeric,min:0,max:2000"
              validationErrors={{
                isNumeric: 'only numbers',
                min: '0 - 2000',
                max: '0 - 2000',
              }}
              value={row?.volume || ''}
              name={`${prefix}[${i}][volume]`}
              onChange={changeVolume.bind(this, row)}
              fullWidth
            />
          </div>
          <div className={v.gear_rest}>
                <span className={v.add_line}>
                {
                  props.geared === false
                  ? null
                  : (
                    i === props.rows?.length-1 ?
                      <ContentAdd
                          className={v.add_icon}
                          onClick={props.handleAddRow}
                      />
                      :
                      <ContentClear

                          className={v.delete}
                          onClick={props.handleRemoveRow.bind(this, row)}
                      />
                  )
                }
                </span>
            </div>

          </div>
        ),
        )}
      </div>
  )
}
_Grabs.parseRows = rows => {
  return rows.map(row => !!row ? row : {})
}

function _Gears(props) {

  const prefix = props.prefix || 'gearV2[summary]';

  const specifyQuantity = (row, ev, val) => {
    props.setGeared();
    props.changeField({id: row?.id ?? row, field: 'quantity'}, ev, val)
  }

  const changeMaxSWL = (row, ev, val) => {
    props.setGeared();
    ['maxSWL', 'combinable'].forEach(field => props.changeField({id: row?.id ?? row, field}, ev, field === 'combinable' ? true : +val))
  }

  const changeComby = (row, ev, val) => {
    props.setGeared();
    props.changeField({id: row?.id ?? row, field: 'combinable'}, ev, val)
  }

  const changeCapacity = (row, ev, val) => {
    props.setGeared();
    props.changeField({id: row?.id ?? row, field: 'capacity'}, ev, val)
  }

  const changeOutreach = (row, ev, val) => {
    props.setGeared();
    props.changeField({id: row?.id ?? row, field: 'outreach'}, ev, val)
  }

  const changeType = (row, ev, value) => {
    props.setGeared();
    props.changeField({id: row?.id ?? row, field: 'type'}, ev, value);
  }

  return (
    <div className={v.gears}>
        {
          props.withoutTitle
          ? null
          : (
            <h5 className={v.section_title}>
                GEAR
            </h5>
          )
        }
        {props.rows?.map((row, i) => (<div key={row?.id ?? row} className={v.gear}>
          <div>
            <NarrowSelect
              floatingLabelText="Type"
              name={`${prefix}[${i}][type]`}
              maxHeight={271}
              className={v.select}
              fullWidth
              value={row?.type || ''}
              onChange={changeType.bind(this, row)}
              disabled={props.geared === false}
            >
              {
                gearTypes.map(gearType => (
                  <MenuItem key={gearType} value={gearType} primaryText={gearLabels[gearType] || gearType} />
                ))

              }
            </NarrowSelect>
          </div>
          <div>
            <NarrowFormsyText
              floatingLabelText="Quantity"
              validations={'requiredIf:${prefix}[' + i + '][capacity],isNumeric,isInt,min:0,max:20'}
              validationErrors={{
                isInt: 'only integer',
                isNumeric: 'only numbers',
                min: '0 - 20',
                max: '0 - 20',
                "requiredIf": "required"
              }}
              disabled={!row?.type || props.geared === false}
              onChange={specifyQuantity.bind(this, row)}
              value={row?.quantity || ''}
              name={`${prefix}[${i}][quantity]`}
              fullWidth
            />
          </div>
          <div>
          <NarrowFormsyText
              floatingLabelText="Capacity, mt"
              validations="isNumeric,min:0,max:600"
              validationErrors={{
                isNumeric: 'only numbers',
                min: '0 - 600',
                max: '0 - 600',
              }}
              disabled={!row?.type || props.geared === false}
              name={`${prefix}[${i}][capacity]`}
              value={row?.capacity || ''}
              fullWidth
              onChange={changeCapacity.bind(this, row)}
            />
          </div>
           <div>
           <NarrowFormsyText
              floatingLabelText="Outreach, m"
              validations="isNumeric,min:0,max:50"
              validationErrors={{
                isNumeric: 'only numbers',
                min: '0 - 50',
                max: '0 - 50',
              }}
              disabled={!row?.type || props.geared === false}
              name={`${prefix}[${i}][outreach]`}
              value={row?.outreach || ''}
              fullWidth
              onChange={changeOutreach.bind(this, row)}
            />
           </div>
           <div>
           <NarrowFormsyText
              floatingLabelText="SWL"
              validations="isNumeric,min:0,max:1000"
              validationErrors={{
                isNumeric: 'only numbers',
                min: '0 - 1000',
                max: '0 - 1000',
              }}
              disabled={!row?.type || !row?.quantity || row.quantity < 2 || props.geared === false}
              name={`${prefix}[${i}][maxSWL]`}
              value={row?.maxSWL || ''}
              onChange={changeMaxSWL.bind(this, row)}
              fullWidth
            />
           </div>
            <div className={v.gear_rest}>
                <CheckBox
                  className={v.combinable}
                  name={`${prefix}[${i}][combinable]`}
                  label={'Comby'}
                  checked={row.combinable || false}
                  onCheck={changeComby.bind(this, row)}
                  disabled={!row?.type || !row?.quantity || row.quantity < 2 || props.geared === false}
                />
                <span className={v.add_line} style={{marginLeft: 10}}>
                {
                  props.geared === false
                  ? null
                  : (
                    i === props.rows?.length-1 ?
                      <ContentAdd
                          className={v.add_icon}
                          onClick={props.handleAddRow}
                      />
                      :
                      <ContentClear

                          className={v.delete}
                          onClick={props.handleRemoveRow.bind(this, row)}
                      />
                  )
                }
                </span>
            </div>

          </div>
        ),
        )}
      </div>
  )
}
_Gears.parseRows = rows => {
  return rows.map(row => !!row ? row : {})
}


function PermissibleLoads(props) {
  return (
    <div className={cx(v.section, v.permissibleLoads)}>
      <div className={v.row}>
          <NarrowFormsyText
              floatingLabelText="Max TT strength"
              validations="isNumeric,min:0,max:50"
              validationErrors={{
                  isNumeric: 'only numbers',
                  min: '0 - 50',
                  max: '0 - 50',
              }}
              name="strength[maxTt]"
              fullWidth

          />
          <NarrowFormsyText
              floatingLabelText="Max Tween strength"
              validations="isNumeric,min:0,max:20"
              validationErrors={{
                  isNumeric: 'only numbers',
                  min: '0 - 20',
                  max: '0 - 20',
              }}
              name="strength[maxTween]"
              fullWidth
          />
          <NarrowFormsyText
                floatingLabelText="Max Weather Deck strength"
                validations="isNumeric,min:0,max:50"
                validationErrors={{
                    isNumeric: 'only numbers',
                    min: '0 - 50',
                    max: '0 - 50',
                }}
                name="strength[maxWeather]"
                fullWidth
          />
      </div>
      <div className={v.row}>
          <NarrowFormsyText
              floatingLabelText="20' Max stackweight hold"
              validations="isNumeric,min:0,max:1000"
              validationErrors={{
                  isNumeric: 'only numbers',
                  min: '0 - 1000',
                  max: '0 - 1000',
              }}
              name="stackWeight[20hold]"
              fullWidth
          />
          <NarrowFormsyText
              floatingLabelText="20' Max stackweight deck"
              validations="isNumeric,min:0,max:1000"
              validationErrors={{
                  isNumeric: 'only numbers',
                  min: '0 - 1000',
                  max: '0 - 1000',
              }}
              name="stackWeight[20deck]"
              fullWidth
          />
          <NarrowFormsyText
              floatingLabelText="40' Max stackweight hold"
              validations="isNumeric,min:0,max:1000"
              validationErrors={{
                  isNumeric: 'only numbers',
                  min: '0 - 1000',
                  max: '0 - 1000',
              }}
              name="stackWeight[40hold]"
              fullWidth
          />
          <NarrowFormsyText
              floatingLabelText="40' Max stackweight deck"
              validations="isNumeric,min:0,max:1000"
              validationErrors={{
                  isNumeric: 'only numbers',
                  min: '0 - 1000',
                  max: '0 - 1000',
              }}
              name="stackWeight[40deck]"
              fullWidth
          />
      </div>
    </div>
  )
}

function _Bunkers({rows, handleAddRow, handleRemoveRow}) {
  return (
    <div className={cx(v.section, v.bunkers)}>
      <div className={v.row}>
        <div className={v.columns}>
        {
          rows?.map((row, index) => (
            <div key={row} className={v.column}>
              <div className={v.field}>
                <NarrowFormsyText
                    floatingLabelText="Max Quantity, mt"
                    validations="isNumeric,min:0,max:10000"
                    validationErrors={{
                        isNumeric: 'only numbers',
                        min: '0 - 10000',
                        max: '0 - 10000',
                    }}
                    name={`bunkers[${index}][quantity]`}
                    fullWidth
                />
              </div>
              <NarrowSelect
                  floatingLabelText="Type Of Fuel"
                  name={`bunkers[${index}][typeOfFuel]`}
                  validations={'requiredIf:bunkers' + '[' + index + ']' + '[quantity]'}
                  validationErrors={{
                    "requiredIf": "required"
                  }}
                  className={v.field}
              >
                  {
                    bunkersList.map(fuel => <MenuItem value={fuel.type} primaryText={fuel.type}></MenuItem>)
                  }

              </NarrowSelect>
              <div className={v.cell_wrapper}>
                        {
                          index === rows.length - 1
                          ?
                          <div onClick={handleAddRow} className={v.cell}>
                            <ContentAdd
                                  className={v.add_icon}
                                  color="#285596"
                            />
                          </div>
                          :
                          <div onClick={handleRemoveRow.bind(this, row)} className={v.cell}>
                            <ContentClear
                                className={v.delete}
                                data-id={row}
                            />
                          </div>
                        }
              </div>
            </div>
          ))
        }
        </div>

        </div>
    </div>
  )
}



function Dimensions() {
  return (
    <div className={cx(v.row, v.dimensions)}>
      <NarrowFormsyText
          floatingLabelText={'LOA'}
          validations="isNumeric,min:0,max:500"
          validationErrors={{
              isNumeric: 'only numbers',
              min: '0 - 500',
              max: '0 - 500',
          }}
          name={'dimensions[loa]'}
          fullWidth
          className={cx("custom_errors", "input_default")}
      />
      <NarrowFormsyText
          floatingLabelText={'Beam'}
          validations="isNumeric,min:0,max:100"
          validationErrors={{
              isNumeric: 'only numbers',
              min: '0 - 100',
              max: '0 - 100',
          }}
          name={'dimensions[beam]'}
          fullWidth
          className={cx("custom_errors", "input_default")}
      />
      <NarrowFormsyText
          floatingLabelText={'LBP'}
          validations="isNumeric,min:0,max:500"
          validationErrors={{
              isNumeric: 'only numbers',
              min: '0 - 500',
              max: '0 - 500',
          }}
          name={'dimensions[lbp]'}
          fullWidth
          className={cx("custom_errors", "input_default")}
      />
      <NarrowFormsyText
          floatingLabelText={'Depth'}
          validations="isNumeric,min:0,max:99"
          validationErrors={{
              isNumeric: 'only numbers',
              min: '0 - 99',
              max: '0 - 99',
          }}
          name={'dimensions[depth]'}
          fullWidth
          className={cx("custom_errors", "input_default")}
      />
    </div>
  )
}

export class _Capacity extends Component {

  static inputs = [
    {
      type: "hold",
      name: "grain",
      validations: "isNumeric,min:0,max:600000"
    },
    {
      type: "hold",
      name: "bale",
      validations: "isNumeric,min:0,max:600000"
    },
    {
      type: "hold",
      name: "teu",
      validations: "isNumeric,min:0,max:5000"
    },
    {
      type: "hold",
      name: "feu",
      validations: "isNumeric,min:0,max:5000"
    },
    {
      type: "tweenDeck",
      name: "grain",
      validations: "isNumeric,min:0,max:600000"
    },
    {
      type: "tweenDeck",
      name: "bale",
      validations: "isNumeric,min:0,max:600000"
    },
    {
      type: "tweenDeck",
      name: "teu",
      validations: "isNumeric,min:0,max:5000"
    },
    {
      type: "tweenDeck",
      name: "feu",
      validations: "isNumeric,min:0,max:5000"
    },
  ];

  /* constructor(props) {
    super(props);
    this.state = {
      rows: props?.rows?.length ? this.transformProps(props.rows) : [this.getRandomId()]
    }
  }; */

 /*  componentWillReceiveProps(nextProps) {
    if (nextProps?.rows?.length && nextProps?.rows !== this.props?.rows) {
      console.log(nextProps.rows);
      this.setState(state => ({
        rows: [...state.rows, ...this.transformProps(nextProps.rows)]
      }))
    }
  } */

  getRow = (id = 1) => {
    return {
      id,
      inputs: [..._Capacity.inputs]
    }
  }

  t/* ransformProps = (rows = []) => {
     rows = rows.map((item, index) => index);
     return rows;
  } */

  transformRow = row => {
    return {
      id: this.getRandomId(),
      inputs: [
        ...Object.keys(row.hold)?.map(hold => ({name: hold, type: 'hold'})),
        ...Object.keys(row.tweenDeck)?.map(td => ({name: td, type: 'tweenDeck'})),
      ]
    }
  }

  /* getRandomId = () => Math.floor(Math.random() * 900) */

  /* handleAddRow = () => {
    this.setState(state => ({
      rows: [...state.rows, this.getRandomId()]
    }))
  } */

  /* handleRemoveRow = index => {
    const rows = this.state.rows.filter((row, i) => row !== index);
    this.setState({
      rows
    })
  } */

  render() {
    const {handleRemoveRow, handleAddRow, changeRowsQuantity, rows} = this.props;
    return (
      <div className={cx(v.section, v.capacity)}>
          <div className={v.row}>
              <NarrowFormsyText
                  floatingLabelText={'Grain, m3'}
                  validations="isNumeric,min:0,max:600000"
                  validationErrors={{
                      isNumeric: 'only numbers',
                      min: '0 - 600000',
                      max: '0 - 600000',
                  }}
                  name={'capacityV2[grain]'}
                  fullWidth
                  className={cx("custom_errors", "input_default")}
              />
              <NarrowFormsyText
                  floatingLabelText={'Bale, m3'}
                  validations="isNumeric,min:0,max:600000"
                  validationErrors={{
                      isNumeric: 'only numbers',
                      min: '0 - 600000',
                      max: '0 - 600000',
                  }}
                  name={'capacityV2[bale]'}
                  fullWidth
                  className={cx("custom_errors", "input_default")}
              />
              <NarrowFormsyText
                  floatingLabelText={'TEU'}
                  validations="isNumeric,min:0,max:30000"
                  validationErrors={{
                      isNumeric: 'only numbers',
                      min: '0 - 30000',
                      max: '0 - 30000',
                  }}
                  name={'capacityV2[teu]'}
                  fullWidth
                  className={cx("custom_errors", "input_default")}
              />
              <NarrowFormsyText
                  floatingLabelText={'FEU'}
                  validations="isNumeric,min:0,max:30000"
                  validationErrors={{
                      isNumeric: 'only numbers',
                      min: '0 - 30000',
                      max: '0 - 30000',
                  }}
                  name={'capacityV2[feu]'}
                  fullWidth
                  className={cx("custom_errors", "input_default")}
              />
              <NarrowFormsyText
                  floatingLabelText={'Constant, mt'}
                  validations="isNumeric,min:0,max:1000"
                  validationErrors={{
                      isNumeric: 'only numbers',
                      min: '0 - 1000',
                      max: '0 - 1000',
                  }}
                  name={'capacityV2[constant]'}
                  fullWidth
                  className={cx("custom_errors", "input_default")}
              />
          </div>
          <div className={v.row}>
              <div className={v.table_wrapper}>
                  <table displayBorder={false} allRowsSelected={false} selectable={false} className={cx(v.table)}>
                      <thead style={{border: 'none'}} adjustForCheckbox={false} enableSelectAll={false} displaySelectAll={false}>
                          <tr style={{border: 'none'}}>
                              <th colSpan="4" className={v.big_cell}>
                                  <div>
                                    HOLD
                                  </div>
                              </th>
                              <th colSpan="4" className={v.big_cell}>
                                  <div>
                                    TWEENDECK
                                  </div>
                              </th>
                          </tr>
                      </thead>
                      <tbody displayRowCheckbox={false}>
                          <tr>
                              <td colSpan="1" className={cx(v.small_cell, v.cell_bold)}>
                                <div>
                                  Grain, m3
                                </div>
                              </td>
                              <td colSpan="1" className={cx(v.small_cell, v.cell_bold)}>
                                <div>
                                  Bale, m3
                                </div>
                              </td>
                              <td colSpan="1" className={cx(v.small_cell, v.cell_bold)}>
                                <div>
                                  TEU
                                </div>
                              </td>
                              <td colSpan="1" className={cx(v.small_cell, v.cell_bold)}>
                                <div>
                                  FEU
                                </div>
                              </td>
                              <td colSpan="1" className={cx(v.small_cell, v.cell_bold)}>
                                <div>
                                  Grain, m3
                                </div>
                              </td>
                              <td colSpan="1" className={cx(v.small_cell, v.cell_bold)}>
                                <div>
                                  Bale, m3
                                </div>
                              </td>
                              <td colSpan="1" className={cx(v.small_cell, v.cell_bold)}>
                                <div>
                                  TEU
                                </div>
                              </td>
                              <td colSpan="1" className={cx(v.small_cell, v.cell_bold)}>
                                <div>
                                  FEU
                                </div>
                              </td>
                          </tr>
                            {
                              rows?.map((row, index) => (
                                <tr key={row}>
                                  {_Capacity.inputs.map(input => (
                                    <td colSpan="1" key={row.id + input.name + input.type} className={v.small_cell}>
                                        <div className={cx(v.cell_input)}>
                                            <Input
                                                type="text"
                                                numeric
                                                validations={input.validations}
                                                name={`capacityV2[summary][${index}][${input.type}][${input.name}]`}
                                                id={`capacityV2[summary][${index}][${input.type}][${input.name}]`}
                                                fullWidth
                                            />
                                        </div>
                                    </td>
                                  ))}
                                </tr>
                              ))
                            }
                      </tbody>
                  </table>
              </div>
              <div className={v.side_column}>
                {rows?.map((row, index) => (<div key={row} className={v.cell}>
                    {
                      index === rows.length - 1
                       ?
                       <ContentAdd
                            onClick={handleAddRow}
                            color="#285596"
                        />
                        :
                        <ContentClear
                            data-id={row}
                            onClick={handleRemoveRow.bind(this, row)}
                        />

                    }
                </div>))}
              </div>
          </div>
      </div>
    )
  }
}

export class Tonnage extends Component {
  static cells = [
    {
      field: "Loaded",
      validations: "isNumeric,min:500,max:400000"
    },
    {
      field: "DWT",
      validations: "isNumeric,min:650,max:400000"
    },
    {
      field: "DWCC",
      validations: "isNumeric,min:0,max:500000"
    },
    {
      field: "Draft",
      validations: "isNumeric,min:0,max:40000"
    },
    {
      field: "Tpc",
      validations: "isNumeric,min:0,max:999"
    },
    {
      field: "Tpi",
      validations: "isNumeric,min:0,max:999"
    }
  ];
  static objectName = 'deadWeight';

  state = {
    hidden: true
  }

  renderRow = (rowType, requiredFieldsArray = [], requiredIfOtherInputs = false) => {
    return Tonnage.cells.map(cell => {
      const requiredField = requiredFieldsArray.includes(cell);
      return  (
        <td className={v.small_cell}>
            <div className={cx(v.cell_input)}>
                <Input
                    validations={cell.validations || ""}
                    placeholder={requiredIfOtherInputs ? '' : requiredField ? 'Enter*' : ' '}
                    required={!this.state.hidden && requiredIfOtherInputs && requiredField ? 'requiredIfInArrInputs:' + rowType : requiredField ? true : false}
                    type="text"
                    numeric
                    name={`${Tonnage.objectName}[${rowType}${cell.field}]`}
                    id={`${Tonnage.objectName}[${rowType}${cell.field}]`}
                    fullWidth
                />
            </div>
        </td>
      )
    })
  }

  componentWillMount() {
    setTimeout(() => {
      this.setState({hidden: false})
    }, 1000)
  }

  render() {
    return (
      <div className={cx(v.section, v.tonnage)}>
          <div className={v.row}>
              <NarrowFormsyText
                  floatingLabelText={'GT'}
                  validations="isNumeric,min:0,max:500000"
                  validationErrors={{
                      isNumeric: 'only numbers',
                      min: '0 - 500000',
                      max: '0 - 500000',
                  }}
                  name={'rt[gt]'}
                  fullWidth
                  className={cx("custom_errors", "input_default")}
              />
              <NarrowFormsyText
                  floatingLabelText={'Suez GT'}
                  validations="isNumeric,min:0,max:500000"
                  validationErrors={{
                      isNumeric: 'only numbers',
                      min: '0 - 500000',
                      max: '0 - 500000',
                  }}
                  name={'rt[suezGt]'}
                  fullWidth
                  className={cx("custom_errors", "input_default")}
              />
              <NarrowFormsyText
                  floatingLabelText={'Suez NT'}
                  validations="isNumeric,min:0,max:500000"
                  validationErrors={{
                      isNumeric: 'only numbers',
                      min: '0 - 500000',
                      max: '0 - 500000',
                  }}
                  name={'rt[suezNt]'}
                  fullWidth
                  className={cx("custom_errors", "input_default")}
              />

          </div>
          <div className={v.row}>

              <NarrowFormsyText
                  floatingLabelText={'NT'}
                  validations="isNumeric,min:0,max:500000"
                  validationErrors={{
                      isNumeric: 'only numbers',
                      min: '0 - 500000',
                      max: '0 - 500000',
                  }}
                  name={'rt[nt]'}
                  fullWidth
                  className={cx("custom_errors", "input_default")}
              />
              <NarrowFormsyText
                  floatingLabelText={'Panama GT'}
                  validations="isNumeric,min:0,max:500000"
                  validationErrors={{
                      isNumeric: 'only numbers',
                      min: '0 - 500000',
                      max: '0 - 500000',
                  }}
                  name={'rt[panamaGt]'}
                  fullWidth
                  className={cx("custom_errors", "input_default")}
              />
              <NarrowFormsyText
                  floatingLabelText={'Panama NT'}
                  validations="isNumeric,min:0,max:500000"
                  validationErrors={{
                      isNumeric: 'only numbers',
                      min: '0 - 500000',
                      max: '0 - 500000',
                  }}
                  name={'rt[panamaNt]'}
                  fullWidth
                  className={cx("custom_errors", "input_default")}
              />
          </div>
          <div className={s.row}>
              <div className={v.table_wrapper}>
                  <table displayBorder={false} allRowsSelected={false} selectable={false} className={cx(v.table, v.tonnage)}>
                      <thead style={{border: 'none'}} adjustForCheckbox={false} enableSelectAll={false} displaySelectAll={false}>
                          <tr style={{border: 'none'}}>
                              <th className={v.big_cell}>
                                  <div>
                                    DEADWEIGHT / OTHER
                                  </div>
                              </th>
                              <th className={v.small_cell}>
                                  <div>
                                    Loaded displ
                                  </div>
                              </th>
                              <th  className={v.small_cell}>
                                  <div>
                                    DWT, mt
                                  </div>
                              </th>
                              <th  className={v.small_cell}>
                                  <div>
                                    DWCC
                                  </div>
                              </th>
                              <th  className={v.small_cell}>
                                  <div>
                                    Draft, m
                                  </div>
                              </th>
                              <th  className={v.small_cell}>
                                  <div>
                                    Tpc
                                  </div>
                              </th>
                              <th  className={v.small_cell}>
                                  <div>
                                    Tpi
                                  </div>
                              </th>
                          </tr>
                      </thead>
                      <tbody displayRowCheckbox={false}>
                          <tr>
                              <td className={cx(v.big_cell, v.cell_bold)}>
                                Summer
                              </td>
                              {this.renderRow("s", [Tonnage.cells[1]])}
                          </tr>
                          <tr>
                              <td className={cx(v.big_cell, v.cell_bold)}>
                                Tropical
                              </td>
                              {this.renderRow("t", [Tonnage.cells[1]], true)}
                          </tr>
                          <tr>
                              <td className={cx(v.big_cell, v.cell_bold)}>
                                Winter
                              </td>
                              {this.renderRow("w", [Tonnage.cells[1]], true)}
                          </tr>
                          <tr>
                              <td className={cx(v.big_cell, v.cell_bold)}>
                                Fresh
                              </td>
                              {this.renderRow("f", [Tonnage.cells[1]], true)}
                          </tr>
                      </tbody>
                  </table>
              </div>
          </div>
      </div>

    )
  }
}

/* const TextArea = React.createClass({
    mixins: [{
        ...Formsy.Mixin
    }],
    update(ev) {
      this.setState({
        _value: ev.target.value
      })
    },
    componentWillReceiveProps(nextProps) {
      this.setState({
        _value: nextProps.value,
        value: nextProps.value
      })
    },
    render() {
        const {name} = this.props;
        return (
            <textarea value={this.state._value} name={name} onChange={this.update} className={v.textarea} {...this.props}></textarea>
        )
    }
}) */

/* class Exclusions extends Component {
    static defaultProps = {
        cargoExclusions: [],
        tradingRestrictions: [],
        isVesselOwner: false,
        attachAsRef: () => void(0),
        changesCallback: () => void(0),
    }
    static greenExclRoles = ['user', 'admin']
    static ownerRole = "owner"
    static userRole = "user"
    constructor(props) {
        super(props);
        this.state = {
            cargoExclusions: this.filteredExclusions(this.props.cargoExclusions || []),
            tradingRestrictions: this.filteredExclusions(this.props.tradingRestrictions || []),
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.cargoExclusions !== this.props.cargoExclusions || nextProps.tradingRestrictions !== this.props.tradingRestrictions) {
            this.setState({
                cargoExclusions:this.filteredExclusions(nextProps.cargoExclusions || this.state.cargoExclusions),
                tradingRestrictions: this.filteredExclusions(nextProps.tradingRestrictions || this.state.tradingRestrictions),
            })
        }
    }
    sortExclusions = list => list.sort((a,b) => a.role === Exclusions.ownerRole ? -1 : 1)
    filteredExclusions = list => list.filter((item, index) => {
        if (!item._id && !item.name) {
            return false;
        }
        const _item = JSON.stringify(item);
        return index === list.findIndex(obj => JSON.stringify(obj) === _item)
    })
    transformExclToSend = list => list ? list.map(({_id}) => ({id: _id})) : []
    getCargoExclusions = () => this.transformExclToSend(this.state.cargoExclusions)
    getTradingRestrictions = () => this.transformExclToSend(this.state.tradingRestrictions)
    getOnlyUserExclusions = type => this.state[type] ? this.transformExclToSend(this.state[type].filter(item => item.role === Exclusions.userRole)) : null;
    exclusionExists = (arr, excl) => {
        return arr.find(item => item._id === excl._id)
    }
    addCargoExclusion = (exclusion) => {
        if (!exclusion || !exclusion.name) return;
        const {cargoExclusions} = this.state;
        if (this.exclusionExists(cargoExclusions, exclusion)) return;
        this.props.changesCallback();
        this.setState(state => ({
            cargoExclusions: [...state.cargoExclusions, {
                ...exclusion,
                role: this.props.isVesselOwner ? Exclusions.ownerRole : Exclusions.userRole,
                mine: true
            }]
        }))
    }
    addTradingRestriction = (exclusion) => {
        if (!exclusion || !exclusion.name) return;
        const {tradingRestrictions} = this.state;
        if (this.exclusionExists(tradingRestrictions, exclusion)) return;
        this.props.changesCallback();
        this.setState(state => ({
            tradingRestrictions: [...state.tradingRestrictions, {
                ...exclusion,
                role: this.props.isVesselOwner ? Exclusions.ownerRole : Exclusions.userRole,
                mine: true
            }],
        }))
    }

    renderExclusions = (array, cb) => {
        const {isVesselOwner} = this.props;
        return array.map(item => {
            const green = Exclusions.greenExclRoles.includes(item.role.toLowerCase());
            const canDelete = isVesselOwner || item.mine || item.role === Exclusions.userRole;
            return (
                <Chip onRequestDelete={canDelete ? _ => cb(item) : null} backgroundColor={green ? '#D6EDBD' : '#E6E6E6'} style={{margin: '8px 8px 0px 0px'}} className={v.chip}>- {item.name}</Chip>
            )
        })
    }
    deleteExclusion = (type, excl) => {
        const newArr = this.state[type].filter(item => {
            if (item._id === excl._id) {
                if (item.role === excl.role) {
                    return false;
                }
                else {
                    return true;
                }
            }
            else {
                return true;
            }
        });
        this.props.changesCallback();
        this.setState({
            [type]: newArr
        })
    }
    componentDidMount() {
      this.props.attachAsRef(this);
    }
    render() {
        const {addCargoExclusion, addTradingRestriction, renderExclusions, deleteExclusion} = this;
        const {cargoExclusions, tradingRestrictions} = this.state;
        return (
            <div className={v.exclusions}>
                <div className={v.add}>
                    <NarrowCargoAutocomplete
                        floatingLabelText="Cargo exclusions"
                        forceSelection
                        validationError="max 500 characters"
                        name="cargoExclusions"
                        fullWidth
                        shouldReset
                        onNewRequest={addCargoExclusion}
                        params={{groupBy: 'name'}}
                    />

                </div>
                <div className={v.list}>
                    {
                        renderExclusions(cargoExclusions, deleteExclusion.bind(this, 'cargoExclusions'))
                    }
                </div>
                <div className={v.add}>
                    <NarrowPortAutocomplete
                        floatingLabelText="Trading restrictions"
                        validationError="max 500 characters"
                        name="tradingRestrictions"
                        shouldReset
                        onNewRequest={addTradingRestriction}
                    />

                </div>
                <div className={v.list}>
                    {
                        renderExclusions(tradingRestrictions, deleteExclusion.bind(this, 'tradingRestrictions'))
                    }
                </div>
            </div>
        )
    }
} */

class HoHaTable extends Component {

    static hatches =  [
      {
        type: "l",
        validations: "isNumeric,min:0,max:500"
      },
      {
        type: "b",
        validations: "isNumeric,min:0,max:500"
      },
      {
        type: "area",
        validations: "isNumeric,min:0,max:2000"
      },
    ]
    static holdsAndTweens =  [
      {
        type: "l",
        validations: "isNumeric,min:0,max:500"
      },
      {
        type: "b",
        validations: "isNumeric,min:0,max:500"
      },
      {
        type: "h",
        validations: "isNumeric,min:0,max:99"
      },
      {
        type: "area",
        validations: "isNumeric,min:0,max:2000"
      },
    ]
    renderLBH = (prefix, list, limitColumnsToTwo = false) => {
        return (
            <TableRowColumn className={v.list_cell}>
                {list.map((item, i) => (
                    <TableRow>
                        <TableRowColumn className={v.small_cell}>
                            <div className={cx(v.cell_input)}>
                                <Input floatingLabelText={' '}
                                    dontModifyValue={true}
                                    type="text" placeholder=""
                                    numeric
                                    value=""
                                    name={prefix + `[${i}][l]`}
                                    fullWidth
                                />
                            </div>
                        </TableRowColumn>
                        <TableRowColumn className={v.small_cell}>
                            <div className={cx(v.cell_input)}>
                                <Input floatingLabelText={' '}
                                    dontModifyValue={true}
                                    type="text" placeholder=""
                                    numeric
                                    value=""
                                    name={prefix + `[${i}][b]`}
                                    fullWidth
                                />
                            </div>
                        </TableRowColumn>
                            <TableRowColumn className={v.small_cell}>
                            {
                                !limitColumnsToTwo
                                    ? (
                                        <div className={cx(v.cell_input)}>
                                            <Input floatingLabelText={' '}
                                                dontModifyValue={true}
                                                type="text" placeholder=""
                                                numeric
                                                value=""
                                                name={prefix + `[${i}][h]`}
                                                fullWidth
                                            />
                                        </div>
                                    )
                                    : null
                            }

                        </TableRowColumn>
                    </TableRow>
                    ))
                }

            </TableRowColumn>
        )
    }

    renderRow = (row, index) => {
      return (
                <tbody key={row}>
                  <tr>
                    {
                      HoHaTable.holdsAndTweens.map(input => (
                        <td rowSpan="2" colSpan={input.type === "area" ? "" : "1"} key={row + input.type + 'holds'}>
                          <div className={cx(v.cell_input)}>
                            <Input
                                numeric
                                validations={input.validations}
                                name={`cells[${index}][holds][${input.type}]`}
                                id={`cells[${index}][holds][${input.type}]`}
                                fullWidth
                            />
                          </div>
                        </td>
                      ))
                    }
                    {
                      HoHaTable.holdsAndTweens.map(input => (
                        <td rowSpan="1" colSpan={input.type === "area" ? "" : "1"} key={row + input.type + 'tweenDecks' + '0'}>
                          <div className={cx(v.cell_input)}>
                            <Input
                                numeric
                                validations={input.validations}
                                name={`cells[${index}][tweenDecks][0][${input.type}]`}
                                id={`cells[${index}][tweenDecks][0][${input.type}]`}
                                fullWidth
                            />
                          </div>
                        </td>
                      ))
                    }
                    {
                      HoHaTable.hatches.map(input => (
                        <td rowSpan="1" colSpan={input.type === "area" ? "" : "1"} key={row + input.type + 'hatches' + '0'}>
                          <div className={cx(v.cell_input)}>
                            <Input
                                numeric
                                validations={input.validations}
                                name={`cells[${index}][hatches][0][${input.type}]`}
                                id={`cells[${index}][hatches][0][${input.type}]`}
                                fullWidth
                            />
                          </div>
                        </td>
                      ))
                    }
                  </tr>
                  <tr>

                    {
                      HoHaTable.holdsAndTweens.map(input => (
                        <td rowSpan="1" colSpan={input.type === "area" ? "" : "1"} key={row + input.type + 'tweenDecks' + '1'}>
                          <div className={cx(v.cell_input)}>
                            <Input
                                numeric
                                validations={input.validations}
                                name={`cells[${index}][tweenDecks][1][${input.type}]`}
                                id={`cells[${index}][tweenDecks][1][${input.type}]`}
                                fullWidth
                            />
                          </div>
                        </td>
                      ))
                    }
                    {
                      HoHaTable.hatches.map(input => (
                        <td rowSpan="1" colSpan={input.type === "area" ? "" : "1"} key={row + input.type + 'hatches' + '1'}>
                          <div className={cx(v.cell_input)}>
                            <Input
                                numeric
                                validations={input.validations}
                                name={`cells[${index}][hatches][1][${input.type}]`}
                                id={`cells[${index}][hatches][1][${input.type}]`}
                                fullWidth
                            />
                          </div>
                        </td>
                      ))
                    }
                  </tr>
                </tbody>

      )
    }

    render() {
        const {rows} = this.props;
        return (
            <table displayBorder={false} allRowsSelected={false} selectable={false} className={v.table}>
                        <thead style={{border: 'none'}} adjustForCheckbox={false} enableSelectAll={false} displaySelectAll={false}>
                            <tr style={{border: 'none'}}>
                                <th rowSpan="1" colSpan="4">
                                  <div>
                                    HOLDS
                                  </div>
                                </th>
                                <th rowSpan="1" colSpan="4">
                                  <div>
                                    TWEENDECKS
                                  </div>
                                </th>
                                <th rowSpan="1" colSpan="3">
                                  <div>
                                    HATCHES
                                  </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody displayRowCheckbox={false}>
                            <tr>
                                <td rowSpan="1" colSpan="1" className={cx(v.cell_bold)}>
                                  <div>
                                    L, m
                                  </div>
                              </td>
                              <td rowSpan="1" colSpan="1" className={cx( v.cell_bold)}>
                                  <div>
                                    B, m
                                  </div>
                              </td>
                              <td rowSpan="1" colSpan="1" className={cx( v.cell_bold)}>
                                  <div>
                                    H, m
                                  </div>
                              </td>
                              <td rowSpan="1" className={cx(v.cell_bold)}>
                                  <div>
                                    Area, m2
                                  </div>
                              </td>
                              <td rowSpan="1" colSpan="1" className={cx(v.cell_bold)}>
                                  <div>
                                    L, m
                                  </div>
                              </td>
                              <td rowSpan="1" colSpan="1" className={cx(v.cell_bold)}>
                                  <div>
                                    B, m
                                  </div>
                              </td>
                              <td rowSpan="1" colSpan="1" className={cx(v.cell_bold)}>
                                  <div>
                                    H, m
                                  </div>
                              </td>
                              <td rowSpan="1" className={cx(v.cell_bold)}>
                                  <div>
                                    Area, m2
                                  </div>
                              </td>
                              <td rowSpan="1" colSpan="1" className={cx(v.cell_bold)}>
                                  <div>
                                    L, m
                                  </div>
                              </td>
                              <td rowSpan="1" colSpan="1" className={cx(v.cell_bold)}>
                                  <div>
                                    B, m
                                  </div>
                              </td>
                              <td rowSpan="1" className={cx(v.cell_bold)}>
                                  <div>
                                    Area, m2
                                  </div>
                              </td>
                            </tr>
                          </tbody>
                            {
                              rows.map(this.renderRow)
                            }

             </table>
        )
    }
}

class _HoHa extends Component {
  static defaultProps = {
    holds: 0,
    hatches: undefined,
    rows: []
  }
  constructor(props) {
    super(props);
    this.state = {
      holds: props.holds,
      hatches: props.hatches > props.holds * 2 ? props.holds * 2 : props.hatches,
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.holds !== this.props.holds) {
      this.setState({
        holds: nextProps.holds
      })
    }
    if (nextProps.hatches !== this.props.hatches) {
      this.setState({
        hatches: nextProps.hatches > nextProps.holds * 2 ? nextProps.holds * 2 : nextProps.hatches
      })
    }
  }

  changeField = (field = '', ev, val) => {
    this.setState({
      [field]: +val
    })
  }

  addRow = (ev) => {
    if (this.props.rows.length >= this.state.holds) {
      this.setState(state => ({
        holds: this.props.rows.length+1 <= 20 ? this.props.rows.length+1 : 20
      }))
    }
    this.props.handleAddRow(ev);
  }

  handleAreasChange = () => {
    setTimeout(() => {
      const md = (this.refs.areasMd && parseFloat(this.refs.areasMd.refs.input.getValue())) || 0;
      const td = (this.refs.areasTd && parseFloat(this.refs.areasTd.refs.input.getValue())) || 0;
      const wd = (this.refs.areasWd && parseFloat(this.refs.areasWd.refs.input.getValue())) || 0;
      const total = md + td + wd;
      if (total) {
        this.refs.areasTotal && this.refs.areasTotal.refs.input.setValue(total);
      }
    }, 0);
  };
    render() {
        const {handleRemoveRow, handleAddRow, changeRowsQuantity, rows} = this.props;
        const {holds, hatches} = this.state;
        return (
            <div className={v.hoha}>
                <div className={v.hoha_main}>
                    <div>
                        <NarrowFormsyText
                            floatingLabelText="Hold"
                            validations={"isNumeric,isInt,min:" + rows.length + ",max: 20"}
                            validationErrors={{
                                isInt: 'only integer',
                                isNumeric: 'only numbers',
                                min: rows.length + " - 20",
                                max: '0 - 20',
                            }}
                            name="holds[quantity]"
                            type={'number'}
                            min={0}
                            max={20}
                            fullWidth
                            value={holds === 0 ? undefined : holds}
                            onChange={this.changeField.bind(this, 'holds')}
                            // onChange={handleChangeHoldsQuantity}
                        />
                    </div>
                    <div>
                        <NarrowFormsyText
                            floatingLabelText="Hatch"
                            validations={"isNumeric,isInt,min:0,max:" + holds * 2}
                            validationErrors={{
                                isNumeric: 'only numbers',
                                isInt: 'only integer',
                                min: holds === 0 ? 'Enter holds' : '0 - ' + holds * 2,
                                max: holds === 0 ? 'Enter holds' : '0 - ' + holds * 2,
                            }}
                            name="hatches[quantity]"
                            type={'number'}
                            min={0}
                            max={holds * 2}
                            fullWidth
                            withRows
                            value={hatches === 0 ? undefined : hatches}
                            onChange={this.changeField.bind(this, 'hatches')}
                            // onChange={handleChangeHatchesQuantity}
                        />
                    </div>
                    <div className={cx(v.bigger)}>
                        <NarrowSelect
                            className={v.select}
                            floatingLabelText="Hatch type"
                            name="hatches[hatchType]"
                            fullWidth
                            defaultValue={''}
                            maxHeight={271}
                            >
                            {
                                hatchTypes.map((item, i) => (
                                  <MenuItem key={i} value={item} primaryText={item} />
                                ))

                            }
                        </NarrowSelect>
                    </div>

                </div>
                <div className={v.row} style={{paddingTop: '5px', paddingBottom:'18px'}}>
                  <div className={v.table_wrapper}>
                      <HoHaTable holds={holds} hatches={hatches} changeField={this.changeField} rows={rows}></HoHaTable>
                  </div>
                  <div className={v.side_column}>
                    {rows?.map((row, index) => (
                      <div style={{cursor: index === rows.length - 1 && (holds === 0 || holds <= rows?.length) ? 'default' : 'pointer'}} key={row} className={v.cell}>
                        {
                          index === rows.length - 1
                            ?

                              <ContentAdd
                                    onClick={this.addRow}
                                    color="#285596"
                                    className={(holds === 0 || holds <= rows?.length) && v.disabled}
                              />
                            :
                            <ContentClear
                                data-id={row}
                                onClick={handleRemoveRow.bind(this, row)}
                            />

                        }
                        </div>
                    ))}
                  </div>
                </div>
                <div className={v.row}>
                    <NarrowFormsyText
                        floatingLabelText="Area main deck, m2"
                        validations="isNumeric,min:0,max:30000"
                        validationErrors={{
                            isNumeric: 'only numbers',
                            min: '0 - 30000',
                            max: '0 - 30000',
                        }}
                        name="areas[mainDeck]"
                        fullWidth
                        ref="areasMd"
                        // onBlur={this.handleAreasChange}
                    />
                    <NarrowFormsyText
                        floatingLabelText="Area tween deck, m2"
                        validations="isNumeric,min:0,max:30000"
                        validationErrors={{
                            isNumeric: 'only numbers',
                            min: '0 - 30000',
                            max: '0 - 30000',
                        }}
                        name="areas[tweenDeck]"
                        fullWidth
                        ref="areasTd"
                        // onBlur={this.handleAreasChange}
                    />
                    <NarrowFormsyText
                        floatingLabelText="Area weather deck, m2"
                        validations="isNumeric,min:0,max:30000"
                        validationErrors={{
                            isNumeric: 'only numbers',
                            min: '0 - 30000',
                            max: '0 - 30000',
                        }}
                        name="areas[weatherDeck]"
                        fullWidth
                        ref="areasWd"
                        // onBlur={this.handleAreasChange}
                    />
                    <NarrowFormsyText
                        floatingLabelText="Total, m2"
                        validations="isNumeric,min:0,max:90000"
                        validationErrors={{
                            isNumeric: 'only numbers',
                            min: '0 - 90000',
                            max: '0 - 90000',
                        }}
                        name="areas[total]"
                        fullWidth
                        ref="areasTotal"
                    />
                </div>
            </div>
        )
    }

}



const Capacity = withRows(_Capacity);
const HoHa =  withRows(_HoHa);
const Gears = withRows(_Gears);
const Bunkers = withRows(_Bunkers);
const Grabs = withRows(_Grabs);

export default connect( state => ({ user: state.login.user }), {claimRights, uploadImage, addNewVessel, updateVesselById, updateVesselsList} )(withStyles(v)(VesselEdit));
export {
  Gears,
  Grabs,
  TechEquipment
}
