import React, { Component } from 'react'
import ContentClear from 'material-ui/svg-icons/content/clear';
import IconAttachFile from 'material-ui/svg-icons/editor/attach-file';
import v from './VesselEdit.scss'
import { connect } from "react-redux";
import { uploadImage } from './../../../actions/login';
import {fileToFormData, getAttachmentAProps} from './../../../core/utils';
import LockIcon from '../../Icons/LockIcon';
import { IconButton } from 'material-ui';
import CustomTooltip from '../../Common/CustomTooltip';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

export class Attachments extends Component {
  static defaultProps = {
    changesCallback: () => undefined,
    handleRemove: () => undefined,
  }

  handleRemove = (e, id) => {
    this.props.changesCallback();
    this.props.handleRemove(e, id);
  }
  handleAttachmentVisibility = (id)=>{
    this.props.changesCallback();
    this.props.handleAttachmentVisibility(id);
  }

  render() {
    return (
      <div style={{paddingTop: '15px',marginBottom:'16px'}}>
        {
          this.props.attachments && this.props.attachments.length ?
            this.props.attachments.map(attachment => <Attachment isUserOwner={this.props.isUserOwner} key={attachment._id} attachment={attachment} handleRemove={this.handleRemove} handleAttachmentVisibility={this.handleAttachmentVisibility}></Attachment>)
            : <div>---</div>
        }
      </div>

    )
  }
}

export async function uploadFile(upload = uploadImage, e, uploadOpts) {
  const input = e.target;
  const formData = fileToFormData(input.files && input.files[0], 'attachment');
  if (!formData) return;
  return upload(formData, uploadOpts);
}

export function _AddAttachment({success, uploadImage, changesCallback = () => undefined, uploadOpts }) {
  const updated = async ev => {
    success(ev, true);
  }
  return (
    <div className={v.add_attachments}>
      <label htmlFor={'attach_file'}>
          <IconAttachFile
              style={{
                  width: '20px',
                  height: '20px',
                  transform: 'rotate(45deg)',
              }}
          />
          <span>Attach files</span>
          <input accept=".xls, .xlsx, .txt, .csv, .pdf, .jpg, .jpeg, .png, .doc, .docx, .rtf, .tiff, .dwg, .dxf, .vsd, .xlsm, .zip, .rar, .7z" id={'attach_file'} multiple type="file" onChange={updated} />
      </label>
    </div>
  )
}

const AddAttachment = connect(null, {uploadImage})(withStyles(v)(_AddAttachment));

export {
  AddAttachment
}

export function Attachment({attachment, isUserOwner = false, handleRemove = null,handleAttachmentVisibility=null}) {
  return (
    <div className={v.label_select}>
      <div>
        <IconAttachFile
                style={{
                    width: '20px',
                    minWidth: '20px',
                    height: '20px',
                    transform: 'rotate(45deg)',
                    marginRight: '8px'
                }}
                color={'#787878'}
            />
            <a {...getAttachmentAProps(attachment)}>
                {attachment.name}
            </a>
      </div>
      <div style={{display:'flex',alignItems:'center'}}>
            {attachment.canEdit && handleAttachmentVisibility && <CustomTooltip className={v.tooltipRightPosition} dark={true}  horizontalPosition="left" tooltip={`${attachment.visibility==='public' ? 'Public' : 'Private'} File`}>
              <IconButton className={v.iconBtn}
                onClick={()=>handleAttachmentVisibility(attachment._id)}>
                <LockIcon 
                  opened={attachment.visibility==='public'}
                  fill={attachment.visibility==='public' ? '#4380C7' : '#787878'}
                />
              </IconButton>
              
            </CustomTooltip> }
            {
              handleRemove && attachment.canEdit === true
                ? <ContentClear style={{marginLeft: '8px'}} onClick={e => handleRemove(e, attachment._id)} className={v.delete} />
                : null
            }
      </div>
            
    </div>
  )
}

export default Attachments
