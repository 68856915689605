import RequestRourceFilter from '../Monitor/RequestRourceFilter';
import React, { PureComponent } from 'react';
import { PrivateIcon, ReadIcon, SharedIcon, typeIcons, UnreadIcon, unrecognizedTypeIcons, StarredIcon, UnstarredIcon, AttachedIcon, UnattachedIcon } from './Icons';

const keys = [
  '{"type":"cargo","processed":true}',
  '{"type":"vessel","processed":true}',
  '{"type":"sales","processed":true}',
  '{"type":"tc","processed":true}',
    null,
  '{"type":"cargo","processed":false}',
  '{"type":"vessel","processed":false}',
  '{"type":"sales","processed":false}',
  '{"type":"tc","processed":false}',
  '{"type":"spam","processed":false}',
   null,
  '{"type":"read"}',
  '{"type":"unread"}',
   null,
  '{"type":"shared"}',
  '{"type":"private"}',
   null,
  '{"type":"starred"}',
  '{"type":"unstarred"}',
   null,
  '{"type":"attached"}',
  '{"type":"unattached"}',
];
const groups = [
  {
    start: 0,
    end: 5,
  },
  {
    start: 5,
    end: 11,
  },
  {
    start: 11,
    end: 14,
  },
  {
    start: 14,
    end: 17,
  },
  {
    start: 17,
    end: 20,
  },
  {
    start: 20,
    end: 22,
  },
];
const titles = [
  'Cargo',
  'Positions',
  'S&P',
  'Order',
  null,
  'Cargo',
  'Positions',
  'S&P',
  'Order',
  'Other',
  null,
  'Read',
  'Unread',
  null,
  'Shared',
  'Private',
  null,
  'Starred',
  'Unstarred',
  null,
  'With Attach',
  'Without Attach',
];
const icons = [
  typeIcons.cargo,
  typeIcons.vessel,
  typeIcons.sales,
  typeIcons.tc,
  null,
  unrecognizedTypeIcons.cargo,
  unrecognizedTypeIcons.vessel,
  unrecognizedTypeIcons.sales,
  unrecognizedTypeIcons.tc,
  typeIcons.spam,
  null,
  <ReadIcon />,
  <UnreadIcon />,
  null,
  <SharedIcon />,
  <PrivateIcon />,
  null,
  <StarredIcon />,
  <UnstarredIcon />,
  null,
  <AttachedIcon />,
  <UnattachedIcon />,
];

export default class EmailsTypeFilter extends PureComponent {

  convertFromServer = (filters) => {
    delete filters.type;
    filters = { ...filters };
    filters.scopes = filters.scopes || [];
    filters.type = filters.scopes.reduce((acc, scope) => {
      acc[`{"type":"${scope.type}","processed":${scope.processed}}`] = true;
      return acc;
    },{});
    if (typeof filters.read === 'boolean') {
      if (filters.read) {
        filters.type['{"type":"read"}'] = true;
      } else {
        filters.type['{"type":"unread"}'] = true;
      }
    } else {
      filters.type['{"type":"read"}'] = true;
      filters.type['{"type":"unread"}'] = true;
    }
    if (typeof filters.private === 'boolean') {
      filters.type['{"type":"private"}'] = filters.private;
      filters.type['{"type":"shared"}'] = !filters.private;
    } else {
      filters.type['{"type":"private"}'] = true;
      filters.type['{"type":"shared"}'] = true;
    }
    if (typeof filters.favorite === 'boolean') {
      filters.type['{"type":"starred"}'] = filters.favorite;
      filters.type['{"type":"unstarred"}'] = !filters.favorite;
    } else {
      filters.type['{"type":"starred"}'] = true;
      filters.type['{"type":"unstarred"}'] = true;
    }
    if (typeof filters.attached === 'boolean') {
      filters.type['{"type":"attached"}'] = filters.attached;
      filters.type['{"type":"unattached"}'] = !filters.attached;
    } else {
      filters.type['{"type":"attached"}'] = true;
      filters.type['{"type":"unattached"}'] = true;
    }
    if (!Object.keys(filters.type).length) {
      for (let i = 0; i < keys.length; i++) {
        filters.type[keys[i]] = true;
      }
    }
    delete filters.scopes;
    return filters;
  }
  convertToServer = (filters) => {
    filters = { ...filters };
    let read, unread;
    let priv, shared;
    let starred, unstarred;
    let attached, unattached;
    delete filters.read;
    const selected = Object.keys(filters.type).filter(k => filters.type[k]);
    if (selected.length === keys.length) {
      delete filters.type;
      filters.scopes = [];
      return filters;
    }
    filters.scopes = selected.map(t => {
      switch (t){
        case '{"type":"read"}':
          read = true;
          return null;
        case '{"type":"unread"}':
          unread = true;
          return null;
        case '{"type":"private"}':
          priv = true;
          return null;
        case '{"type":"shared"}':
          shared = true;
          return null;
        case '{"type":"starred"}':
          starred = true;
          return null;
        case '{"type":"unstarred"}':
          unstarred = true;
          return null;
        case '{"type":"attached"}':
          attached = true;
          return null;
        case '{"type":"unattached"}':
          unattached = true;
          return null;
        default:
          return JSON.parse(t);
      }
    }).filter(s => s);
    if (read !== unread) {
      filters.read = read || !unread;
    }
    if ((priv && shared) || (!priv && !shared)) {
      filters.private = undefined;
    } else {
      filters.private = priv || !shared;
    }
    if ((starred && unstarred) || (!starred && !unstarred)) {
      filters.favorite = undefined;
    } else {
      filters.favorite = starred || !unstarred;
    }
    if ((attached && unattached) || (!attached && !unattached)) {
      filters.attached = undefined;
    } else {
      filters.attached = attached || !unattached;
    }
    delete filters.type;
    return filters;
  }
  onFilter = (filters) => {
    this.props.handleFilter(this.convertToServer(filters), this.props.tagged);
  }
  render() {
    return (
    <RequestRourceFilter
      style={this.props.style}
      handleFilter={this.onFilter}
      filters={this.convertFromServer(this.props.filters)}
      endingText={''}
      filtersKey="type"
      allText={'All Mail'}
      noDots
      keys={keys}
      titles={titles}
      icons={icons}
      renderIcons
      selectAllEnabled
      grouped
      groups={groups}
    />
    );
  }
}

