import React from 'react';
import Layout from '../../components/Layout';
import {getAccountFilters} from "../../actions/login";

export default {
  path: '/my',
  children: [
    {
      path: '/cargo/:cargoId?/:vesselId?/:actionType?',
      async action({ params, hash, store, token, previousPath }) {
        if (!previousPath || previousPath.indexOf('/my/cargo') !== 0) {
          try {
            await store.dispatch(getAccountFilters(token));
          } catch (e) {
            console.error(e);
          }
        }
        const TradingsList = ((await import(/* webpackChunkName: 'TradingsList' */ '../../components/MyTradings/TradingsList')).default);
        return ({
          component: <Layout>
            <TradingsList {...params} />
          </Layout>,
        })
      },
    },
  ],
}
