import { combineReducers } from 'redux';
import user from './user';
import login from './login';
import register from './register';
import runtime from './runtime';
import monitor from './monitor';
import cargo from './cargo';
import vessel from './vessel';
import contracts from './contracts';
import port from './port';
import bl from './bl';
import analytics from './analytics';
import allFleet from './allFleet';
import emails from './emails';
import contacts from './contacts';
import marketMonitor from './marketMonitor';

import { reducer as chat } from '../components/Chat';
import calculator from '../components/Calculator/reducer';
import issueReport from '../components/IssueReport/reducer';

import produce, { setAutoFreeze } from 'immer';
setAutoFreeze(false);

export default combineReducers({
  login,
  user,
  cargo,
  vessel,
  runtime,
  monitor,
  contracts,
  port,
  bl,
  register,
  analytics,
  chat,
  calculator,
  allFleet,
  emails,
  contacts,
  issueReport,
  marketMonitor,
});
